import { useCurrentProject } from '@modules/projects/utils/useCurrentProject';
import { TaskActionDefinition } from '@modules/workflow/components/WorkflowEditor/Actions/Task/TaskAction';
import { getFieldValue } from '@modules/workflow/components/types';
import { Draw } from '@mui/icons-material';
import { useNotification } from '@utils/useNotification';
import { useQueryInvalidator } from '@utils/useQueryInvalidator';
import { useActionsOfWorkflowInstanceQuery, useSubmitWorkflowActionMutation, useWorkflowInstanceQuery, useWorkflowInstancesQuery } from 'gql/index';
import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { ActionAccordion } from '../ActionAccordion/ActionAccordion';
import { ActionAccordionProps } from '../Types';

export const TaskAction: React.FC<ActionAccordionProps> = (props) => {
  const { formatMessage } = useIntl();
  const { action, workflowId } = props;
  const { projectId } = useCurrentProject();
  const { notifySuccess } = useNotification();
  const invalidateQuery = useQueryInvalidator();
  const { mutate: submitWorkflowAction, isLoading: isSubmitting } = useSubmitWorkflowActionMutation({
    onSuccess: () => {
      invalidateQuery(useWorkflowInstanceQuery, { projectId, workflowId });
      invalidateQuery(useActionsOfWorkflowInstanceQuery, { workflowId });
      invalidateQuery(useWorkflowInstancesQuery, { projectId });

      notifySuccess(formatMessage({ id: 'Workflow action completed successfully.' }));
    }
  });

  const taskDescription = useMemo(() => getFieldValue<string>(action, TaskActionDefinition.FieldId_TaskDescription) ?? '', [action]);

  const handleOnClick = () => submitWorkflowAction({ input: { workflowInstanceId: workflowId, workflowActionId: action.id, jsonPayload: undefined } });

  return (
    <ActionAccordion
      action={action}
      submitAction={{
        loading: isSubmitting,
        buttonIcon: <Draw />,
        buttonText: formatMessage({ id: 'Mark as done' }),
        onClick: handleOnClick
      }}
    >

      {taskDescription}

    </ActionAccordion>
  );
};


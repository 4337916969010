import React from 'react';
import { Outlet } from 'react-router-dom';
import { SettingsDrawer } from '../components/SettingsDrawer';
import { AnonymousTopbar } from '../components/TopBar/AnonymousTopbar';

export const AnonymousPageLayout: React.FC = () => {
  return <>
    <AnonymousTopbar />

    <SettingsDrawer />

    <main style={{ flex: 1, display: 'flex', flexDirection: 'column', overflow: 'auto' }}>
      <Outlet />
    </main>
  </>;
};
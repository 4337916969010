import { TextFieldProps } from '@mui/material';
import { FieldValueType, WorkflowVariableValueTypes } from 'gql/index';
import React, { useContext, useMemo } from 'react';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { VariableValueInputComponent } from '../Variables/VariableValueInputComponent';
import { WorkflowRolePicker } from '../Variables/WorkflowRolePicker';
import { WorkflowEditorContext } from '../WorkflowEditorContext';
import { WorkflowEditorActionValue, WorkflowEditoryActionFieldValue } from '../types';


interface Props {
  fieldId: string;
  label: string;
  textFieldProps?: TextFieldProps;
}

export const WorkflowFieldInput: React.FC<Props> = ({ fieldId, label, textFieldProps }) => {
  const { control, setValue, getValues } = useFormContext<WorkflowEditorActionValue>();
  const { fields: fieldValues } = useFieldArray({ control: control, name: 'fieldValues', keyName: 'uniqueKey' });
  const { actionDefinitionsMap, disabled } = useContext(WorkflowEditorContext);

  const { formatMessage } = useIntl();
  const actionDefinitionId = getValues('workflowActionDefinitionId');

  const fieldValueIndex = fieldValues?.findIndex(p => p?.fieldId === fieldId);

  const actionField = useMemo(() => {
    const actionDefinition = actionDefinitionsMap[actionDefinitionId];
    if (actionDefinition == null) return null;

    const fieldIndex = actionDefinition.fields.findIndex(p => p.id === fieldId);

    const actionField = actionDefinition.fields[fieldIndex];
    return actionField;
  }, [actionDefinitionsMap, actionDefinitionId, fieldId]);

  const onChange = (fieldValue: WorkflowEditoryActionFieldValue) => {
    setValue(`fieldValues.${fieldValueIndex}`, fieldValue);
  };

  if (actionField == null) {
    return undefined;
  }

  return <Controller
    control={control}
    name={`fieldValues.${fieldValueIndex}`}
    rules={{
      validate: (value) => (actionField.required && !value.value && !value.variableId) ? formatMessage({ id: 'This field is required.' }) : true
    }}
    render={({ field, fieldState }) => {

      // At some point we can allow configuring a field with either a value or an available workflow variable (global variable or output from another action)
      if (actionField.variableType == WorkflowVariableValueTypes.User) {
        return <WorkflowRolePicker

          onChange={(value) => { onChange({ fieldId: fieldId, type: FieldValueType.Variable, variableId: value ?? '', value: '', fieldValueType: actionField.variableType }); }}
          value={field.value?.variableId ?? ''}
          textFieldProps={{ label, required: actionField.required, error: !!fieldState.error?.message, helperText: fieldState.error?.message }}
        />;
      }

      return <VariableValueInputComponent
        label={label}
        textFieldProps={textFieldProps}
        onChange={(value) => onChange({ fieldId: fieldId, type: FieldValueType.Value, variableId: '', value: value ?? '', fieldValueType: actionField.variableType })}
        variableType={actionField.variableType}
        value={field.value?.value ?? ''}
        error={fieldState.error?.message}
        required={actionField.required}
        disabled={disabled}
      />;
    }}
  />;
};
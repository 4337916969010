import { AddProjectMemberDrawer } from '@modules/projects/components/Members/AddProjectMemberDrawer';
import { useCurrentProject } from '@modules/projects/utils/useCurrentProject';
import { Autocomplete, AutocompleteProps, TextField } from '@mui/material';
import { PrincipalType } from 'gql/index';
import { deburr } from 'lodash';
import React, { useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { isRemovedPrincipal, removedPrincipalLabelByType } from '.';
import { SecurityPrincipal, SecurityPrincipalShim } from '../types';
import { useProjectPrincipals } from '../useProjectPrincipals';
import { PrincipalPickerChip } from './PrincipalPickerChip';
import { PrincipalPickerMenuItem } from './PrincipalPickerMenuItem';

type Props = {
  label: string;
  usersOnly?: boolean;
  includeMe?: boolean;
  internalUsersOnly?: boolean;
  error?: string;
  multiple?: boolean;
  disabled?: boolean;
  required?: boolean;
  filter?: (principal: SecurityPrincipal) => boolean;
  value: SecurityPrincipalShim[];
  onChange: (value: SecurityPrincipalShim[]) => void;
  options?: SecurityPrincipal[];
};

export interface RemovedPrincipal extends SecurityPrincipalShim {
  type: 'removed';
}

export type PrincipalPickerValue = string | SecurityPrincipal | RemovedPrincipal;

export const PrincipalPicker: React.FC<Props> = ({
  label, error, usersOnly, internalUsersOnly, includeMe, value: selectedPrincipals, onChange: setSelectedPrincipals, multiple, disabled, required, options: externalOptions, filter
}) => {
  const { formatMessage } = useIntl();

  const { canManageProject } = useCurrentProject();

  const [uninitializedCustomers, setUninitializedCustomers] = useState<string[]>([]);

  const [uninitializedUserToInvite, setUninitializedUserToInvite] = useState<string>();
  const isInvitingUser = Boolean(uninitializedUserToInvite);

  const [inputValue, setInputValue] = useState('');

  const { principals, isFetching: isFetchingPrincipals } = useProjectPrincipals({
    disabled: externalOptions != null,
    includeMe: includeMe ?? false,
    usersOnly: usersOnly ?? false,
    internalUsersOnly: internalUsersOnly
  });

  const options = useMemo(() => externalOptions ?? principals, [externalOptions, principals]);

  const isFetching = isFetchingPrincipals;

  const value: PrincipalPickerValue[] = useMemo(() => [
    ...uninitializedCustomers,
    ...selectedPrincipals.map(shim => options.find(p => p.id === shim.id) ?? ({ ...shim, type: 'removed' as const }))
  ], [options, selectedPrincipals, uninitializedCustomers]);

  const onChange: AutocompleteProps<PrincipalPickerValue, true, false, true>['onChange'] = (_, values, reason) => {
    if (reason === 'selectOption') {
      setInputValue('');
    }

    if (multiple) {
      const principals = values.filter((v): v is SecurityPrincipal => typeof v !== 'string');
      setSelectedPrincipals(principals);

      const uninitialized = values.filter((v): v is string => typeof v === 'string');
      setUninitializedCustomers(uninitialized);
    } else {
      const newValues = values.filter(v => typeof v === 'string' ? !uninitializedCustomers.includes(v) : !selectedPrincipals.map(p => p.id).includes(v.id));

      const principal = newValues.at(0);

      if (!principal) {
        setSelectedPrincipals([]);
        setUninitializedCustomers([]);
        return;
      }

      if (typeof principal === 'string') {
        setSelectedPrincipals([]);
        setUninitializedCustomers([principal]);
      } else {
        setSelectedPrincipals([principal]);
        setUninitializedCustomers([]);
      }
    }
  };

  const helperTextProps = useMemo(() => {
    if (value.some(isRemovedPrincipal)) {
      return {
        label: formatMessage({ id: 'Some users or groups have been removed since initializing this entity. Please remove them and replace if necessary.' }),
        color: 'warning.main'
      };
    }

    if (canManageProject) {
      if (uninitializedCustomers.length > 0) {
        return {
          label: formatMessage({ id: 'Some users entered are not yet project members. You may click those users to add them to the project.' }),
          color: error ? undefined : 'warning.main'
        };
      }

      if (options.length === 0) {
        return {
          label: formatMessage({ id: 'This project has no users yet. You may add one here by typing their email or name.' })
        };
      }
    } else {
      if (uninitializedCustomers.length > 0) {
        return {
          label: formatMessage({ id: 'Some users entered are not yet project members, but you do not have permission to invite them.' }),
          color: error ? undefined : 'warning.main'
        };
      }

      if (options.length === 0) {
        return {
          label: formatMessage({ id: 'This project has no users yet, but you do not have permission to invite them.' })
        };
      }
    }

    if (error) return {
      label: error,
    };

    return undefined;
  }, [canManageProject, error, formatMessage, options.length, uninitializedCustomers.length, value]);

  return <>
    <Autocomplete<PrincipalPickerValue, true, false, true>
      freeSolo
      fullWidth
      multiple
      autoHighlight
      disabled={disabled}
      loading={isFetching}
      options={options.filter(p => filter?.(p) ?? true)}
      value={value}
      onChange={onChange}
      filterOptions={(options, state) => {
        const input = deburr(state.inputValue).toLocaleLowerCase();

        return options.filter(option => {
          if (typeof option === 'string') return option;

          if (isRemovedPrincipal(option)) return false;

          if (option.principalType === PrincipalType.User) {
            const fullName = deburr(option.fullName).toLocaleLowerCase();
            const email = deburr(option.email).toLocaleLowerCase();

            return fullName.includes(input) || email.includes(input) || option.phoneNumber?.includes(input);
          } else if (option.principalType === PrincipalType.SecurityGroup) {
            const name = deburr(option.name).toLocaleLowerCase();

            return name.includes(input);
          }
        });
      }}
      renderTags={(principals, getTagProps) => principals.map((principal, index) => (
        <PrincipalPickerChip
          key={typeof principal === 'string' ? index : principal.id}
          getTagProps={getTagProps}
          index={index}
          principal={principal}
          onInviteClicked={() => typeof principal === 'string' && setUninitializedUserToInvite(principal)}
        />
      ))}
      inputValue={inputValue}
      renderInput={params => (
        <TextField
          {...params}
          required={required}
          fullWidth
          onChange={e => {
            if (!multiple && e.target.value) {
              setSelectedPrincipals([]);
              setUninitializedCustomers([]);
            }

            setInputValue(e.target.value);
          }}
          onBlur={e => {
            const newValue = e.target.value.trim();
            if (newValue) {
              setUninitializedCustomers(c => [...c, newValue]);
              setInputValue('');
            }
          }}
          onKeyDown={e => {
            if (e.key === 'Enter') {
              setInputValue('');
            }
          }}
          label={label}
          error={!!error}
          FormHelperTextProps={{ sx: { color: helperTextProps?.color } }}
          helperText={helperTextProps?.label}
        />
      )}
      getOptionLabel={option => {
        if (typeof option === 'string') return option;

        if (isRemovedPrincipal(option)) {
          return formatMessage(removedPrincipalLabelByType[option.principalType]);
        }

        if (option.principalType === PrincipalType.User) {
          return option.fullName;
        } else if (option.principalType === PrincipalType.SecurityGroup) {
          return option.name;
        }

        return '';
      }}
      getOptionKey={principal => typeof principal == 'string' ? crypto.randomUUID() : principal.id}
      renderOption={(props, principal) => (
        <PrincipalPickerMenuItem principal={principal} {...props} />
      )}
    />

    <AddProjectMemberDrawer
      sx={{ zIndex: t => t.zIndex.modal + 1 }}
      userInput={uninitializedUserToInvite}
      onInvitationComplete={newUserId => {
        setSelectedPrincipals([...selectedPrincipals, { id: newUserId, principalType: PrincipalType.User }]);
        setUninitializedCustomers(c => c.filter(u => u !== uninitializedUserToInvite));
        setUninitializedUserToInvite(undefined);
      }}
      open={isInvitingUser}
      onClose={() => setUninitializedUserToInvite(undefined)}
    />
  </>;
};
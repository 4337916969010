import { EditorSavingStatus } from '@components/Editor/TopBar/SavingStatusContext';
import { LoadingButton } from '@mui/lab';
import { useContextSafe } from '@utils/useContextSafe';
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { autoSaveTimeout } from './FormFields/useFormFieldFillerForm';
import { FormFillerContext } from './FormFillerContextProvider';
import { FormFillerValues } from './types';

interface FormSubmitButtonProps {
  loading: boolean;
  onSubmit: (values: FormFillerValues) => void;
}

export const FormFillerSubmitButton: React.FC<FormSubmitButtonProps> = ({ loading, onSubmit }) => {
  const { formSavingStatus } = useContextSafe(FormFillerContext);
  const { formatMessage } = useIntl();

  const [readyToSubmitValues, setReadyToSubmitValues] = useState<FormFillerValues | undefined>(undefined);

  const isSubmitting = formSavingStatus === EditorSavingStatus.Saving;
  const { handleSubmit } = useFormContext<FormFillerValues>();

  useEffect(() => {
    if (readyToSubmitValues && !isSubmitting) {
      onSubmit?.(readyToSubmitValues);
      setReadyToSubmitValues(undefined);
    }
    else if (readyToSubmitValues) {
      console.warn('Waiting on field auto save to finish to submit form.');
    }
  }, [onSubmit, readyToSubmitValues, isSubmitting]);

  const onSubmitClicked = (values: FormFillerValues) => {
    setTimeout(() => setReadyToSubmitValues(values), autoSaveTimeout + 500);
  };

  return (
    <LoadingButton loading={loading} disabled={loading} variant='contained' onClick={handleSubmit(onSubmitClicked, console.log)}>
      {formatMessage({ id: 'Submit' })}
    </LoadingButton>
  );
};
import { ActionMenu } from '@components/ActionMenu';
import { ConfirmDialog } from '@components/ConfirmDialog';
import { PermissionsModal } from '@modules/securityGroups/components/PermissionsModal/PermissionsModal';
import { Delete, Draw, Share } from '@mui/icons-material';
import { ListItemIcon, ListItemText, MenuItem } from '@mui/material';
import { getTenantIdentifier } from '@utils/getTenantIdentifier';
import { useGetMe } from '@utils/useGetMe';
import { useNotification } from '@utils/useNotification';
import { useQueryInvalidator } from '@utils/useQueryInvalidator';
import { ShareableResourceType, WorkflowDefinitionFragmentFragment, useDeleteWorkflowTemplateMutation, useWorkflowTemplatesQuery } from 'gql/index';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { useLocation, useNavigate } from 'react-router-dom';

interface Props {
  template: WorkflowDefinitionFragmentFragment;
  onPreviewClick?: () => void;
}

export const WorkflowTemplatesActions: React.FC<Props> = ({ template }) => {
  const { formatMessage } = useIntl();
  const navigate = useNavigate();
  const invalidateQuery = useQueryInvalidator();
  const { notifySuccess } = useNotification();
  const location = useLocation();
  const { me } = useGetMe();

  const editPath = `/${getTenantIdentifier()}/workflows/edit/${template.id}`;
  const isEditing = location.pathname.startsWith(editPath);

  const canEdit = template.createdBy?.id === me?.id;

  const [confirmRemoveDialogOpen, setConfirmRemoveDialogOpen] = useState(false);

  const [permissionsDialogOpen, setPermissionsDialogDialogOpen] = useState(false);

  const { mutate: deleteWorkflowTemplate, isLoading } = useDeleteWorkflowTemplateMutation({
    onSuccess: () => {
      notifySuccess(formatMessage({ id: 'Workflow template deleted successfully' }));
      invalidateQuery(useWorkflowTemplatesQuery);
      if (isEditing) {
        navigate(`/${getTenantIdentifier()}/workflows`, { replace: true });
      }
    }
  });

  const onDeleteConfirm = () => {
    deleteWorkflowTemplate({ id: template.id });
  };

  if (!canEdit) {
    return null;
  }

  return <>
    <ActionMenu>
      <MenuItem onClick={() => setPermissionsDialogDialogOpen(true)}>
        <ListItemIcon>
          <Share />
        </ListItemIcon>
        <ListItemText primary={formatMessage({ id: 'Share' })} />
      </MenuItem>

      {!isEditing && (
        <MenuItem onClick={() => navigate(editPath)}>
          <ListItemIcon>
            <Draw />
          </ListItemIcon>
          <ListItemText
            primary={formatMessage({ id: 'Edit' })}
          />
        </MenuItem>
      )}

      <MenuItem onClick={() => setConfirmRemoveDialogOpen(true)}>
        <ListItemIcon>
          <Delete />
        </ListItemIcon>
        <ListItemText
          primary={formatMessage({ id: 'Delete' })}
        />
      </MenuItem>
    </ActionMenu>

    <PermissionsModal
      open={permissionsDialogOpen}
      onClose={() => setPermissionsDialogDialogOpen(false)}
      resource={{
        resourceType: ShareableResourceType.WorkflowDefinition,
        ...template
      }}
    />

    <ConfirmDialog
      open={confirmRemoveDialogOpen}
      title={formatMessage({ id: 'Delete workflow template' })}
      content={formatMessage({ id: 'This workflow template will be deleted. Workflow created using it will remain. Are you sure?' })}
      confirmText={formatMessage({ id: 'Delete' })}
      confirmColor='error'
      onCancel={() => setConfirmRemoveDialogOpen(false)}
      onConfirm={onDeleteConfirm}
      loading={isLoading}
    />
  </>;
};
import { FieldValueType, WorkflowActionConfigurationFragmentFragment, WorkflowActionDefinitionFragmentFragment, WorkflowFieldType, WorkflowVariableType } from 'gql/index';
import { WorkflowEditorActionValue } from './types';

export const mapActionFragmentToForm = (action: WorkflowActionConfigurationFragmentFragment, actionDefinition: WorkflowActionDefinitionFragmentFragment): WorkflowEditorActionValue => {
  return ({
    id: action.id,
    workflowActionDefinitionId: action.workflowActionDefinitionId,
    name: action.name,
    order: action.order,
    status: action.status,
    fieldValues: actionDefinition.fields
      .filter(p => p.fieldType == WorkflowFieldType.Configuration)
      .map(field => {
        const existingValue = action.fieldValues.find(p => p.fieldId == field.id);
        if (existingValue) {
          return ({
            fieldId: existingValue.fieldId,
            fieldValueType: existingValue.value?.variableValueType ?? '',
            type: existingValue.value?.variableType == WorkflowVariableType.WorkflowVariable ? FieldValueType.Variable : FieldValueType.Value,
            variableId: existingValue.value?.id ?? '',
            value: existingValue.value?.serializedValue == null ? null : JSON.parse(existingValue.value.serializedValue)
          });
        } else {
          return ({
            fieldId: field.id,
            type: FieldValueType.Value,
            fieldValueType: '',
            value: '',
            variableId: ''
          });
        }
      }) ?? [],
  });
};
import { ConfirmDialog } from '@components/ConfirmDialog';
import { Property } from '@components/DataDisplay/Property';
import { PropertyGroup } from '@components/DataDisplay/PropertyGroup';
import { TextProperty } from '@components/DataDisplay/TextProperty';
import { getFileExtension } from '@components/Icons/FileIcon';
import { DisableableTooltip } from '@components/Tooltips/DisableableTooltip';
import { UserAvatar } from '@components/UserAvatar/UserAvatar';
import { SignatureDialog } from '@modules/documents/components/DocumentSignatureDialog';
import { RequestSignatureDialog } from '@modules/documents/components/RequestSignatureDialog/RequestSignatureDialog';
import { useCurrentProject } from '@modules/projects/utils/useCurrentProject';
import { Alert, Button, Chip, Divider, Stack, Typography } from '@mui/material';
import { getTenantIdentifier } from '@utils/getTenantIdentifier';
import { useGetMe } from '@utils/useGetMe';
import { useNotification } from '@utils/useNotification';
import { useQueryInvalidator } from '@utils/useQueryInvalidator';
import dayjs from 'dayjs';
import { DocumentFragmentFragment, useFolderEntriesQuery, useGetDocumentQuery, useProjectDocumentsQuery, useProjectTasksQuery, useRemoveSignatureMutation } from 'gql/index';
import { useState } from 'react';
import { useIntl } from 'react-intl';

interface OwnProps {
  document?: DocumentFragmentFragment | null;
}

export const DocumentSignatureDetails: React.FC<OwnProps> = ({ document }) => {
  const { formatMessage } = useIntl();
  const invalidateQuery = useQueryInvalidator();
  const { notifySuccess } = useNotification();
  const { me } = useGetMe();
  const { isArchived, projectId, canManageDocuments } = useCurrentProject();

  const currentUserCanSign = document?.signedOn == null && document?.signatoryCustomerId === me?.id;
  const isPdfDocument = getFileExtension(document?.fileName ?? '').toLocaleLowerCase() == 'pdf';

  const [isSignatureDialogOpen, setIsSignatureDialogOpen] = useState(false);

  const [openAskSignatureDialog, setOpenAskSignatureDialog] = useState(false);
  const [openRemoveSignatureDialog, setOpenRemoveSignatureDialog] = useState(false);

  const { mutate: removeSignature, isLoading: isRemovingSignature } = useRemoveSignatureMutation({
    onSuccess: () => {
      notifySuccess(formatMessage({ id: 'Signature removed successfully.' }));
      invalidateQuery(useProjectDocumentsQuery, { projectId });
      invalidateQuery(useFolderEntriesQuery, { projectId });
      invalidateQuery(useGetDocumentQuery, { id: document?.id });
      invalidateQuery(useProjectTasksQuery, { projectId });
    }
  });

  const handleRemoveSignature = () => {
    document && removeSignature({ input: { projectId, documentId: document.id } });
  };

  const actionButton = () => {
    if (isArchived) {
      return;
    }

    if (canManageDocuments) {
      return (document?.isSigned || document?.requiresSignatures) ? (
        <Button variant='text' onClick={() => setOpenRemoveSignatureDialog(true)}>
          {formatMessage({ id: 'Remove' })}
        </Button>
      ) : (
        <DisableableTooltip disabled={isPdfDocument} title={formatMessage({ id: 'Only pdf documents can be signed.' })}>
          <Button disabled={!isPdfDocument} variant='text' onClick={() => setOpenAskSignatureDialog(true)}>
            {formatMessage({ id: 'Add' })}
          </Button>
        </DisableableTooltip>
      );
    } else if (currentUserCanSign) {
      return (
        <Button variant='text' onClick={() => setIsSignatureDialogOpen(true)} disabled={isArchived}>
          {formatMessage({ id: 'Sign now' })}
        </Button>
      );
    }
  };

  return (
    <>
      <PropertyGroup
        title={formatMessage({ id: 'Signature' })}
        actionButton={actionButton()}
      >
        <TextProperty propertyName={formatMessage({ id: 'Require signature' })} propertyValue={document?.requiresSignatures ? formatMessage({ id: 'Yes' }) : formatMessage({ id: 'No' })} />

        {document?.requiresSignatures &&
          <Stack>
            <Property propertyName={formatMessage({ id: 'Require signature from' })}>
              <Stack direction='row' marginBottom={2}>
                <Chip
                  avatar={<UserAvatar displayName={document?.signatoryCustomer?.fullName} />}
                  label={document?.signatoryCustomer?.fullName}
                />
              </Stack>
            </Property>
            <TextProperty
              propertyName={formatMessage({ id: 'Signed on' })}
              propertyValue={document?.isSigned ? dayjs(document.signedOn).format('LL') : formatMessage({ id: 'Not signed yet' })}
            />
          </Stack>
        }

        {(document?.isSigned && document.zohoRequestId) && (
          <Alert severity='info'>
            {formatMessage({ id: 'Download the document to view the signature.' })}
          </Alert>
        )}

        {(document?.isSigned && !document.zohoRequestId) &&
          <Property propertyName={formatMessage({ id: 'Signature' })}>
            <Stack>
              {document.textualSignature ? (
                <>
                  <Typography fontFamily='Dancing Script' fontSize='1.5rem'>{document.textualSignature}</Typography>
                  <Divider />
                </>
              ) : (
                <img
                  src={`/${getTenantIdentifier()}/api/documents/${document.id}/signature`}
                  style={{ backgroundColor: 'white' }}
                />
              )}
            </Stack>
          </Property>
        }
      </PropertyGroup>

      <RequestSignatureDialog
        documentId={document?.id ?? 0}
        open={openAskSignatureDialog}
        onClose={() => setOpenAskSignatureDialog(false)}
      />

      <SignatureDialog
        documentId={document?.id ?? 0}
        actionId={document?.zohoActionId ?? ''}
        requestId={document?.zohoRequestId ?? ''}
        open={isSignatureDialogOpen}
        onClose={() => setIsSignatureDialogOpen(false)}
      />

      <ConfirmDialog
        open={openRemoveSignatureDialog}
        onCancel={() => setOpenRemoveSignatureDialog(false)}
        onConfirm={handleRemoveSignature}
        title={formatMessage({ id: 'Remove signature' })}
        content={formatMessage({ id: 'Are you sure you want to remove the signature?' })}
        confirmColor='error'
        loading={isRemovingSignature}
      />
    </>
  );
};
import { ActionMenu } from '@components/ActionMenu';
import { ConfirmDialog } from '@components/ConfirmDialog';
import { Delete } from '@mui/icons-material';
import { ListItemIcon, ListItemText, MenuItem, Stack } from '@mui/material';
import { useNotification } from '@utils/useNotification';
import { useDeleteWorkflowActionMutation } from 'gql/index';
import { useContext, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { WorkflowEditorContext } from '../WorkflowEditorContext';
import { WorkflowEditorActionValue, WorkflowEditorValues } from '../types';



interface Props {
  workflowTemplateId: number;
  workflowActionConfiguration: WorkflowEditorActionValue;
}

export const WorkflowActionConfigurationActions: React.FC<Props> = ({ workflowTemplateId, workflowActionConfiguration }) => {
  const { formatMessage } = useIntl();
  const { notifySuccess } = useNotification();

  const { setValue, getValues } = useFormContext<WorkflowEditorValues>();
  const { selectedActionIndex, setSelectedActionIndex } = useContext(WorkflowEditorContext);



  const [confirmRemoveDialogOpen, setConfirmRemoveDialogOpen] = useState(false);
  const { mutate: deleteWorkflowAction, isLoading: isDeleting } = useDeleteWorkflowActionMutation();


  const onDeleteConfirm = () => {
    deleteWorkflowAction({ input: { workflowDefinitionId: workflowTemplateId, actionConfigurationId: workflowActionConfiguration.id } },
      {
        onSuccess: () => {
          notifySuccess(formatMessage({ id: 'Action deleted successfully' }));
          setConfirmRemoveDialogOpen(false);

          const actionIndex = getValues('actions').findIndex(p => p.id === workflowActionConfiguration.id);

          if (actionIndex >= 0) {

            const updatedActions = [...getValues('actions')];
            updatedActions.splice(actionIndex, 1);

            setValue('actions', updatedActions);
            if (selectedActionIndex === actionIndex) {
              setSelectedActionIndex(null);
            }
          }
        }
      }
    );
  };



  const [moreMenuDrawerOpen, setMoreMenuDrawerOpen] = useState(false);

  return (
    <>
      <Stack direction='row' gap={2}>



        <ActionMenu open={moreMenuDrawerOpen} onOpen={setMoreMenuDrawerOpen}>


          <MenuItem onClick={() => setConfirmRemoveDialogOpen(true)}>
            <ListItemIcon><Delete /></ListItemIcon>
            <ListItemText primary={formatMessage({ id: 'Delete' })} />
          </MenuItem>
        </ActionMenu>
      </Stack>

      <ConfirmDialog
        open={confirmRemoveDialogOpen}
        title={formatMessage({ id: 'Delete workflow action' })}
        content={formatMessage({ id: 'This workflow action will be deleted. Are you sure?' })}
        confirmText={formatMessage({ id: 'Delete' })}
        confirmColor='error'
        onCancel={() => setConfirmRemoveDialogOpen(false)}
        onConfirm={onDeleteConfirm}
        loading={isDeleting}
      />


    </>
  );
};
import { ReactComponent as UnexpectedErrorImage } from '@assets/images/not-found.svg';
import { Button, Container, Stack } from '@mui/material';
import React from 'react';
import { useIntl } from 'react-intl';
import { ErrorDetails } from '../components/ErrorDetails';
import { ErrorPageLayout } from '../layouts/ErrorPageLayout';
import { useNavigate } from 'react-router';

export const NotFoundPage: React.FC = () => {
  const { formatMessage } = useIntl();
  const navigate = useNavigate();
  return (
    <ErrorPageLayout>
      <Container maxWidth="sm" sx={{ mt: 4 }}>
        <ErrorDetails
          image={<UnexpectedErrorImage />}
          title={formatMessage({ id: '404 Not Found' })}
          message={formatMessage({ id: 'The page you tried to view does not exist.' })}
        />
        <Stack direction="row" justifyContent='center' alignItems='center' spacing={2} sx={{ marginTop: 3 }}>
          <Button variant="outlined" onClick={() => navigate(-1)}>{formatMessage({ id: 'Back' })}</Button>
          <Button variant="contained" onClick={() => navigate('/')}>{formatMessage({ id: 'Home' })}</Button>
        </Stack>
      </Container>
    </ErrorPageLayout >
  );
};
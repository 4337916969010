import { BudgetAndExpensesPermission, DocumentsPermission, FormsPermission, TasksPermission } from 'gql/index';
import { MessageDescriptor, defineMessages } from 'react-intl';


export const budgetPermissionMessages: Record<BudgetAndExpensesPermission, MessageDescriptor> = defineMessages({
  NotAllowed: { id: 'Not allowed' },
  View: { id: 'Read' },
  Manage: { id: 'Full access' }
});

export const budgetPermissionDescriptionMessages: Record<BudgetAndExpensesPermission, MessageDescriptor> = defineMessages({
  NotAllowed: { id: 'Cannot view budget and expenses information.' },
  View: { id: 'Can view all budget and expenses information.' },
  Manage: { id: 'Can manage project budget and expenses.' }
});


export const formsPermissionMessages: Record<FormsPermission, MessageDescriptor> = defineMessages({
  LimitedAccess: { id: 'Limited access' },
  Manage: { id: 'Full access' }
});

export const formsPermissionDescriptionMessages: Record<FormsPermission, MessageDescriptor> = defineMessages({
  LimitedAccess: { id: 'Can fill forms sent to the user by an administrator.' },
  Manage: { id: 'Can manage and send forms to project members.' }
});


export const tasksPermissionMessages: Record<TasksPermission, MessageDescriptor> = defineMessages({
  LimitedAccess: { id: 'Limited access' },
  ViewAll: { id: 'Read' },
  Manage: { id: 'Full access' },
});

export const tasksPermissionDescriptionMessages: Record<TasksPermission, MessageDescriptor> = defineMessages({
  LimitedAccess: { id: 'Can view only tasks directly assigned to the user.' },
  ViewAll: { id: 'Can view all project tasks.' },
  Manage: { id: 'Can manage all project tasks.' },
});

export const documentsPermissionMessages: Record<DocumentsPermission, MessageDescriptor> = defineMessages({
  LimitedAccess: { id: 'Limited access' },
  Manage: { id: 'Full access' },
});

export const documentsPermissionDescriptionMessages: Record<DocumentsPermission, MessageDescriptor> = defineMessages({
  LimitedAccess: { id: 'Can view only documents directly assigned to the user.' },
  Manage: { id: 'Can manage all project documents.' },
});

export const workflowsPermissionMessages: Record<DocumentsPermission, MessageDescriptor> = defineMessages({
  LimitedAccess: { id: 'Limited access' },
  Manage: { id: 'Full access' },
});

export const workflowsPermissionDescriptionMessages: Record<DocumentsPermission, MessageDescriptor> = defineMessages({
  LimitedAccess: { id: 'Can view only workflows user is a participant of.' },
  Manage: { id: 'Can manage all project workflows.' },
});
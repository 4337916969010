import { FileIcon } from '@components/Icons/FileIcon';
import { Draw, Task } from '@mui/icons-material';
import { alpha, Theme } from '@mui/material';
import { WorkflowActionConfigurationFragmentFragment, WorkflowActionStatus } from 'gql/index';
import React from 'react';
import { defineMessages, MessageDescriptor } from 'react-intl';
import { AllActionIds } from '../../WorkflowEditor/Actions/types';
import { ConfigureDocumentSignatureAction } from './ConfigureDocumentSignature/ConfigureDocumentSignatureAction';
import { FillFormAction } from './FillForm/FillFormAction';
import { TaskAction } from './Task/TaskAction';

export const ActionAccordionBgColor: Record<WorkflowActionStatus, (theme: Theme) => string> = {
  [WorkflowActionStatus.Completed]: (theme: Theme) => alpha(theme.palette.success.main, theme.palette.action.hoverOpacity),
  [WorkflowActionStatus.InProgress]: (theme: Theme) => theme.palette.background.paper,
  [WorkflowActionStatus.NotStarted]: (theme: Theme) => alpha(theme.palette.warning.main, theme.palette.action.hoverOpacity)
};

export interface ActionAccordionProps {
  workflowId: number;
  action: WorkflowActionConfigurationFragmentFragment;
}

export const ActionAccordionByType: Record<AllActionIds, React.FC<ActionAccordionProps>> = {
  'Internal.FillForm': FillFormAction,
  'Internal.Task': TaskAction,
  'Internal.ConfigureDocumentSignature': ConfigureDocumentSignatureAction,
};

export const ActionIconByType: Record<AllActionIds, React.ReactNode> = {
  'Internal.FillForm': <FileIcon fileIconType={'InternalForm'} fontSize='large' />,
  'Internal.Task': <Task />,
  'Internal.ConfigureDocumentSignature': <Draw />,
};

export const ActionNameByType: Record<AllActionIds, MessageDescriptor> = defineMessages({
  'Internal.FillForm': { id: 'Fill a form' },
  'Internal.Task': { id: 'Perform a task' },
  'Internal.ConfigureDocumentSignature': { id: 'Request a document signature' }
});
import { ActionMenu } from '@components/ActionMenu';
import { ConfirmDialog } from '@components/ConfirmDialog';
import { InlineEditPaper } from '@components/InlineEditPaper';
import { Draggable } from '@hello-pangea/dnd';
import { Delete, DragHandle, Edit, ForkRight, Visibility } from '@mui/icons-material';
import { Box, FormControlLabel, ListItemIcon, MenuItem, Stack, Switch, Tooltip } from '@mui/material';
import { FieldType } from 'gql/index';
import { useContext, useState } from 'react';
import { Controller, FormProvider, useForm, useFormContext, useWatch } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { EditorSavingStatus, SavingStatusContext } from '../../../../../components/Editor/TopBar/SavingStatusContext';
import { ReactHookFormFieldArrayElement } from '../../types';
import { FormEditorContext } from '../FormEditorContext';
import { FormEditorFieldValues, FormEditorValues } from '../types';
import { BooleanFormFieldConfiguration } from './configurations/BooleanFormFieldConfiguration';
import { DateFormFieldConfiguration } from './configurations/DateFormFieldConfiguration';
import { FileFormFieldConfiguration } from './configurations/FileFormField/FileFormFieldConfiguration';
import { MatrixFormFieldConfiguration } from './configurations/MatrixFormField/MatrixFormFieldConfiguration';
import { NumericalFormFieldConfiguration } from './configurations/NumericalFormFieldConfiguration';
import { SelectionFormFieldConfiguration } from './configurations/SelectionFormField/SelectionFormFieldConfiguration';
import { TextFormFieldConfiguration } from './configurations/TextFormFieldConfiguration';

type DraggableCustomFieldProps = {
  index: number,
  field: FormEditorFieldValues & ReactHookFormFieldArrayElement;
  onSubmit: (data: FormEditorFieldValues) => void;
  onDelete: () => void;
};

export interface InlineEditableComponentProps {
  isActive: boolean;
  onSubmit?: (data: FormEditorFieldValues) => void;
}

const componentTypeMap: Record<FieldType, React.FC<InlineEditableComponentProps>> = {
  [FieldType.Text]: TextFormFieldConfiguration,
  [FieldType.Numerical]: NumericalFormFieldConfiguration,
  [FieldType.Boolean]: BooleanFormFieldConfiguration,
  [FieldType.Matrix]: MatrixFormFieldConfiguration,
  [FieldType.Selection]: SelectionFormFieldConfiguration,
  [FieldType.File]: FileFormFieldConfiguration,
  [FieldType.Date]: DateFormFieldConfiguration
};

export const DraggableFormField = ({ field, index, onDelete, onSubmit }: DraggableCustomFieldProps) => {
  const { formatMessage } = useIntl();

  const parentForm = useFormContext<FormEditorValues>();

  const conditions = useWatch({ control: parentForm.control, name: 'conditions' });

  const hasVisibilityEffect = conditions.filter(c => c.targetFieldId == field.id).length !== 0;

  const form = useForm<FormEditorFieldValues>({ defaultValues: field, mode: 'onBlur' });
  const { formState: { errors }, control, handleSubmit } = form;

  const id = useWatch({ control, name: 'id' });
  const fieldType = useWatch({ control, name: 'fieldType' });
  const name = useWatch({ control, name: 'name' });

  const InlineEditableComponent = componentTypeMap[field.fieldType];

  const { isEditorLoading, editorSavingStatus } = useContext(SavingStatusContext);

  const isLoading = isEditorLoading || editorSavingStatus === EditorSavingStatus.Saving;

  const [isDeleteConfirmationDialogOpen, setDeleteConfirmationDialogOpen] = useState(false);

  const [isCurrentlyActive, setIsCurrentlyActive] = useState(!name.trim());

  const dontShowDragHandle = isCurrentlyActive && fieldType === FieldType.Selection;
  const { prefillNewCondition } = useContext(FormEditorContext);
  return <>
    <Draggable draggableId={field.uniqueId} index={index} isDragDisabled={isLoading}>
      {(provided) => (
        <Box ref={provided.innerRef} {...provided.draggableProps}>
          <FormProvider {...form}>
            <InlineEditPaper
              defaultOpen={!name.trim()}
              onActiveChange={setIsCurrentlyActive}
              isActive={isCurrentlyActive}
              disabled={isLoading}
              error={Boolean(errors.name)}
              startIcon={!dontShowDragHandle && (
                <Stack {...provided.dragHandleProps}><DragHandle /></Stack>
              )}
              endIcon={(
                <ActionMenu iconButtonProps={{ disabled: isLoading }}>
                  <MenuItem onClick={() => setIsCurrentlyActive(true)}>
                    <ListItemIcon>
                      <Edit />
                    </ListItemIcon>

                    {formatMessage({ id: 'Edit' })}
                  </MenuItem>
                  <MenuItem onClick={() => field.id && prefillNewCondition(field.id)}>
                    <ListItemIcon>
                      <ForkRight />
                    </ListItemIcon>

                    {formatMessage({ id: 'Add logic condition' })}
                  </MenuItem>
                  <MenuItem onClick={() => id ? setDeleteConfirmationDialogOpen(true) : onDelete()}>
                    <ListItemIcon>
                      <Delete />
                    </ListItemIcon>

                    {formatMessage({ id: 'Delete' })}
                  </MenuItem>
                </ActionMenu>
              )}
              onInactivate={handleSubmit(onSubmit)}
            >
              {isActive => <>
                {hasVisibilityEffect && (
                  <Stack pt={1} direction='row' width='100%' justifyContent='end'>
                    <Tooltip title={formatMessage({ id: 'This field is affected by a visibility condition' })}>
                      <Visibility />
                    </Tooltip>
                  </Stack>
                )}

                <InlineEditableComponent isActive={isActive} onSubmit={() => handleSubmit(onSubmit)()} />

                {(isActive && field.fieldType !== FieldType.Matrix) && (
                  <Stack alignItems='end' pb={1} pr={2} gap={1}>
                    <Controller
                      control={control}
                      name='isRequired'
                      render={({ field }) => (
                        <FormControlLabel
                          control={<Switch checked={field.value} onChange={(_, checked) => field.onChange(checked)} />}
                          label={formatMessage({ id: 'Required' })}
                          labelPlacement='start'
                        />
                      )}
                    />
                  </Stack>
                )}
              </>}
            </InlineEditPaper>
          </FormProvider>
        </Box>
      )}
    </Draggable >

    <ConfirmDialog
      open={isDeleteConfirmationDialogOpen}
      title={formatMessage({ id: 'Delete field' })}
      content={formatMessage({ id: 'This field will be deleted. Are you sure?' })}
      confirmText={formatMessage({ id: 'Delete' })}
      confirmColor='error'
      onCancel={() => setDeleteConfirmationDialogOpen(false)}
      onConfirm={onDelete}
      loading={isLoading}
    />
  </>;
};
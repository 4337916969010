import { Check, Schedule } from '@mui/icons-material';
import { WorkFlowStatus, WorkflowActionConfigurationFragmentFragment, WorkflowActionStatus, WorkflowInstance } from 'gql/index';
import { ReactNode } from 'react';
import { MessageDescriptor, defineMessages } from 'react-intl';

export type WorkflowRow = (Pick<WorkflowInstance, 'id' | 'workFlowStatus' | 'name' | 'variables'>)

export const WorkflowStatusType: Record<WorkFlowStatus, MessageDescriptor> = defineMessages({
  [WorkFlowStatus.NotStarted]: { id: 'Not started' },
  [WorkFlowStatus.InProgress]: { id: 'In progress' },
  [WorkFlowStatus.Completed]: { id: 'Completed' },
});

type ChipColors = 'default' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning';
export const WorkflowStatusTypeColor: Record<WorkFlowStatus, ChipColors> = {
  [WorkFlowStatus.NotStarted]: 'info',
  [WorkFlowStatus.InProgress]: 'success',
  [WorkFlowStatus.Completed]: 'secondary'
};

export const WorkflowActionStatusTypeColor: Record<WorkflowActionStatus, ChipColors> = {
  [WorkflowActionStatus.NotStarted]: 'warning',
  [WorkflowActionStatus.InProgress]: 'warning',
  [WorkflowActionStatus.Completed]: 'success'
};

export const WorkflowActionStatusType: Record<WorkflowActionStatus, ReactNode> = {
  [WorkflowActionStatus.NotStarted]: <Schedule />,
  [WorkflowActionStatus.InProgress]: <Schedule />,
  [WorkflowActionStatus.Completed]: <Check />,
};

export function getFieldValue<T>(action: WorkflowActionConfigurationFragmentFragment, fieldId: string, assertFunc?: (input: unknown) => T): T | null {
  const fieldValue = action?.fieldValues.find(v => v.fieldId === fieldId)?.value;
  if (fieldValue?.serializedValue == null) return null;

  const parsedObject = JSON.parse(fieldValue.serializedValue);
  return assertFunc && parsedObject != null ? assertFunc(parsedObject) : parsedObject as T;

}

import { useIntl } from 'react-intl';
import { WorkflowFieldInput } from '../../Fields/WorkflowFieldInput';
import { ActionConfigurationComponentProps } from '../types';


export const TaskActionDefinition = {
  ActionDefinitionId: 'Internal.Task',
  FieldId_TaskDescription: 'c1ab59ff-6dcf-4f90-8055-8a1d84145864',
  FieldId_Assignee: '27db498a-3bbc-4de2-afbb-3e180aa3b394'
} as const;

export const TaskAction: React.FC<ActionConfigurationComponentProps> = () => {
  const { formatMessage } = useIntl();

  return (
    <>
      <WorkflowFieldInput
        fieldId={TaskActionDefinition.FieldId_TaskDescription}
        label={formatMessage({ id: 'Description' })}
        textFieldProps={{ multiline: true, rows: 3 }}
      />
      <WorkflowFieldInput fieldId={TaskActionDefinition.FieldId_Assignee} label={formatMessage({ id: 'Assigned to' })} />
    </>
  );
};
import { Box, CircularProgress, Stack } from '@mui/material';
import { useWorkflowTemplateQuery } from 'gql/index';
import { useEffect } from 'react';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { VariableValueInputComponent } from '../../WorkflowEditor/Variables/VariableValueInputComponent';
import { StartWorkflowFormValues } from '../types';


export const ConfigureVariablesStep = () => {
  const { formatMessage } = useIntl();

  const { setValue, getValues, control } = useFormContext<StartWorkflowFormValues>();

  const { data: template, isLoading } = useWorkflowTemplateQuery({ id: getValues('workflowTemplateId') }, { select: p => p.workflowTemplate });

  const { fields } = useFieldArray({ control, name: 'variableInputs' });

  useEffect(() => setValue('variableInputs', template?.variables.map(p => ({
    variableId: p.id,
    name: p.name,
    variableValueType: p.variableValueType,
    value: p.serializedValue == null ? null : JSON.parse(p.serializedValue)
  })) ?? []), [setValue, template]);


  if (isLoading && template == null) {
    return (
      <Stack minHeight='300px' height='100%' width='100%' alignItems='center' justifyContent='center'>
        <CircularProgress />
      </Stack>
    );
  }

  return <Stack gap={4} >
    {fields.map((p, index) =>
      <Box key={p.id} sx={{ maxWidth: '50ch' }}>
        <Controller
          control={control}
          name={`variableInputs.${index}.value`}
          rules={{
            required: formatMessage({ id: 'A value for this variable is required.' })
          }}
          render={({ field, fieldState }) => (
            <VariableValueInputComponent
              variableType={p.variableValueType}
              required
              error={fieldState.error?.message}
              label={p.name ?? ''}
              value={field.value}
              onChange={(value) => field.onChange(value)}
            />
          )}
        />
      </Box>
    )}
  </Stack>;
};
import { MatrixDataGrid } from '@modules/forms/components/FormEditor/FormFields/configurations/MatrixFormField/MatrixDataGridEditor';
import { Stack, TextField, debounce } from '@mui/material';
import { useValidationRules } from '@utils/useValidationRules';
import { useCallback } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { FormEditorFieldValues, FormEditorMatrixValues } from '../../../types';
import { InlineEditableComponentProps } from '../../DraggableFormField';
import { FormFieldLabel } from '../../FormFieldLabel';

type Props = InlineEditableComponentProps;

export const MatrixFormFieldConfiguration = (props: Props) => {
  const { formatMessage } = useIntl();
  const { notEmpty } = useValidationRules();

  const { control, setValue, getValues } = useFormContext<FormEditorFieldValues>();

  const question = useWatch({ control: control, name: 'name' });
  const required = useWatch({ control: control, name: 'isRequired' });

  const matrixValues = useWatch({ control, name: 'matrixValues' });

  const autoSaveField = debounce(() => {
    props.onSubmit?.(getValues());
  }, 2000);

  const onChange = useCallback((values: FormEditorMatrixValues) => {
    setValue('matrixValues', values);
    autoSaveField();
    // don't react to debounced function
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setValue]);

  return props.isActive ? (
    <Stack p={2} gap={2}>
      <Controller
        name='name'
        control={control}
        rules={{ validate: notEmpty }}
        render={({ field, fieldState: { error } }) => (
          <TextField
            {...field}
            autoFocus
            fullWidth
            required
            error={Boolean(error)}
            helperText={error?.message}
            label={formatMessage({ id: 'Question' })}
          />
        )}
      />

      <MatrixDataGrid values={matrixValues} onChange={onChange} />
    </Stack >
  ) : (
    <Stack p={2} gap={2}>
      <FormFieldLabel required={required} italic={!question}>
        {question || formatMessage({ id: 'No name set' })}
      </FormFieldLabel>

      <MatrixDataGrid disabled values={matrixValues} />
    </Stack>
  );
};
import { MultiStepFormDialog } from '@components/MultiStepForms/MultiStepFormDialog';
import { AddMeetingFormValues } from '@modules/meetings/types';
import { useCurrentProject } from '@modules/projects/utils/useCurrentProject';
import { DialogProps } from '@mui/material';
import { getTenantIdentifier } from '@utils/getTenantIdentifier';
import { useNotification } from '@utils/useNotification';
import { useQueryInvalidator } from '@utils/useQueryInvalidator';
import dayjs from 'dayjs';
import { useAddMeetingMutation, useMeetingsQuery, useMeetingTypesQuery } from 'gql/index';
import React, { useEffect, useState } from 'react';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { MeetingAvailabilityPickerStep } from './MeetingAvailabilityPickerStep';
import { MeetingRequestSummaryStep } from './MeetingRequestSummaryStep';
import { MeetingTypeSelector } from './MeetingTypeSelector';


type Props = DialogProps;

export const RequestMeetingDialog: React.FC<Props> = ({ ...dialogProps }) => {
  const form = useForm<AddMeetingFormValues>({ defaultValues: { proposedTimes: [] } });
  const { projectId } = useCurrentProject();
  const { formatMessage } = useIntl();
  const { mutate: addMeeting, isLoading: isAddingMeeting } = useAddMeetingMutation();
  const { data: meetingTypes } = useMeetingTypesQuery({ projectId });
  const meetingTypeId = useWatch({ control: form.control, name: 'meetingTypeId' });
  const meetingType = meetingTypes?.meetingTypes.find(p => p.id == meetingTypeId);
  const meetingTypeDuration = meetingType ? dayjs.duration(meetingType.duration).asMinutes() : 30;
  const navigate = useNavigate();
  const { notifySuccess } = useNotification();
  const invalidateQuery = useQueryInvalidator();

  useEffect(() => {
    form.reset();
  }, [form, dialogProps.open]);

  const onSubmit = (values: AddMeetingFormValues) => {
    if (!values.meetingTypeId) return;

    addMeeting({
      input: {
        projectId,
        description: undefined,
        meetingTypeId: values.meetingTypeId,
        proposedStartTimes: values.proposedTimes.map(p => dayjs(p.start).toISOString())
      }
    }, {
      onSuccess: () => {
        notifySuccess(formatMessage({ id: 'Meeting request was sent. Email confirmation will be sent once meeting is confirmed.' }));
        dialogProps.onClose?.({}, 'backdropClick');
        invalidateQuery(useMeetingsQuery);
        navigate(`/${getTenantIdentifier()}/projects/${projectId}/meetings/requests`);
      }
    });
  };

  const [activeStep, setActiveStep] = useState(0);

  return <>
    <FormProvider {...form}>
      <MultiStepFormDialog
        dialogProps={{
          ...dialogProps,
          maxWidth: activeStep === 1 ? 'xl' : 'md'
        }}

        activeStep={activeStep}
        onStepChange={setActiveStep}

        onSubmit={form.handleSubmit(onSubmit)}
        isSubmitting={isAddingMeeting}

        steps={[
          {
            title: formatMessage({ id: 'Select meeting type' }),
            subtitle: formatMessage({ id: 'Select the type of meeting you want to schedule' }),
            content: ({ nextStep }) => (
              <MeetingTypeSelector onSelected={meetingTypeId => {
                form.setValue('meetingTypeId', meetingTypeId);
                nextStep();
              }}
              />
            ),
          },
          {
            title: formatMessage({ id: 'Propose times' }),
            subtitle: formatMessage({ id: 'Select your availabilities' }),
            content: () => <MeetingAvailabilityPickerStep meetingDurationInMinutes={meetingTypeDuration} />,
            disablePadding: true,
            onNext: form.handleSubmit
          },
          {
            title: formatMessage({ id: 'Request summary' }),
            subtitle: formatMessage({ id: 'Review the details of your meeting request' }),
            content: () => <MeetingRequestSummaryStep meetingType={meetingType} />
          }
        ]}
      />
    </FormProvider>
  </>;
};
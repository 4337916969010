import { Stack } from '@mui/material';
import React, { ReactNode } from 'react';
import { TopBarAppLogo } from '../components/TopBar/TopBarAppLogo';
import { TopBarBase } from '../components/TopBar/TopBarBase';

export const ErrorPageLayout: React.FC<{ children: ReactNode }> = ({ children }) => {

  return (
    <Stack>
      <TopBarBase>
        <TopBarAppLogo />
      </TopBarBase>

      <Stack component="main" direction="row" p={{ xs: 1, md: 2, lg: 4 }} pt={{ lg: 2 }}>
        {children}
      </Stack>
    </Stack>
  );
};
import { CircularProgress, Stack } from '@mui/material';
import { useGetMe } from '@utils/useGetMe';
import { useNotification } from '@utils/useNotification';
import { SubmissionStatus, useFillFormAnonymouslyMutation, useProjectAccessTokenFormValuesQuery, useProjectAccessTokenQuery } from 'gql/index';
import React, { useCallback, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { Navigate, useParams } from 'react-router-dom';
import { getTenantIdentifier } from '../../../utils/getTenantIdentifier';
import { useResponsive } from '../../../utils/useResponsive';
import { AnonymousContextProvider } from '../AnonymousContextProvider';
import { AnonymousFormCompletedDialog } from '../components/AnonymousFormCompletedDialog';
import { AnonymousFormDetailsDialog } from '../components/AnonymousFormDetailsDialog';
import { RenewAccessTokenDialog } from '../components/RenewAccessTokenDialog';
import { UnauthorizedAccessDialog } from '../components/UnauthorizedAccessDialog';
import { AnonymousFormDesktopView } from './AnonymousFormDesktopView';
import { AnonymousFormMobileView } from './AnonymousFormMobileView';

export const AnonymousFormPage: React.FC = () => {
  const { token } = useParams();
  const { isMobile } = useResponsive();
  const { formatMessage } = useIntl();
  const { notifySuccess } = useNotification();

  const { data: content, isFetching: isPatFetching, refetch: refetchPat } = useProjectAccessTokenQuery({ token: token ?? '' }, {
    cacheTime: 0,
    keepPreviousData: false,
    select: d => d.projectAccessTokenContent
  });

  const form = content?.projectForm;

  const { me } = useGetMe();

  const [submissionReadyId, setSubmissionReadyId] = useState<number>();

  const { data: formValues, isFetching: isValuesFetching } = useProjectAccessTokenFormValuesQuery({ token: token ?? '' }, {
    cacheTime: 0,
    keepPreviousData: false,
    enabled: submissionReadyId != null,
    select: d => d.projectAccessTokenFormValues
  });

  const { mutate: fillForm, isLoading: isSubmittingForm } = useFillFormAnonymouslyMutation({
    onSuccess: () => {
      refetchPat();

      notifySuccess(formatMessage({ id: 'Form successfully submitted.' }));
    }
  });

  const onSubmit = useCallback(() => {
    if (!token) return;

    fillForm({ input: { token } });
  }, [fillForm, token]);


  const isFetching = isPatFetching || isValuesFetching;

  const cannotFillForm = !content?.projectForm?.isUserAuthorizedToFill;

  const formSubmitted = content?.formSubmission?.status === SubmissionStatus.Submitted;

  const inner = useMemo(() => {
    if (!token) return;

    if (cannotFillForm) {
      return <UnauthorizedAccessDialog />;
    }

    if (formSubmitted) {
      return <AnonymousFormCompletedDialog content={content} />;
    }

    if (content.tokenValidationError && content.isTokenRenewable) {
      return <RenewAccessTokenDialog token={token} />;
    }

    if (!submissionReadyId) {
      return <AnonymousFormDetailsDialog
        token={token ?? ''}
        content={content}
        onSubmissionStarted={submissionId => {
          refetchPat();
          setSubmissionReadyId(submissionId);
        }}
      />;
    }

    if (content.formSubmission?.formDefinition && formValues != null) {
      return isMobile ? (
        <AnonymousFormMobileView
          submissionId={submissionReadyId}
          isLoading={isFetching}
          isSubmitting={isSubmittingForm}
          content={content}
          formDefinition={content.formSubmission.formDefinition}
          formValues={formValues}
          token={token}
          onSubmit={onSubmit}
        />
      ) : (
        <AnonymousFormDesktopView
          submissionId={submissionReadyId}
          isLoading={isFetching}
          isSubmitting={isSubmittingForm}
          content={content}
          formDefinition={content.formSubmission.formDefinition}
          formValues={formValues}
          token={token ?? ''}
          onSubmit={onSubmit}
        />
      );
    }
  }, [cannotFillForm, content, formSubmitted, formValues, isFetching, isMobile, isSubmittingForm, onSubmit, refetchPat, submissionReadyId, token]);

  if (me != null && me.id == content?.forUser?.id && content?.project != null && form != null && submissionReadyId != null) {
    return <Navigate replace to={`/${getTenantIdentifier()}/projects/${content?.project?.id}/form/${form?.id}/${submissionReadyId}`} />;
  }

  if (isFetching) {
    return (
      <Stack height='100%' width='100%' alignItems='center' justifyContent='center'>
        <CircularProgress />
      </Stack>
    );
  }

  if (content?.projectForm == null) {
    return <Navigate to='/error/404' />;
  }

  return (
    <AnonymousContextProvider accessToken={token ?? ''}>
      {inner}
    </AnonymousContextProvider>
  );
};
import { CircularProgress, Stack } from '@mui/material';
import { WorkflowActionDefinitionFragmentFragment, WorkflowDefinitionFullFragmentFragment, useWorkflowActionDefinitionsQuery } from 'gql/index';
import React, { createContext, useMemo, useState } from 'react';

export interface WorkflowEditorContext {
  disabled?: boolean;
  workflowDefinition: WorkflowDefinitionFullFragmentFragment;
  actionDefinitions: WorkflowActionDefinitionFragmentFragment[];
  actionDefinitionsMap: Record<string, WorkflowActionDefinitionFragmentFragment>;
  selectedActionIndex: number | null;
  setSelectedActionIndex: (actionIndex: number | null) => void;
}

export const WorkflowEditorContext = createContext<WorkflowEditorContext>({} as WorkflowEditorContext);

export const WorkflowEditorContextProvider: React.FC<{
  children: React.ReactNode;
  disabled?: boolean;
  workflowDefinition: WorkflowDefinitionFullFragmentFragment;
}> = ({ children, workflowDefinition, disabled }) => {
  const [selectedActionIndex, setSelectedActionIndex] = useState<number | null>(null);

  const { data: actionDefinitions } = useWorkflowActionDefinitionsQuery(undefined, { select: d => d.workflowActionDefinitions });

  const actionDefinitionsMap = useMemo(() => {
    const map: Record<string, WorkflowActionDefinitionFragmentFragment> = {};

    if (!actionDefinitions) return map;

    for (const action of actionDefinitions) {
      map[action.actionDefinitionId] = action;
    }

    return map;
  }, [actionDefinitions]);

  return (
    <WorkflowEditorContext.Provider value={{
      disabled,
      workflowDefinition,
      selectedActionIndex,
      setSelectedActionIndex,
      actionDefinitions: actionDefinitions ?? [],
      actionDefinitionsMap
    }}>
      {actionDefinitions ? children : (
        <Stack height='100%' width='100%' alignItems='center' justifyContent='center'>
          <CircularProgress />
        </Stack>
      )}
    </WorkflowEditorContext.Provider>
  );
};
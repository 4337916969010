import { DraggableItemType } from '@components/Editor/DraggableItemType';
import { Droppable } from '@hello-pangea/dnd';
import { AccordionSelectionPanel } from '@modules/forms/components/FormEditor/FieldSelectionPanel/AccordionSelectionPanel';
import { Box, SvgIcon } from '@mui/material';
import { useWorkflowActionDefinitionsQuery } from 'gql/index';
import { useContext } from 'react';
import { useIntl } from 'react-intl';
import { ActionNameByType } from '../../WorflowInstance/Actions/Types';
import { ActionConfigurationIconById, AllActionIds } from '../Actions/types';
import { WorkflowEditorContext } from '../WorkflowEditorContext';
import { WorkflowEditorDroppableIds, WorkflowEditorDroppableTypes } from '../types';

export const ActionSelectionPanel: React.FC = () => {
  const { formatMessage } = useIntl();

  const { disabled } = useContext(WorkflowEditorContext);

  const { data: allActions } = useWorkflowActionDefinitionsQuery(undefined, { select: d => d.workflowActionDefinitions });

  return <>
    <Box style={{ height: '100%' }} mt={1}>
      <AccordionSelectionPanel header={formatMessage({ id: 'Actions' })} defaultExpanded>
        <Droppable
          droppableId={WorkflowEditorDroppableIds.actionsSelectionPanel}
          type={WorkflowEditorDroppableTypes.step}
          isDropDisabled={true}
        >
          {provided => (
            <Box ref={provided.innerRef} {...provided.droppableProps}>
              {allActions?.map((action, index) => (
                <DraggableItemType
                  disabled={disabled}
                  key={action.actionDefinitionId}
                  id={action.actionDefinitionId}
                  index={index}
                  icon={(
                    <SvgIcon component={ActionConfigurationIconById[action.actionDefinitionId as AllActionIds]} />
                  )}
                  text={formatMessage(ActionNameByType[action.actionDefinitionId as AllActionIds])}
                />
              ))}
            </Box>
          )}
        </Droppable>
      </AccordionSelectionPanel>
    </Box>
  </>;
};
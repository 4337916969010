import { FieldValueType, WorkflowActionStatus, WorkflowVariableFragmentFragment, WorkflowVariableValueTypes } from 'gql/index';

export interface ReactHookArrayElement {
  uniqueId: string;
}

export interface WorkflowEditorValues {
  id: number;
  name: string;
  description: string;
  actions: WorkflowEditorActionValue[];
  variables: WorkflowVariableFragmentFragment[];
}

export interface WorkflowEditorActionValue {
  id: number;
  workflowActionDefinitionId: string;
  name: string;
  fieldValues: WorkflowEditoryActionFieldValue[];
  order: number;
  status: WorkflowActionStatus;
}

export type WorkflowEditoryActionFieldValue = {
  fieldId: string,
  type: FieldValueType,
  variableId: number | '',
  value: unknown | '',
  fieldValueType: WorkflowVariableValueTypes | ''
};

export interface WorkflowEditorActionFieldValue {
  serializedValue?: string;
}

export const WorkflowEditorDroppableTypes = {
  step: 'step'
};

export const WorkflowEditorDroppableIds = {
  actionsSelectionPanel: 'actionsSelectionPanel',
  steps: 'steps'
};
import { PresenceDecorator } from '@components/Tooltips/PresenceDecorator';
import { Tooltip, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { useContextSafe } from '@utils/useContextSafe';
import { MatrixColumnType } from 'gql/index';
import { useIntl } from 'react-intl';
import { FormFillerContext } from '../../FormFillerContextProvider';
import { useFormFillerPresence } from '../../useFormFillerPresence';

interface Props {
  rowId: number,
  columnId: number,
  value: unknown,
  fieldType: MatrixColumnType;
}

export const MatrixFillerCell = ({ rowId, columnId, value, fieldType }: Props) => {
  const { submissionId } = useContextSafe(FormFillerContext);
  const { presences } = useFormFillerPresence(submissionId);

  const presentUser = presences.find(p => p.matrixRowId == rowId && p.matrixColumnId == columnId);
  const { formatMessage } = useIntl();

  return (
    <Stack p='1px' overflow={'auto'} height='100%' width='100%' alignItems='center' justifyContent='center' direction='row'>
      <PresenceDecorator fullWidth userDisplayName={presentUser?.displayName}>
        {fieldType === MatrixColumnType.Boolean && (
          <span style={{ textAlign: 'center' }}>
            {value === true ? formatMessage({ id: 'Yes' })
              : value === false ? formatMessage({ id: 'No' })
                : '-'}
          </span>
        )}
        {(fieldType === MatrixColumnType.Text) && (
          <Tooltip title={(value as string | number) ?? ''}>
            <Typography sx={{ wordWrap: 'break-word', whiteSpace: 'break-spaces', maxHeight: '100%', maxWidth: '100%', '::-webkit-scrollbar': { height: 2 } }}>
              {(value as string | number) ?? ''}
            </Typography>
          </Tooltip>
        )}
        {(fieldType === MatrixColumnType.Numerical) && (
          <Tooltip title={(value as string | number) ?? ''}>
            <Typography align='center'>
              {(value as string | number) ?? ''}
            </Typography>
          </Tooltip>
        )}
      </PresenceDecorator>
    </Stack>
  );
};
import { Draggable } from '@hello-pangea/dnd';
import { DragHandle } from '@mui/icons-material';
import { Card, CardActionArea, CardActions, CardContent, Chip, IconButton, Stack, SvgIcon, Typography } from '@mui/material';
import { notNullOrUndefined } from '@utils/notNullOrUndefined';
import { WorkflowVariableValueTypes } from 'gql/index';
import React, { useContext } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { ActionConfigurationIconById, AllActionIds } from '../Actions/types';
import { WorkflowEditorContext } from '../WorkflowEditorContext';
import { WorkflowEditorValues } from '../types';
import { WorkflowActionConfigurationActions } from './WorkflowActionConfigurationActions';

interface Props {
  actionIndex: number;
}

export const ActionTile: React.FC<Props> = ({ actionIndex }) => {
  const { setSelectedActionIndex, selectedActionIndex, disabled } = useContext(WorkflowEditorContext);
  const { control } = useFormContext<WorkflowEditorValues>();
  const { workflowDefinition } = useContext(WorkflowEditorContext);
  const isSelected = actionIndex === selectedActionIndex;

  const action = useWatch({ control: control, name: `actions.${actionIndex}` });

  const partiticipants = action.fieldValues
    .filter(p => p.fieldValueType === WorkflowVariableValueTypes.User)
    .map(p => workflowDefinition.variables.find(q => q.id === p.variableId))
    .filter(notNullOrUndefined);


  return (
    <Draggable isDragDisabled={disabled} draggableId={action.id.toString()} index={actionIndex}>{provided => {
      return (
        <Stack ref={provided.innerRef} {...provided.draggableProps} alignItems={'center'}>
          <Card
            sx={{
              width: 400,
              border: isSelected ? '1px solid' : 'default',
              backgroundColor: isSelected ? 'action.selected' : 'default'
            }}
          >
            <CardActionArea disabled={disabled} onClick={() => setSelectedActionIndex(actionIndex)}>
              <Stack justifyContent={'center'} alignItems={'center'}>
                <IconButton {...provided.dragHandleProps} sx={{ visibility: disabled ? 'hidden' : 'visible' }}>
                  <DragHandle />
                </IconButton>
              </Stack>

              <CardContent sx={{ pt: 0 }}>
                <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'} spacing={1} flex={1}>
                  <Stack direction={'row'} alignItems={'center'} spacing={1}>
                    <SvgIcon color='primary' component={ActionConfigurationIconById[action.workflowActionDefinitionId as AllActionIds]} />
                    <Typography variant='body1' fontWeight={600}>
                      {action.name}
                    </Typography>
                  </Stack>

                  {!disabled && (
                    <WorkflowActionConfigurationActions workflowTemplateId={workflowDefinition.id} workflowActionConfiguration={action} />
                  )}
                </Stack>
              </CardContent>

              <CardActions sx={{ p: 2 }} >
                {partiticipants.map(p => <Chip size='small' key={p.id} label={p.name}></Chip>)}
              </CardActions>
            </CardActionArea>
          </Card>
        </Stack>
      );
    }}</Draggable>
  );
};
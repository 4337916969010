import { FormHelperText } from '@mui/material';
import { useIntl } from 'react-intl';
import { WorkflowFieldInput } from '../../Fields/WorkflowFieldInput';
import { ActionConfigurationComponentProps } from '../types';

export const ConfigureDocumentSignatureActionDefinition = {
  ActionDefinitionId: 'Internal.ConfigureDocumentSignature',
  FieldId_Document: 'c3f2d48a-3338-4052-bf33-9ea1bc8ebdb7',
  FieldId_Signatory: '6411516a-0fed-4e95-ae89-e6497f7c3877',
  FieldId_SignatureConfigureBy: 'ee366166-0675-448f-86c9-a535b26b2a7f',
  FieldId_SignaturePlacement: '9e1282f3-dd25-4f62-bc2e-a07a611b3112'
} as const;

export const ConfigureDocumentSignatureAction: React.FC<ActionConfigurationComponentProps> = () => {
  const { formatMessage } = useIntl();

  return (
    <>

      <WorkflowFieldInput

        fieldId={ConfigureDocumentSignatureActionDefinition.FieldId_SignatureConfigureBy}
        label={formatMessage({ id: 'Signature to be configured by' })}

      />
      <FormHelperText>
        {formatMessage({ id: 'After the workflow is started and reaches this action, the user assigned with this role will be asked to select the document to sign, assign a signatory and place the signature fields over the PDF.' })}
      </FormHelperText>
    </>
  );
};
import { FormDrawer } from '@components/Drawers/FormDrawer';
import { useCurrentProject } from '@modules/projects/utils/useCurrentProject';
import { PrincipalPicker } from '@modules/users/components/PrincipalPicker';
import { DrawerProps, Stack, TextField } from '@mui/material';
import { useNotification } from '@utils/useNotification';
import { useQueryInvalidator } from '@utils/useQueryInvalidator';
import { useValidationRules } from '@utils/useValidationRules';
import dayjs from 'dayjs';
import { MeetingTypesQuery, PrincipalType, useAddMeetingTypeMutation, useEditMeetingTypeMutation, useMeetingTypesQuery } from 'gql/index';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';


interface FormValues {
  name: string;
  description: string;
  durationMinutes: string;
  userIds: string[];
}

const defaultValues: FormValues = {
  name: '',
  description: '',
  durationMinutes: '15',
  userIds: []
};

type Props = DrawerProps & {
  meetingType?: MeetingTypesQuery['meetingTypes'][number];
};

const maxMinutes = 60 * 7;

export const MeetingTypeDrawer = ({ meetingType, ...drawerProps }: Props) => {
  const { notEmpty, validateNumber, validateIsPositive } = useValidationRules();
  const invalidateQuery = useQueryInvalidator();
  const { notifySuccess } = useNotification();
  const { formatMessage } = useIntl();
  const { projectId } = useCurrentProject();

  const { control, handleSubmit, reset } = useForm<FormValues>({ defaultValues });

  useEffect(() => {
    if (meetingType) {
      reset({
        name: meetingType.name,
        description: meetingType.description ?? '',
        durationMinutes: dayjs.duration(meetingType.duration).asMinutes().toString(),
        userIds: meetingType.meetingRequestsOwners.map(p => p.id)
      });
    } else {
      reset(defaultValues);
    }
  }, [meetingType, reset, drawerProps.open]);

  const { mutate: addMeetingType } = useAddMeetingTypeMutation({
    onSuccess: () => {
      drawerProps.onClose?.({}, 'backdropClick');
      notifySuccess(formatMessage({ id: 'Created meeting type successfully' }));
      invalidateQuery(useMeetingTypesQuery);
    }
  });

  const { mutate: editMeetingType } = useEditMeetingTypeMutation({
    onSuccess: () => {
      drawerProps.onClose?.({}, 'backdropClick');
      notifySuccess(formatMessage({ id: 'Edited meeting type successfully' }));
      invalidateQuery(useMeetingTypesQuery);
    }
  });

  const onSubmit = (values: FormValues) => {
    if (meetingType?.id) {
      editMeetingType({
        input: {
          id: meetingType.id,
          projectId,
          name: values.name,
          description: values.description,
          durationMinutes: Number(values.durationMinutes),
          userIds: values.userIds
        }
      });
    } else {
      addMeetingType({
        input: {
          name: values.name,
          projectId,
          description: values.description,
          durationMinutes: Number(values.durationMinutes),
          userIds: values.userIds
        }
      });
    }
  };

  return (
    <FormDrawer
      {...drawerProps}
      header={meetingType ? formatMessage({ id: 'Edit meeting type' }) : formatMessage({ id: 'Add meeting type' })}
      showFooter
      onSave={handleSubmit(onSubmit)}
    >
      <Stack p={2} gap={2}>
        <Controller
          control={control}
          name='name'
          rules={{ validate: notEmpty }}
          render={({ field, fieldState: { error } }) => (
            <TextField
              required
              label={formatMessage({ id: 'Name' })}
              {...field}
              error={!!error}
              helperText={error?.message}
            />
          )}
        />

        <Controller
          control={control}
          name='description'
          render={({ field, fieldState: { error } }) => (
            <TextField
              label={formatMessage({ id: 'Description' })}
              {...field}
              error={!!error}
              helperText={error?.message}
            />
          )}
        />


        <Controller
          control={control}
          name='durationMinutes'
          rules={{
            validate: {
              notEmpty,
              validateNumber,
              validateIsPositive,
              maxMinutes: value => Number(value) > maxMinutes ? formatMessage({ id: 'Meetings cannot be that long' }) : undefined
            }
          }}
          render={({ field, fieldState: { error } }) => (
            <TextField
              label={formatMessage({ id: 'Duration (minutes)' })}
              {...field}
              required
              type='number'
              inputProps={{
                step: 5
              }}
              error={!!error}
              helperText={error?.message}
            />
          )}
        />

        <Controller
          control={control}
          name='userIds'
          rules={{
            validate: { notEmpty }
          }}
          render={({ field, fieldState: { error } }) => (
            <PrincipalPicker
              multiple
              includeMe
              usersOnly
              internalUsersOnly
              required
              label={formatMessage({ id: 'Assign meeting requests to' })}
              value={field.value.map(id => ({ id, principalType: PrincipalType.User }))}
              onChange={users => field.onChange(users.map(u => u.id))}
              error={error?.message}
            />
          )}
        />
      </Stack>
    </FormDrawer>
  );
};
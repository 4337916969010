import { Checklist, Draw, Task } from '@mui/icons-material';
import React from 'react';
import { WorkflowEditorActionValue } from '../types';
import { ConfigureDocumentSignatureAction, ConfigureDocumentSignatureActionDefinition } from './ConfigureDocumentSignature/ConfigureDocumentSignatureAction';
import { FillFormAction, FillFormActionDefinition } from './FillForm/FillFormAction';
import { TaskAction, TaskActionDefinition } from './Task/TaskAction';

export type ActionConfigurationComponentProps = {
  actionConfiguration: WorkflowEditorActionValue;
};

export type ActionDefinitionIds = {
  actionDefinitionId: string;
};

export const allActions = [
  ConfigureDocumentSignatureActionDefinition,
  FillFormActionDefinition,
  TaskActionDefinition,
] as const;
export type AllActionIds = typeof allActions[number]['ActionDefinitionId'];

export const ActionConfigurationComponentById: Record<AllActionIds, React.FC<ActionConfigurationComponentProps>> = {
  'Internal.FillForm': FillFormAction,
  'Internal.Task': TaskAction,
  'Internal.ConfigureDocumentSignature': ConfigureDocumentSignatureAction,
};

export const ActionConfigurationIconById: Record<AllActionIds, React.FC> = {
  'Internal.FillForm': Checklist,
  'Internal.Task': Task,
  'Internal.ConfigureDocumentSignature': Draw,
};
import { BuiltInRoles } from 'gql/index';
import { MessageDescriptor, defineMessages } from 'react-intl';

export const userRoleMessages: Record<BuiltInRoles, MessageDescriptor> = defineMessages({
  Customer: { id: 'Customer' },
  LimitedAccess: { id: 'Limited Access' },
  Reader: { id: 'Reader' },
  Contributor: { id: 'Contributor' },
  TenantAdministrator: { id: 'Tenant Administrator' },
  MultiTenantAdministrator: { id: 'Global Administrator' },
  ProjectAdministrator: { id: 'Project Administrator' },
  BudgetAndExpensesAdmin: { id: 'Budget Administrator' },
  BudgetAndExpensesReader: { id: 'Budget Reader' },
  ExpensesAdmin: { id: 'Expenses Administrator' },
  ExpensesReader: { id: 'Expenses Reader' },
  FormsAdmin: { id: 'Forms Administrator' },
  TasksAdmin: { id: 'Tasks Administrator' },
  TasksReader: { id: 'Tasks Reader' },
  FilesAdmin: { id: 'Files Administrator' },
  WorkflowsAdmin: { id: 'Workflows Administrator' }
});

export const userRoleDescriptionMessages: Partial<Record<BuiltInRoles, MessageDescriptor>> = defineMessages({
  ProjectAdministrator: { id: 'Project administrators can create projects and only view and manage projects they are assigned to.' },
  Contributor: { id: 'Project contributors cannot create projects but can manage projects they have been assigned to.' },
  TenantAdministrator: { id: 'Tenant administrators can access all projects and invite new administrators.' },
});
import { createEvent } from 'react-event-hook';

interface ChildActionAddedEvent {
  parentActionConfigurationId: number;
  destinationId: string;
  createdAtIndex: number;
  actionDefinitionId: string;
}

export const { emitChildActionAdded, useChildActionAddedListener } = createEvent('childActionAdded')<ChildActionAddedEvent>();

interface ChildActionMovedEvent {
  parentActionConfigurationId: number;
  sourceIndex: number;
  destinationIndex: number;
  movedActionConfigurationId: number;
}

export const { emitChildActionMoved, useChildActionMovedListener } = createEvent('childActionMoved')<ChildActionMovedEvent>();

import { PrincipalPicker } from '@modules/users/components/PrincipalPicker';
import { IWorkflowPrincipal } from '@modules/workflow/types';
import { TextField, TextFieldProps } from '@mui/material';
import { WorkflowVariableValueTypes } from 'gql/index';
import typia from 'typia';
import { WorkflowEditorTemplateInput } from './WorkflowEditorTemplateInput';

export type VariableValueInputComponentProps = {
  variableType: WorkflowVariableValueTypes;
  required: boolean;
  onChange: (value: unknown) => void;
  value: unknown | null | undefined;
  label: string;
  error?: string;
  disabled?: boolean;
  textFieldProps?: TextFieldProps;
};
export const VariableValueInputComponent: React.FC<VariableValueInputComponentProps> = ({ onChange, value, variableType, required, label, error, textFieldProps, disabled }) => {
  switch (variableType) {
    case WorkflowVariableValueTypes.FormTemplate: {
      let templateId: number | undefined;

      if (value != null) {
        templateId = Number(value);
      }

      return <WorkflowEditorTemplateInput disabled={disabled} required={required} onChange={onChange} error={error} selectedTemplateId={templateId} />;
    }
    case WorkflowVariableValueTypes.String: {
      return <TextField {...textFieldProps} disabled={disabled} required={required} label={label} value={value} onChange={e => onChange(e.currentTarget.value)} error={!!error} helperText={error} />;
    }
    case WorkflowVariableValueTypes.User: {
      const principal = value == null ? null : typia.assert<IWorkflowPrincipal>(value);
      return <PrincipalPicker label={label} disabled={disabled} required={required} error={error} onChange={value => onChange(value.at(0))} value={principal ? [principal] : []} usersOnly includeMe />;
    }
    default: {
      throw Error(`Variable type ${variableType} not implemented.`);
    }
  }
};

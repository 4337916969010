import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  Byte: { input: any; output: any; }
  DateTime: { input: any; output: any; }
  Decimal: { input: any; output: any; }
  Long: { input: any; output: any; }
  TimeSpan: { input: any; output: any; }
  UUID: { input: any; output: any; }
  Upload: { input: any; output: any; }
};

export type AcceptMeetingRequestError = NotFoundError | ValidationResultError;

export type AcceptMeetingRequestInput = {
  meetingId: Scalars['Int']['input'];
  projectId: Scalars['Int']['input'];
  proposalId: Scalars['Int']['input'];
};

export type AcceptMeetingRequestPayload = {
  __typename?: 'AcceptMeetingRequestPayload';
  errors?: Maybe<Array<AcceptMeetingRequestError>>;
  meetingProposal?: Maybe<MeetingProposal>;
};

export type AccessTokenExpiredError = Error & {
  __typename?: 'AccessTokenExpiredError';
  message: Scalars['String']['output'];
};

export type AccessTokenInvalidError = Error & {
  __typename?: 'AccessTokenInvalidError';
  message: Scalars['String']['output'];
};

export type ActionFieldValueInput = {
  fieldId: Scalars['UUID']['input'];
  fieldValueType: FieldValueType;
  serializedValue?: InputMaybe<Scalars['String']['input']>;
  variableId?: InputMaybe<Scalars['Int']['input']>;
};

export type AddBudgetItemError = NotFoundError | ValidationResultError;

export type AddBudgetItemInput = {
  budgetedAmount: Scalars['Decimal']['input'];
  name: Scalars['String']['input'];
  projectId: Scalars['Int']['input'];
};

export type AddBudgetItemPayload = {
  __typename?: 'AddBudgetItemPayload';
  budgetItem?: Maybe<BudgetItem>;
  errors?: Maybe<Array<AddBudgetItemError>>;
};

export type AddChildActionError = NotFoundError | ValidationResultError;

export type AddChildActionInput = {
  actionConfigurationId: Scalars['Int']['input'];
  actionDefinitionId: Scalars['String']['input'];
  defaultName?: InputMaybe<Scalars['String']['input']>;
  orderIndex: Scalars['Int']['input'];
  workflowDefinitionId: Scalars['Int']['input'];
};

export type AddChildActionPayload = {
  __typename?: 'AddChildActionPayload';
  errors?: Maybe<Array<AddChildActionError>>;
  workflowActionConfiguration?: Maybe<WorkflowActionConfiguration>;
};

export type AddConditionError = NotFoundError | ValidationResultError;

export type AddConditionInput = {
  effectFieldId?: InputMaybe<Scalars['Int']['input']>;
  effectSectionId?: InputMaybe<Scalars['Int']['input']>;
  effectVisibility?: InputMaybe<ConditionEffectVisibilityInput>;
  formDefinitionId: Scalars['Int']['input'];
  triggerFieldId: Scalars['Int']['input'];
  triggerValueEquality?: InputMaybe<ConditionTriggerValueEqualityInput>;
};

export type AddConditionPayload = {
  __typename?: 'AddConditionPayload';
  errors?: Maybe<Array<AddConditionError>>;
  formCondition?: Maybe<FormCondition>;
};

export type AddExpenseError = NotFoundError | ValidationResultError;

export type AddExpenseInput = {
  budgetItemId?: InputMaybe<Scalars['Int']['input']>;
  expendedAmount: Scalars['Decimal']['input'];
  expenseDate: Scalars['DateTime']['input'];
  name: Scalars['String']['input'];
  projectId: Scalars['Int']['input'];
  status: ExpenseStatus;
};

export type AddExpensePayload = {
  __typename?: 'AddExpensePayload';
  errors?: Maybe<Array<AddExpenseError>>;
  expense?: Maybe<Expense>;
};

export type AddExternalLinkError = ValidationResultError;

export type AddExternalLinkInput = {
  folderId: Scalars['Int']['input'];
  link: Scalars['String']['input'];
  name: Scalars['String']['input'];
  projectId: Scalars['Int']['input'];
};

export type AddExternalLinkPayload = {
  __typename?: 'AddExternalLinkPayload';
  errors?: Maybe<Array<AddExternalLinkError>>;
  externalLink?: Maybe<ExternalLink>;
};

export type AddExternalUserError = NotFoundError | ValidationResultError;

export type AddExternalUserInput = {
  business?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  projectId: Scalars['Int']['input'];
  securityGroupId: Scalars['String']['input'];
  title?: InputMaybe<Scalars['String']['input']>;
};

export type AddExternalUserPayload = {
  __typename?: 'AddExternalUserPayload';
  applicationUser?: Maybe<ApplicationUser>;
  errors?: Maybe<Array<AddExternalUserError>>;
};

export type AddFolderError = ValidationResultError;

export type AddFolderInput = {
  name: Scalars['String']['input'];
  parentFolderId: Scalars['Int']['input'];
  projectId: Scalars['Int']['input'];
};

export type AddFolderPayload = {
  __typename?: 'AddFolderPayload';
  errors?: Maybe<Array<AddFolderError>>;
  folder?: Maybe<Folder>;
};

export type AddMatrixRowError = NotFoundError | ValidationResultError;

export type AddMatrixRowInput = {
  matrixFieldId: Scalars['Int']['input'];
  submissionId: Scalars['Int']['input'];
};

export type AddMatrixRowPatError = NotFoundError | ValidationResultError;

export type AddMatrixRowPatInput = {
  accessToken: Scalars['String']['input'];
  matrixFieldId: Scalars['Int']['input'];
  submissionId: Scalars['Int']['input'];
};

export type AddMatrixRowPatPayload = {
  __typename?: 'AddMatrixRowPATPayload';
  errors?: Maybe<Array<AddMatrixRowPatError>>;
  matrixRow?: Maybe<MatrixRow>;
};

export type AddMatrixRowPayload = {
  __typename?: 'AddMatrixRowPayload';
  errors?: Maybe<Array<AddMatrixRowError>>;
  matrixRow?: Maybe<MatrixRow>;
};

export type AddMeetingError = NotFoundError | ValidationResultError;

export type AddMeetingInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  meetingTypeId: Scalars['Int']['input'];
  projectId: Scalars['Int']['input'];
  proposedStartTimes: Array<Scalars['DateTime']['input']>;
};

export type AddMeetingPayload = {
  __typename?: 'AddMeetingPayload';
  errors?: Maybe<Array<AddMeetingError>>;
  meeting?: Maybe<Meeting>;
};

export type AddMeetingTypeError = NotFoundError | ValidationResultError;

export type AddMeetingTypeInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  durationMinutes: Scalars['Int']['input'];
  name: Scalars['String']['input'];
  projectId: Scalars['Int']['input'];
  userIds: Array<Scalars['String']['input']>;
};

export type AddMeetingTypePayload = {
  __typename?: 'AddMeetingTypePayload';
  errors?: Maybe<Array<AddMeetingTypeError>>;
  meetingType?: Maybe<MeetingType>;
};

export type AddProjectError = NotFoundError | ValidationResultError;

export type AddProjectFormError = ValidationResultError;

export type AddProjectFormFromTemplateError = ValidationResultError;

export type AddProjectFormFromTemplateInput = {
  formType: FormType;
  fromFormTemplateId: Scalars['Int']['input'];
  projectId: Scalars['Int']['input'];
};

export type AddProjectFormFromTemplatePayload = {
  __typename?: 'AddProjectFormFromTemplatePayload';
  errors?: Maybe<Array<AddProjectFormFromTemplateError>>;
  projectForm?: Maybe<ProjectForm>;
};

export type AddProjectFormInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  formType: FormType;
  name: Scalars['String']['input'];
  projectId: Scalars['Int']['input'];
};

export type AddProjectFormPayload = {
  __typename?: 'AddProjectFormPayload';
  errors?: Maybe<Array<AddProjectFormError>>;
  projectForm?: Maybe<ProjectForm>;
};

export type AddProjectInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  hideBudgetFeature: Scalars['Boolean']['input'];
  name: Scalars['String']['input'];
};

export type AddProjectMemberError = NotFoundError | ValidationResultError;

export type AddProjectMemberInput = {
  projectId: Scalars['Int']['input'];
  securityGroupId: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};

export type AddProjectMemberPayload = {
  __typename?: 'AddProjectMemberPayload';
  errors?: Maybe<Array<AddProjectMemberError>>;
  project?: Maybe<Project>;
};

export type AddProjectPayload = {
  __typename?: 'AddProjectPayload';
  errors?: Maybe<Array<AddProjectError>>;
  project?: Maybe<Project>;
};

export type AddSecurityGroupError = NotFoundError | ValidationResultError;

export type AddSecurityGroupInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  projectId: Scalars['Int']['input'];
};

export type AddSecurityGroupPayload = {
  __typename?: 'AddSecurityGroupPayload';
  errors?: Maybe<Array<AddSecurityGroupError>>;
  securityGroup?: Maybe<SecurityGroup>;
};

export type AddTaskError = NotFoundError | ValidationResultError;

export type AddTaskInput = {
  assigneeIds: Array<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  dueDate?: InputMaybe<Scalars['DateTime']['input']>;
  name: Scalars['String']['input'];
  parentTaskId?: InputMaybe<Scalars['Int']['input']>;
  predecessorIds: Array<Scalars['Int']['input']>;
  projectId: Scalars['Int']['input'];
  startDate?: InputMaybe<Scalars['DateTime']['input']>;
  status: ProjectTaskStatus;
};

export type AddTaskPayload = {
  __typename?: 'AddTaskPayload';
  errors?: Maybe<Array<AddTaskError>>;
  projectTask?: Maybe<ProjectTask>;
};

export type AddTaskPredecessorError = ValidationResultError;

export type AddTaskPredecessorInput = {
  predecessorId: Scalars['Int']['input'];
  projectId: Scalars['Int']['input'];
  taskId: Scalars['Int']['input'];
};

export type AddTaskPredecessorPayload = {
  __typename?: 'AddTaskPredecessorPayload';
  errors?: Maybe<Array<AddTaskPredecessorError>>;
  projectTask?: Maybe<ProjectTask>;
};

export type AddTenantError = DuplicateNameError | MissingConfigurationError | ValidationResultError;

export type AddTenantInput = {
  adminInput: TenantAdminInput;
  tenantInput: TenantInput;
};

export type AddTenantPayload = {
  __typename?: 'AddTenantPayload';
  errors?: Maybe<Array<AddTenantError>>;
  tenant?: Maybe<Tenant>;
};

export type AddWorkflowDefinitionError = NotFoundError | ValidationResultError;

export type AddWorkflowDefinitionInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
};

export type AddWorkflowDefinitionPayload = {
  __typename?: 'AddWorkflowDefinitionPayload';
  errors?: Maybe<Array<AddWorkflowDefinitionError>>;
  workflowDefinition?: Maybe<WorkflowDefinition>;
};

export type AddWorkflowVariableError = NotFoundError | ValidationResultError;

export type AddWorkflowVariableInput = {
  name: Scalars['String']['input'];
  valueType: WorkflowVariableValueTypes;
  workflowDefinitionId: Scalars['Int']['input'];
};

export type AddWorkflowVariablePayload = {
  __typename?: 'AddWorkflowVariablePayload';
  errors?: Maybe<Array<AddWorkflowVariableError>>;
  workflowVariable?: Maybe<WorkflowVariable>;
};

export enum AllowedRespondents {
  AllProjectMembers = 'AllProjectMembers',
  SpecificUsersOrGroups = 'SpecificUsersOrGroups'
}

export type AllowedRespondentsOperationFilterInput = {
  eq?: InputMaybe<AllowedRespondents>;
  in?: InputMaybe<Array<AllowedRespondents>>;
  neq?: InputMaybe<AllowedRespondents>;
  nin?: InputMaybe<Array<AllowedRespondents>>;
};

export type AppSubscription = {
  __typename?: 'AppSubscription';
  asOfDate: Scalars['DateTime']['output'];
  isRegisteredWithLicenseService: Scalars['Boolean']['output'];
  products: Array<AppSubscriptionProduct>;
  referralId?: Maybe<Scalars['String']['output']>;
  status?: Maybe<SubscriptionStatus>;
  subscriptionId?: Maybe<Scalars['UUID']['output']>;
  trialEnd?: Maybe<Scalars['DateTime']['output']>;
  trialStart?: Maybe<Scalars['DateTime']['output']>;
};

export type AppSubscriptionModel = {
  __typename?: 'AppSubscriptionModel';
  hasSubscription: Scalars['Boolean']['output'];
  subscription?: Maybe<AppSubscription>;
  trialDaysRemaining?: Maybe<Scalars['Int']['output']>;
};

export type AppSubscriptionProduct = {
  __typename?: 'AppSubscriptionProduct';
  quantity: Scalars['Long']['output'];
  sku: Scalars['String']['output'];
};

export type ApplicationRole = {
  __typename?: 'ApplicationRole';
  builtInRole: BuiltInRoles;
  concurrencyStamp?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  normalizedName?: Maybe<Scalars['String']['output']>;
  securityGroups: Array<SecurityGroup>;
  users: Array<ApplicationUser>;
};

export type ApplicationRoleFilterInput = {
  and?: InputMaybe<Array<ApplicationRoleFilterInput>>;
  builtInRole?: InputMaybe<BuiltInRolesOperationFilterInput>;
  concurrencyStamp?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<StringOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  normalizedName?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<ApplicationRoleFilterInput>>;
  securityGroups?: InputMaybe<ListFilterInputTypeOfSecurityGroupFilterInput>;
  users?: InputMaybe<ListFilterInputTypeOfApplicationUserFilterInput>;
};

export type ApplicationUser = {
  __typename?: 'ApplicationUser';
  builtInRoles: Array<BuiltInRoles>;
  business?: Maybe<Scalars['String']['output']>;
  email: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  fullName: Scalars['String']['output'];
  groups: Array<SecurityGroup>;
  id: Scalars['String']['output'];
  language: Scalars['String']['output'];
  lastName: Scalars['String']['output'];
  optedOutOfWeeklyEmails: Scalars['Boolean']['output'];
  phoneNumber?: Maybe<Scalars['String']['output']>;
  roles: Array<ApplicationRole>;
  title?: Maybe<Scalars['String']['output']>;
};


export type ApplicationUserGroupsArgs = {
  where?: InputMaybe<SecurityGroupFilterInput>;
};

export type ApplicationUserFilterInput = {
  and?: InputMaybe<Array<ApplicationUserFilterInput>>;
  builtInRoles?: InputMaybe<ListBuiltInRolesOperationFilterInput>;
  business?: InputMaybe<StringOperationFilterInput>;
  email?: InputMaybe<StringOperationFilterInput>;
  firstName?: InputMaybe<StringOperationFilterInput>;
  fullName?: InputMaybe<StringOperationFilterInput>;
  groups?: InputMaybe<ListFilterInputTypeOfSecurityGroupFilterInput>;
  id?: InputMaybe<StringOperationFilterInput>;
  language?: InputMaybe<StringOperationFilterInput>;
  lastName?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<ApplicationUserFilterInput>>;
  phoneNumber?: InputMaybe<StringOperationFilterInput>;
  projectAccessTokens?: InputMaybe<ListFilterInputTypeOfProjectAccessTokenFilterInput>;
  roles?: InputMaybe<ListFilterInputTypeOfApplicationRoleFilterInput>;
  title?: InputMaybe<StringOperationFilterInput>;
};

export type ApplicationUserFromAnonymousLink = {
  __typename?: 'ApplicationUserFromAnonymousLink';
  business?: Maybe<Scalars['String']['output']>;
  firstName: Scalars['String']['output'];
  fullName: Scalars['String']['output'];
  id: Scalars['String']['output'];
  lastName: Scalars['String']['output'];
  phoneNumber?: Maybe<Scalars['String']['output']>;
  phoneNumberExtension?: Maybe<Scalars['Int']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type ApplicationUserSortInput = {
  accessFailedCount?: InputMaybe<SortEnumType>;
  business?: InputMaybe<SortEnumType>;
  concurrencyStamp?: InputMaybe<SortEnumType>;
  email?: InputMaybe<SortEnumType>;
  emailConfirmed?: InputMaybe<SortEnumType>;
  firstName?: InputMaybe<SortEnumType>;
  fullName?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  language?: InputMaybe<SortEnumType>;
  lastName?: InputMaybe<SortEnumType>;
  lastWeeklyProjectUpdateSentOn?: InputMaybe<SortEnumType>;
  lockoutEnabled?: InputMaybe<SortEnumType>;
  lockoutEnd?: InputMaybe<SortEnumType>;
  normalizedEmail?: InputMaybe<SortEnumType>;
  normalizedUserName?: InputMaybe<SortEnumType>;
  optedOutOfWeeklyEmails?: InputMaybe<SortEnumType>;
  passwordHash?: InputMaybe<SortEnumType>;
  phoneNumber?: InputMaybe<SortEnumType>;
  phoneNumberConfirmed?: InputMaybe<SortEnumType>;
  phoneNumberExtension?: InputMaybe<SortEnumType>;
  securityStamp?: InputMaybe<SortEnumType>;
  status?: InputMaybe<SortEnumType>;
  title?: InputMaybe<SortEnumType>;
  twoFactorEnabled?: InputMaybe<SortEnumType>;
  userName?: InputMaybe<SortEnumType>;
};

export enum ApplyPolicy {
  AfterResolver = 'AFTER_RESOLVER',
  BeforeResolver = 'BEFORE_RESOLVER',
  Validation = 'VALIDATION'
}

export type AskSignatureError = NotFoundError | ValidationResultError;

export type AskSignatureInput = {
  customMessage?: InputMaybe<Scalars['String']['input']>;
  documentId: Scalars['Int']['input'];
  dueDate?: InputMaybe<Scalars['DateTime']['input']>;
  projectId: Scalars['Int']['input'];
  signatoryCustomerId: Scalars['String']['input'];
  signatureCoordinates?: InputMaybe<ZohoSignatureCoordinatesInput>;
};

export type AskSignaturePayload = {
  __typename?: 'AskSignaturePayload';
  document?: Maybe<Document>;
  errors?: Maybe<Array<AskSignatureError>>;
};

export type Availabilities = {
  __typename?: 'Availabilities';
  availableStartTimes: Array<AvailabilityInfo>;
  isScheduleAvailable: Scalars['Boolean']['output'];
  isoWorkWeekDays: Array<Scalars['Int']['output']>;
  msalUIRequired: Scalars['Boolean']['output'];
};

export enum Availability {
  Busy = 'Busy',
  Free = 'Free',
  OutOfOffice = 'OutOfOffice',
  Tentative = 'Tentative',
  WorkingElsewhere = 'WorkingElsewhere'
}

export type AvailabilityInfo = {
  __typename?: 'AvailabilityInfo';
  availability: Availability;
  startTime: Scalars['DateTime']['output'];
};

export type BooleanOperationFilterInput = {
  eq?: InputMaybe<Scalars['Boolean']['input']>;
  neq?: InputMaybe<Scalars['Boolean']['input']>;
};

export enum BudgetAndExpensesPermission {
  Manage = 'Manage',
  NotAllowed = 'NotAllowed',
  View = 'View'
}

export type BudgetItem = {
  __typename?: 'BudgetItem';
  budgetedAmount: Scalars['Decimal']['output'];
  expenses: Array<Expense>;
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  project?: Maybe<Project>;
  projectId: Scalars['Int']['output'];
};

export type BudgetItemFilterInput = {
  and?: InputMaybe<Array<BudgetItemFilterInput>>;
  budgetedAmount?: InputMaybe<DecimalOperationFilterInput>;
  expenses?: InputMaybe<ListFilterInputTypeOfExpenseFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<BudgetItemFilterInput>>;
  project?: InputMaybe<ProjectFilterInput>;
  projectId?: InputMaybe<IntOperationFilterInput>;
};

export enum BuiltInRoles {
  BudgetAndExpensesAdmin = 'BudgetAndExpensesAdmin',
  BudgetAndExpensesReader = 'BudgetAndExpensesReader',
  Contributor = 'Contributor',
  /** @deprecated No longer supported. */
  Customer = 'Customer',
  /** @deprecated No longer supported. */
  ExpensesAdmin = 'ExpensesAdmin',
  /** @deprecated No longer supported. */
  ExpensesReader = 'ExpensesReader',
  FilesAdmin = 'FilesAdmin',
  FormsAdmin = 'FormsAdmin',
  LimitedAccess = 'LimitedAccess',
  MultiTenantAdministrator = 'MultiTenantAdministrator',
  ProjectAdministrator = 'ProjectAdministrator',
  Reader = 'Reader',
  TasksAdmin = 'TasksAdmin',
  TasksReader = 'TasksReader',
  TenantAdministrator = 'TenantAdministrator',
  WorkflowsAdmin = 'WorkflowsAdmin'
}

export type BuiltInRolesOperationFilterInput = {
  eq?: InputMaybe<BuiltInRoles>;
  in?: InputMaybe<Array<BuiltInRoles>>;
  neq?: InputMaybe<BuiltInRoles>;
  nin?: InputMaybe<Array<BuiltInRoles>>;
};

export type CollectFormResponsesError = ValidationResultError;

export type CollectFormResponsesInput = {
  customMessage?: InputMaybe<Scalars['String']['input']>;
  mandatoryRequestDueDate?: InputMaybe<Scalars['DateTime']['input']>;
  projectFormId: Scalars['Int']['input'];
  projectId: Scalars['Int']['input'];
  userIds: Array<Scalars['String']['input']>;
};

export type CollectFormResponsesPayload = {
  __typename?: 'CollectFormResponsesPayload';
  errors?: Maybe<Array<CollectFormResponsesError>>;
  projectForm?: Maybe<ProjectForm>;
};

export type CommercialProjectCalculator = {
  __typename?: 'CommercialProjectCalculator';
  id: Scalars['Int']['output'];
  jsonData: Scalars['String']['output'];
  name: Scalars['String']['output'];
  project?: Maybe<Project>;
  projectId: Scalars['Int']['output'];
};

export type CommercialProjectCalculatorFilterInput = {
  and?: InputMaybe<Array<CommercialProjectCalculatorFilterInput>>;
  id?: InputMaybe<IntOperationFilterInput>;
  jsonData?: InputMaybe<StringOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<CommercialProjectCalculatorFilterInput>>;
  project?: InputMaybe<ProjectFilterInput>;
  projectId?: InputMaybe<IntOperationFilterInput>;
};

export type ConditionEffect = {
  __typename?: 'ConditionEffect';
  condition?: Maybe<FormCondition>;
  conditionId: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  targetField?: Maybe<FormField>;
  targetFieldId?: Maybe<Scalars['Int']['output']>;
  targetSection?: Maybe<FormSection>;
  targetSectionId?: Maybe<Scalars['Int']['output']>;
  visibility?: Maybe<ConditionEffectVisibility>;
};

export type ConditionEffectFilterInput = {
  and?: InputMaybe<Array<ConditionEffectFilterInput>>;
  condition?: InputMaybe<FormConditionFilterInput>;
  conditionId?: InputMaybe<IntOperationFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<ConditionEffectFilterInput>>;
  targetField?: InputMaybe<FormFieldFilterInput>;
  targetFieldId?: InputMaybe<IntOperationFilterInput>;
  targetSection?: InputMaybe<FormSectionFilterInput>;
  targetSectionId?: InputMaybe<IntOperationFilterInput>;
  visibility?: InputMaybe<ConditionEffectVisibilityFilterInput>;
};

export type ConditionEffectVisibility = {
  __typename?: 'ConditionEffectVisibility';
  visible: Scalars['Boolean']['output'];
};

export type ConditionEffectVisibilityFilterInput = {
  and?: InputMaybe<Array<ConditionEffectVisibilityFilterInput>>;
  or?: InputMaybe<Array<ConditionEffectVisibilityFilterInput>>;
  visible?: InputMaybe<BooleanOperationFilterInput>;
};

export type ConditionEffectVisibilityInput = {
  visible: Scalars['Boolean']['input'];
};

export type ConditionTrigger = {
  __typename?: 'ConditionTrigger';
  condition?: Maybe<FormCondition>;
  conditionId: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  sourceField?: Maybe<FormField>;
  sourceFieldId: Scalars['Int']['output'];
  valueEquality?: Maybe<ConditionTriggerValueEquality>;
};

export type ConditionTriggerFilterInput = {
  and?: InputMaybe<Array<ConditionTriggerFilterInput>>;
  condition?: InputMaybe<FormConditionFilterInput>;
  conditionId?: InputMaybe<IntOperationFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<ConditionTriggerFilterInput>>;
  sourceField?: InputMaybe<FormFieldFilterInput>;
  sourceFieldId?: InputMaybe<IntOperationFilterInput>;
  valueEquality?: InputMaybe<ConditionTriggerValueEqualityFilterInput>;
};

export type ConditionTriggerValueEquality = {
  __typename?: 'ConditionTriggerValueEquality';
  boolean?: Maybe<Scalars['Boolean']['output']>;
};

export type ConditionTriggerValueEqualityFilterInput = {
  and?: InputMaybe<Array<ConditionTriggerValueEqualityFilterInput>>;
  boolean?: InputMaybe<BooleanOperationFilterInput>;
  or?: InputMaybe<Array<ConditionTriggerValueEqualityFilterInput>>;
};

export type ConditionTriggerValueEqualityInput = {
  boolean?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CreateCommercialProjectCalculatorError = ValidationResultError;

export type CreateCommercialProjectCalculatorInput = {
  jsonData: Scalars['String']['input'];
  projectId: Scalars['Int']['input'];
};

export type CreateCommercialProjectCalculatorPayload = {
  __typename?: 'CreateCommercialProjectCalculatorPayload';
  commercialProjectCalculator?: Maybe<CommercialProjectCalculator>;
  errors?: Maybe<Array<CreateCommercialProjectCalculatorError>>;
};

export type CreateFormFieldError = NotFoundError | ValidationResultError;

export type CreateFormFieldInput = {
  fieldType: FieldType;
  formDefinitionId: Scalars['Int']['input'];
  isMultiline: Scalars['Boolean']['input'];
  isMultiselect: Scalars['Boolean']['input'];
  isRequired: Scalars['Boolean']['input'];
  matrixInput?: InputMaybe<MatrixInput>;
  name: Scalars['String']['input'];
  options: Array<SelectionFieldOptionInput>;
  order: Scalars['Int']['input'];
  sectionId: Scalars['Int']['input'];
};

export type CreateFormFieldPayload = {
  __typename?: 'CreateFormFieldPayload';
  errors?: Maybe<Array<CreateFormFieldError>>;
  formField?: Maybe<FormField>;
};

export type CreateFormSectionError = NotFoundError | ValidationResultError;

export type CreateFormSectionInput = {
  formDefinitionId: Scalars['Int']['input'];
  name: Scalars['String']['input'];
  order: Scalars['Int']['input'];
};

export type CreateFormSectionPayload = {
  __typename?: 'CreateFormSectionPayload';
  errors?: Maybe<Array<CreateFormSectionError>>;
  formSection?: Maybe<FormSection>;
};

export type CreateFormTemplateError = ValidationResultError;

export type CreateFormTemplateInput = {
  input: FormDefinitionInput;
};

export type CreateFormTemplatePayload = {
  __typename?: 'CreateFormTemplatePayload';
  errors?: Maybe<Array<CreateFormTemplateError>>;
  formTemplate?: Maybe<FormTemplate>;
};

export type CreateProjectAccessTokenError = NotFoundError;

export type CreateProjectAccessTokenInput = {
  customMessage?: InputMaybe<Scalars['String']['input']>;
  documentId: Scalars['Int']['input'];
  forUserId: Scalars['String']['input'];
  projectId: Scalars['Int']['input'];
};

export type CreateProjectAccessTokenPayload = {
  __typename?: 'CreateProjectAccessTokenPayload';
  errors?: Maybe<Array<CreateProjectAccessTokenError>>;
  projectAccessToken?: Maybe<ProjectAccessToken>;
};

export type CreateTemplateFromProjectFormError = ValidationResultError;

export type CreateTemplateFromProjectFormInput = {
  projectFormId: Scalars['Int']['input'];
};

export type CreateTemplateFromProjectFormPayload = {
  __typename?: 'CreateTemplateFromProjectFormPayload';
  errors?: Maybe<Array<CreateTemplateFromProjectFormError>>;
  formTemplate?: Maybe<FormTemplate>;
};

export type DateTimeOperationFilterInput = {
  eq?: InputMaybe<Scalars['DateTime']['input']>;
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  neq?: InputMaybe<Scalars['DateTime']['input']>;
  ngt?: InputMaybe<Scalars['DateTime']['input']>;
  ngte?: InputMaybe<Scalars['DateTime']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  nlt?: InputMaybe<Scalars['DateTime']['input']>;
  nlte?: InputMaybe<Scalars['DateTime']['input']>;
};

export type DecimalOperationFilterInput = {
  eq?: InputMaybe<Scalars['Decimal']['input']>;
  gt?: InputMaybe<Scalars['Decimal']['input']>;
  gte?: InputMaybe<Scalars['Decimal']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Decimal']['input']>>>;
  lt?: InputMaybe<Scalars['Decimal']['input']>;
  lte?: InputMaybe<Scalars['Decimal']['input']>;
  neq?: InputMaybe<Scalars['Decimal']['input']>;
  ngt?: InputMaybe<Scalars['Decimal']['input']>;
  ngte?: InputMaybe<Scalars['Decimal']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['Decimal']['input']>>>;
  nlt?: InputMaybe<Scalars['Decimal']['input']>;
  nlte?: InputMaybe<Scalars['Decimal']['input']>;
};

export type DeclineMeetingRequestError = NotFoundError | ValidationResultError;

export type DeclineMeetingRequestInput = {
  declineReason: Scalars['String']['input'];
  meetingId: Scalars['Int']['input'];
  projectId: Scalars['Int']['input'];
};

export type DeclineMeetingRequestPayload = {
  __typename?: 'DeclineMeetingRequestPayload';
  errors?: Maybe<Array<DeclineMeetingRequestError>>;
  meeting?: Maybe<Meeting>;
};

export type DeleteBudgetItemError = NotFoundError | ValidationResultError;

export type DeleteBudgetItemInput = {
  id: Scalars['Int']['input'];
  projectId: Scalars['Int']['input'];
};

export type DeleteBudgetItemPayload = {
  __typename?: 'DeleteBudgetItemPayload';
  budgetItem?: Maybe<BudgetItem>;
  errors?: Maybe<Array<DeleteBudgetItemError>>;
};

export type DeleteConditionError = NotFoundError | ValidationResultError;

export type DeleteConditionInput = {
  formDefinitionId: Scalars['Int']['input'];
  id: Scalars['Int']['input'];
};

export type DeleteConditionPayload = {
  __typename?: 'DeleteConditionPayload';
  errors?: Maybe<Array<DeleteConditionError>>;
  formCondition?: Maybe<FormCondition>;
};

export type DeleteExpenseAttachmentError = NotFoundError;

export type DeleteExpenseAttachmentInput = {
  expenseId: Scalars['Int']['input'];
  projectId: Scalars['Int']['input'];
};

export type DeleteExpenseAttachmentPayload = {
  __typename?: 'DeleteExpenseAttachmentPayload';
  errors?: Maybe<Array<DeleteExpenseAttachmentError>>;
  expense?: Maybe<Expense>;
};

export type DeleteExpenseError = NotFoundError | ValidationResultError;

export type DeleteExpenseInput = {
  id: Scalars['Int']['input'];
  projectId: Scalars['Int']['input'];
};

export type DeleteExpensePayload = {
  __typename?: 'DeleteExpensePayload';
  errors?: Maybe<Array<DeleteExpenseError>>;
  expense?: Maybe<Expense>;
};

export type DeleteExternalLinkError = ValidationResultError;

export type DeleteExternalLinkInput = {
  linkId: Scalars['Int']['input'];
  projectId: Scalars['Int']['input'];
};

export type DeleteExternalLinkPayload = {
  __typename?: 'DeleteExternalLinkPayload';
  errors?: Maybe<Array<DeleteExternalLinkError>>;
  externalLink?: Maybe<ExternalLink>;
};

export type DeleteFolderError = ValidationResultError;

export type DeleteFolderInput = {
  folderId: Scalars['Int']['input'];
  projectId: Scalars['Int']['input'];
};

export type DeleteFolderPayload = {
  __typename?: 'DeleteFolderPayload';
  errors?: Maybe<Array<DeleteFolderError>>;
  folder?: Maybe<Folder>;
};

export type DeleteFormAttachmentError = NotFoundError | ValidationResultError;

export type DeleteFormAttachmentInput = {
  attachmentId: Scalars['Int']['input'];
  fieldId: Scalars['Int']['input'];
  submissionId: Scalars['Int']['input'];
};

export type DeleteFormAttachmentPatError = NotFoundError | ValidationResultError;

export type DeleteFormAttachmentPatInput = {
  deleteFormAttachmentInput: DeleteFormAttachmentInput;
  token: Scalars['String']['input'];
};

export type DeleteFormAttachmentPatPayload = {
  __typename?: 'DeleteFormAttachmentPATPayload';
  errors?: Maybe<Array<DeleteFormAttachmentPatError>>;
  formFileAttachment?: Maybe<FormFileAttachment>;
};

export type DeleteFormAttachmentPayload = {
  __typename?: 'DeleteFormAttachmentPayload';
  errors?: Maybe<Array<DeleteFormAttachmentError>>;
  formFileAttachment?: Maybe<FormFileAttachment>;
};

export type DeleteFormFieldError = NotFoundError | ValidationResultError;

export type DeleteFormFieldInput = {
  formDefinitionId: Scalars['Int']['input'];
  id: Scalars['Int']['input'];
};

export type DeleteFormFieldPayload = {
  __typename?: 'DeleteFormFieldPayload';
  errors?: Maybe<Array<DeleteFormFieldError>>;
  formField?: Maybe<FormField>;
};

export type DeleteFormSectionError = NotFoundError | ValidationResultError;

export type DeleteFormSectionInput = {
  formDefinitionId: Scalars['Int']['input'];
  id: Scalars['Int']['input'];
};

export type DeleteFormSectionPayload = {
  __typename?: 'DeleteFormSectionPayload';
  errors?: Maybe<Array<DeleteFormSectionError>>;
  formSection?: Maybe<FormSection>;
};

export type DeleteFormSubmissionError = NotFoundError | ValidationResultError;

export type DeleteFormSubmissionInput = {
  projectId: Scalars['Int']['input'];
  submissionId: Scalars['Int']['input'];
};

export type DeleteFormSubmissionPayload = {
  __typename?: 'DeleteFormSubmissionPayload';
  errors?: Maybe<Array<DeleteFormSubmissionError>>;
  projectFormSubmission?: Maybe<ProjectFormSubmission>;
};

export type DeleteFormTemplateError = ValidationResultError;

export type DeleteFormTemplateInput = {
  id: Scalars['Int']['input'];
};

export type DeleteFormTemplatePayload = {
  __typename?: 'DeleteFormTemplatePayload';
  errors?: Maybe<Array<DeleteFormTemplateError>>;
  formTemplate?: Maybe<FormTemplate>;
};

export type DeleteMeetingError = NotFoundError | ValidationResultError;

export type DeleteMeetingInput = {
  id: Scalars['Int']['input'];
  projectId: Scalars['Int']['input'];
};

export type DeleteMeetingPayload = {
  __typename?: 'DeleteMeetingPayload';
  errors?: Maybe<Array<DeleteMeetingError>>;
  meeting?: Maybe<Meeting>;
};

export type DeleteMeetingTypeError = NotFoundError | ValidationResultError;

export type DeleteMeetingTypeInput = {
  id: Scalars['Int']['input'];
  projectId: Scalars['Int']['input'];
};

export type DeleteMeetingTypePayload = {
  __typename?: 'DeleteMeetingTypePayload';
  errors?: Maybe<Array<DeleteMeetingTypeError>>;
  meetingType?: Maybe<MeetingType>;
};

export type DeleteProjectFormError = ValidationResultError;

export type DeleteProjectFormInput = {
  formId: Scalars['Int']['input'];
  projectId: Scalars['Int']['input'];
};

export type DeleteProjectFormPayload = {
  __typename?: 'DeleteProjectFormPayload';
  errors?: Maybe<Array<DeleteProjectFormError>>;
  formTemplate?: Maybe<FormTemplate>;
};

export type DeleteRequestError = NotFoundError | ValidationResultError;

export type DeleteRequestInput = {
  formId: Scalars['Int']['input'];
  projectId: Scalars['Int']['input'];
  taskId: Scalars['Int']['input'];
};

export type DeleteRequestPayload = {
  __typename?: 'DeleteRequestPayload';
  errors?: Maybe<Array<DeleteRequestError>>;
  projectTask?: Maybe<ProjectTask>;
};

export type DeleteSecurityGroupError = NotFoundError | ValidationResultError;

export type DeleteSecurityGroupInput = {
  groupId: Scalars['String']['input'];
  projectId: Scalars['Int']['input'];
};

export type DeleteSecurityGroupPayload = {
  __typename?: 'DeleteSecurityGroupPayload';
  errors?: Maybe<Array<DeleteSecurityGroupError>>;
  securityGroup?: Maybe<SecurityGroup>;
};

export type DeleteTaskError = NotFoundError | ValidationResultError;

export type DeleteTaskInput = {
  projectId: Scalars['Int']['input'];
  taskId: Scalars['Int']['input'];
};

export type DeleteTaskPayload = {
  __typename?: 'DeleteTaskPayload';
  errors?: Maybe<Array<DeleteTaskError>>;
  projectTask?: Maybe<ProjectTask>;
};

export type DeleteWorkflowActionConfigurationError = NotFoundError | ValidationResultError;

export type DeleteWorkflowActionConfigurationInput = {
  actionConfigurationId: Scalars['Int']['input'];
  workflowDefinitionId: Scalars['Int']['input'];
};

export type DeleteWorkflowActionConfigurationPayload = {
  __typename?: 'DeleteWorkflowActionConfigurationPayload';
  errors?: Maybe<Array<DeleteWorkflowActionConfigurationError>>;
  workflowActionConfiguration?: Maybe<WorkflowActionConfiguration>;
};

export type DeleteWorkflowDefinitionError = NotFoundError | ValidationResultError;

export type DeleteWorkflowDefinitionInput = {
  id: Scalars['Int']['input'];
};

export type DeleteWorkflowDefinitionPayload = {
  __typename?: 'DeleteWorkflowDefinitionPayload';
  errors?: Maybe<Array<DeleteWorkflowDefinitionError>>;
  workflowDefinition?: Maybe<WorkflowDefinition>;
};

export type DeleteWorkflowInstanceError = NotFoundError | ValidationResultError;

export type DeleteWorkflowInstanceInput = {
  id: Scalars['Int']['input'];
};

export type DeleteWorkflowInstancePayload = {
  __typename?: 'DeleteWorkflowInstancePayload';
  errors?: Maybe<Array<DeleteWorkflowInstanceError>>;
  workflowInstance?: Maybe<WorkflowInstance>;
};

export type DeleteWorkflowVariableError = NotFoundError | ValidationResultError;

export type DeleteWorkflowVariableInput = {
  workflowVariableId: Scalars['Int']['input'];
};

export type DeleteWorkflowVariablePayload = {
  __typename?: 'DeleteWorkflowVariablePayload';
  errors?: Maybe<Array<DeleteWorkflowVariableError>>;
  workflowVariable?: Maybe<WorkflowVariable>;
};

export type Document = {
  __typename?: 'Document';
  contentType?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<ApplicationUser>;
  createdById: Scalars['String']['output'];
  createdOn: Scalars['DateTime']['output'];
  fileName: Scalars['String']['output'];
  folderEntry?: Maybe<FolderEntry>;
  folderEntryId?: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
  imageThumbnailUrl?: Maybe<Scalars['String']['output']>;
  isImageContentType: Scalars['Boolean']['output'];
  isPublic: Scalars['Boolean']['output'];
  isSigned: Scalars['Boolean']['output'];
  modifiedBy?: Maybe<ApplicationUser>;
  modifiedById?: Maybe<Scalars['String']['output']>;
  modifiedOn?: Maybe<Scalars['DateTime']['output']>;
  project?: Maybe<Project>;
  projectId: Scalars['Int']['output'];
  publicSlug?: Maybe<Scalars['String']['output']>;
  requiresSignatures: Scalars['Boolean']['output'];
  signatoryCustomer?: Maybe<ApplicationUser>;
  signatoryCustomerId?: Maybe<Scalars['String']['output']>;
  signedOn?: Maybe<Scalars['DateTime']['output']>;
  textualSignature?: Maybe<Scalars['String']['output']>;
  thumbnailStatus: DocumentThumbnailStatus;
  zohoActionId?: Maybe<Scalars['String']['output']>;
  zohoRequestId?: Maybe<Scalars['String']['output']>;
};

export type DocumentFilterInput = {
  and?: InputMaybe<Array<DocumentFilterInput>>;
  contentType?: InputMaybe<StringOperationFilterInput>;
  createdById?: InputMaybe<StringOperationFilterInput>;
  createdOn?: InputMaybe<DateTimeOperationFilterInput>;
  fileName?: InputMaybe<StringOperationFilterInput>;
  folderEntry?: InputMaybe<FolderEntryFilterInput>;
  folderEntryId?: InputMaybe<IntOperationFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  imageThumbnailUrl?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<DocumentFilterInput>>;
  project?: InputMaybe<ProjectFilterInput>;
  projectId?: InputMaybe<IntOperationFilterInput>;
  requiresSignatures?: InputMaybe<BooleanOperationFilterInput>;
  signatoryCustomerId?: InputMaybe<StringOperationFilterInput>;
  signedOn?: InputMaybe<DateTimeOperationFilterInput>;
  thumbnailStatus?: InputMaybe<DocumentThumbnailStatusOperationFilterInput>;
};

export type DocumentFromAnonymousLink = {
  __typename?: 'DocumentFromAnonymousLink';
  createdBy?: Maybe<ApplicationUserFromAnonymousLink>;
  createdOn: Scalars['DateTime']['output'];
  fileName: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  isSigned: Scalars['Boolean']['output'];
  isUserAuthorizedToSign: Scalars['Boolean']['output'];
  requiresSignatures: Scalars['Boolean']['output'];
  signatoryCustomer?: Maybe<ApplicationUserFromAnonymousLink>;
  signatoryCustomerId?: Maybe<Scalars['String']['output']>;
  signature?: Maybe<Array<Scalars['Byte']['output']>>;
  signedOn?: Maybe<Scalars['DateTime']['output']>;
  zohoActionId?: Maybe<Scalars['String']['output']>;
  zohoRequestId?: Maybe<Scalars['String']['output']>;
};

export enum DocumentThumbnailStatus {
  Failed = 'Failed',
  Generating = 'Generating',
  NotAnImage = 'NotAnImage',
  NotStarted = 'NotStarted',
  Ready = 'Ready'
}

export type DocumentThumbnailStatusOperationFilterInput = {
  eq?: InputMaybe<DocumentThumbnailStatus>;
  in?: InputMaybe<Array<DocumentThumbnailStatus>>;
  neq?: InputMaybe<DocumentThumbnailStatus>;
  nin?: InputMaybe<Array<DocumentThumbnailStatus>>;
};

export enum DocumentsPermission {
  LimitedAccess = 'LimitedAccess',
  Manage = 'Manage'
}

export enum DomainEventType {
  DocumentSigned = 'DocumentSigned',
  FileDownloaded = 'FileDownloaded',
  FileUploaded = 'FileUploaded',
  FormFilled = 'FormFilled',
  MeetingAccepted = 'MeetingAccepted',
  MeetingDeclined = 'MeetingDeclined',
  MeetingRequested = 'MeetingRequested',
  WorkflowActionSubmitted = 'WorkflowActionSubmitted'
}

export type DuplicateCommercialProjectCalculatorError = NotFoundError | ValidationResultError;

export type DuplicateCommercialProjectCalculatorInput = {
  id: Scalars['Int']['input'];
  projectId: Scalars['Int']['input'];
};

export type DuplicateCommercialProjectCalculatorPayload = {
  __typename?: 'DuplicateCommercialProjectCalculatorPayload';
  commercialProjectCalculator?: Maybe<CommercialProjectCalculator>;
  errors?: Maybe<Array<DuplicateCommercialProjectCalculatorError>>;
};

export type DuplicateFormTemplateError = ValidationResultError;

export type DuplicateFormTemplateInput = {
  templateId: Scalars['Int']['input'];
};

export type DuplicateFormTemplatePayload = {
  __typename?: 'DuplicateFormTemplatePayload';
  errors?: Maybe<Array<DuplicateFormTemplateError>>;
  formTemplate?: Maybe<FormTemplate>;
};

export type DuplicateNameError = Error & {
  __typename?: 'DuplicateNameError';
  message: Scalars['String']['output'];
};

export type EditBudgetItemError = NotFoundError | ValidationResultError;

export type EditBudgetItemInput = {
  budgetedAmount: Scalars['Decimal']['input'];
  id: Scalars['Int']['input'];
  name: Scalars['String']['input'];
  projectId: Scalars['Int']['input'];
};

export type EditBudgetItemPayload = {
  __typename?: 'EditBudgetItemPayload';
  budgetItem?: Maybe<BudgetItem>;
  errors?: Maybe<Array<EditBudgetItemError>>;
};

export type EditConditionError = NotFoundError | ValidationResultError;

export type EditConditionInput = {
  conditionId: Scalars['Int']['input'];
  effectFieldId?: InputMaybe<Scalars['Int']['input']>;
  effectSectionId?: InputMaybe<Scalars['Int']['input']>;
  effectVisibility?: InputMaybe<ConditionEffectVisibilityInput>;
  formDefinitionId: Scalars['Int']['input'];
  triggerFieldId: Scalars['Int']['input'];
  triggerValueEquality?: InputMaybe<ConditionTriggerValueEqualityInput>;
};

export type EditConditionPayload = {
  __typename?: 'EditConditionPayload';
  errors?: Maybe<Array<EditConditionError>>;
  formCondition?: Maybe<FormCondition>;
};

export type EditExpenseError = NotFoundError | ValidationResultError;

export type EditExpenseInput = {
  budgetItemId?: InputMaybe<Scalars['Int']['input']>;
  expendedAmount: Scalars['Decimal']['input'];
  expenseDate: Scalars['DateTime']['input'];
  id: Scalars['Int']['input'];
  name: Scalars['String']['input'];
  projectId: Scalars['Int']['input'];
  status: ExpenseStatus;
};

export type EditExpensePayload = {
  __typename?: 'EditExpensePayload';
  errors?: Maybe<Array<EditExpenseError>>;
  expense?: Maybe<Expense>;
};

export type EditExternalUserError = NotFoundError | ValidationResultError;

export type EditExternalUserInput = {
  business?: InputMaybe<Scalars['String']['input']>;
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  projectId: Scalars['Int']['input'];
  title?: InputMaybe<Scalars['String']['input']>;
  userId: Scalars['String']['input'];
};

export type EditExternalUserPayload = {
  __typename?: 'EditExternalUserPayload';
  applicationUser?: Maybe<ApplicationUser>;
  errors?: Maybe<Array<EditExternalUserError>>;
};

export type EditFormDefinitionError = NotFoundError | ValidationResultError;

export type EditFormDefinitionInput = {
  formDefinition: FormDefinitionInput;
  formDefinitionId: Scalars['Int']['input'];
};

export type EditFormDefinitionPayload = {
  __typename?: 'EditFormDefinitionPayload';
  errors?: Maybe<Array<EditFormDefinitionError>>;
  formDefinition?: Maybe<FormDefinition>;
};

export type EditFormFieldError = NotFoundError | ValidationResultError;

export type EditFormFieldInput = {
  fieldId: Scalars['Int']['input'];
  formDefinitionId: Scalars['Int']['input'];
  isMultiline: Scalars['Boolean']['input'];
  isMultiselect: Scalars['Boolean']['input'];
  isRequired: Scalars['Boolean']['input'];
  matrixInput?: InputMaybe<MatrixInput>;
  name: Scalars['String']['input'];
  options: Array<SelectionFieldOptionInput>;
};

export type EditFormFieldPayload = {
  __typename?: 'EditFormFieldPayload';
  errors?: Maybe<Array<EditFormFieldError>>;
  formField?: Maybe<FormField>;
};

export type EditFormSectionError = NotFoundError | ValidationResultError;

export type EditFormSectionInput = {
  formDefinitionId: Scalars['Int']['input'];
  id: Scalars['Int']['input'];
  name: Scalars['String']['input'];
};

export type EditFormSectionPayload = {
  __typename?: 'EditFormSectionPayload';
  errors?: Maybe<Array<EditFormSectionError>>;
  formSection?: Maybe<FormSection>;
};

export type EditMeetingTypeError = NotFoundError | ValidationResultError;

export type EditMeetingTypeInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  durationMinutes: Scalars['Int']['input'];
  id: Scalars['Int']['input'];
  name: Scalars['String']['input'];
  projectId: Scalars['Int']['input'];
  userIds: Array<Scalars['String']['input']>;
};

export type EditMeetingTypePayload = {
  __typename?: 'EditMeetingTypePayload';
  errors?: Maybe<Array<EditMeetingTypeError>>;
  meetingType?: Maybe<MeetingType>;
};

export type EditMemberSecurityGroupError = NotFoundError | ValidationResultError;

export type EditMemberSecurityGroupInput = {
  projectId: Scalars['Int']['input'];
  securityGroupId: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};

export type EditMemberSecurityGroupPayload = {
  __typename?: 'EditMemberSecurityGroupPayload';
  applicationUser?: Maybe<ApplicationUser>;
  errors?: Maybe<Array<EditMemberSecurityGroupError>>;
};

export type EditProjectInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  hideBudgetFeature?: InputMaybe<Scalars['Boolean']['input']>;
  isArchived?: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
  projectId: Scalars['Int']['input'];
};

export type EditProjectPayload = {
  __typename?: 'EditProjectPayload';
  project?: Maybe<Project>;
};

export type EditProjectProgressInput = {
  progress: Scalars['Int']['input'];
  projectId: Scalars['Int']['input'];
};

export type EditProjectProgressPayload = {
  __typename?: 'EditProjectProgressPayload';
  project?: Maybe<Project>;
};

export type EditUserError = NotFoundError | ValidationResultError;

export type EditUserInput = {
  business?: InputMaybe<Scalars['String']['input']>;
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  userId: Scalars['String']['input'];
};

export type EditUserPayload = {
  __typename?: 'EditUserPayload';
  applicationUser?: Maybe<ApplicationUser>;
  errors?: Maybe<Array<EditUserError>>;
};

export type EditUserRoleError = NotFoundError | ValidationResultError;

export type EditUserRoleInput = {
  role: BuiltInRoles;
  userId: Scalars['String']['input'];
};

export type EditUserRolePayload = {
  __typename?: 'EditUserRolePayload';
  applicationUser?: Maybe<ApplicationUser>;
  errors?: Maybe<Array<EditUserRoleError>>;
};

export type EditWorkflowDefinitionError = NotFoundError | ValidationResultError;

export type EditWorkflowDefinitionInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['Int']['input'];
  name: Scalars['String']['input'];
};

export type EditWorkflowDefinitionPayload = {
  __typename?: 'EditWorkflowDefinitionPayload';
  errors?: Maybe<Array<EditWorkflowDefinitionError>>;
  workflowDefinition?: Maybe<WorkflowDefinition>;
};

export type EditWorkflowVariableError = NotFoundError | ValidationResultError;

export type EditWorkflowVariableInput = {
  name: Scalars['String']['input'];
  workflowVariableId: Scalars['Int']['input'];
};

export type EditWorkflowVariablePayload = {
  __typename?: 'EditWorkflowVariablePayload';
  errors?: Maybe<Array<EditWorkflowVariableError>>;
  workflowVariable?: Maybe<WorkflowVariable>;
};

export type Error = {
  message: Scalars['String']['output'];
};

export type Expense = {
  __typename?: 'Expense';
  attachments: Array<Document>;
  budgetItem?: Maybe<BudgetItem>;
  budgetItemId?: Maybe<Scalars['Int']['output']>;
  expendedAmount: Scalars['Decimal']['output'];
  expenseDate: Scalars['DateTime']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  project?: Maybe<Project>;
  projectId: Scalars['Int']['output'];
  status: ExpenseStatus;
};

export type ExpenseFilterInput = {
  and?: InputMaybe<Array<ExpenseFilterInput>>;
  attachments?: InputMaybe<ListFilterInputTypeOfDocumentFilterInput>;
  budgetItem?: InputMaybe<BudgetItemFilterInput>;
  budgetItemId?: InputMaybe<IntOperationFilterInput>;
  expendedAmount?: InputMaybe<DecimalOperationFilterInput>;
  expenseDate?: InputMaybe<DateTimeOperationFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<ExpenseFilterInput>>;
  project?: InputMaybe<ProjectFilterInput>;
  projectId?: InputMaybe<IntOperationFilterInput>;
  status?: InputMaybe<ExpenseStatusOperationFilterInput>;
};

export enum ExpenseStatus {
  NotPaid = 'NotPaid',
  Paid = 'Paid'
}

export type ExpenseStatusOperationFilterInput = {
  eq?: InputMaybe<ExpenseStatus>;
  in?: InputMaybe<Array<ExpenseStatus>>;
  neq?: InputMaybe<ExpenseStatus>;
  nin?: InputMaybe<Array<ExpenseStatus>>;
};

export type ExternalLink = {
  __typename?: 'ExternalLink';
  createdBy?: Maybe<ApplicationUser>;
  createdById?: Maybe<Scalars['String']['output']>;
  createdOn: Scalars['DateTime']['output'];
  folderEntry?: Maybe<FolderEntry>;
  folderEntryId?: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
  link: Scalars['String']['output'];
  modifiedBy?: Maybe<ApplicationUser>;
  modifiedById?: Maybe<Scalars['String']['output']>;
  modifiedOn?: Maybe<Scalars['DateTime']['output']>;
  name: Scalars['String']['output'];
  project?: Maybe<Project>;
  projectId: Scalars['Int']['output'];
  roleAssignments: Array<RoleAssignment>;
};

export type ExternalLinkFilterInput = {
  and?: InputMaybe<Array<ExternalLinkFilterInput>>;
  createdBy?: InputMaybe<ApplicationUserFilterInput>;
  createdById?: InputMaybe<StringOperationFilterInput>;
  createdOn?: InputMaybe<DateTimeOperationFilterInput>;
  folderEntry?: InputMaybe<FolderEntryFilterInput>;
  folderEntryId?: InputMaybe<IntOperationFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  link?: InputMaybe<StringOperationFilterInput>;
  modifiedBy?: InputMaybe<ApplicationUserFilterInput>;
  modifiedById?: InputMaybe<StringOperationFilterInput>;
  modifiedOn?: InputMaybe<DateTimeOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<ExternalLinkFilterInput>>;
  project?: InputMaybe<ProjectFilterInput>;
  projectId?: InputMaybe<IntOperationFilterInput>;
  roleAssignments?: InputMaybe<ListFilterInputTypeOfRoleAssignmentFilterInput>;
};

export type FeatureFlags = {
  __typename?: 'FeatureFlags';
  meetingsEnabled: Scalars['Boolean']['output'];
  workflowsEnabled: Scalars['Boolean']['output'];
};

export enum FieldType {
  Boolean = 'Boolean',
  Date = 'Date',
  File = 'File',
  Matrix = 'Matrix',
  Numerical = 'Numerical',
  Selection = 'Selection',
  Text = 'Text'
}

export type FieldTypeOperationFilterInput = {
  eq?: InputMaybe<FieldType>;
  in?: InputMaybe<Array<FieldType>>;
  neq?: InputMaybe<FieldType>;
  nin?: InputMaybe<Array<FieldType>>;
};

export enum FieldValueType {
  Value = 'Value',
  Variable = 'Variable'
}

export type FillFormAnonymouslyError = AccessTokenExpiredError | AccessTokenInvalidError | NotFoundError | ValidationResultError;

export type FillFormAnonymouslyInput = {
  token: Scalars['String']['input'];
};

export type FillFormAnonymouslyPayload = {
  __typename?: 'FillFormAnonymouslyPayload';
  errors?: Maybe<Array<FillFormAnonymouslyError>>;
  projectFormSubmission?: Maybe<ProjectFormSubmission>;
};

export type FillFormError = NotFoundError | ValidationResultError;

export type FillFormInput = {
  formSubmissionId: Scalars['Int']['input'];
};

export type FillFormPayload = {
  __typename?: 'FillFormPayload';
  errors?: Maybe<Array<FillFormError>>;
  projectFormSubmission?: Maybe<ProjectFormSubmission>;
};

export type FillFormValueError = NotFoundError | ValidationResultError;

export type FillFormValueInput = {
  formValue: FormValueInput;
};

export type FillFormValuePatError = NotFoundError | ValidationResultError;

export type FillFormValuePatInput = {
  accessToken: Scalars['String']['input'];
  formValue: FormValueInput;
};

export type FillFormValuePatPayload = {
  __typename?: 'FillFormValuePATPayload';
  errors?: Maybe<Array<FillFormValuePatError>>;
  formValue?: Maybe<FormValue>;
};

export type FillFormValuePayload = {
  __typename?: 'FillFormValuePayload';
  errors?: Maybe<Array<FillFormValueError>>;
  formValue?: Maybe<FormValue>;
};

export type FillMatrixValueError = NotFoundError | ValidationResultError;

export type FillMatrixValueInput = {
  columnId: Scalars['Int']['input'];
  matrixValueId?: InputMaybe<Scalars['Int']['input']>;
  rowId: Scalars['Int']['input'];
  submissionId: Scalars['Int']['input'];
  value: MatrixValueInput;
};

export type FillMatrixValuePatError = AccessTokenExpiredError | AccessTokenInvalidError | NotFoundError | ValidationResultError;

export type FillMatrixValuePatInput = {
  accessToken: Scalars['String']['input'];
  input: FillMatrixValueInput;
};

export type FillMatrixValuePatPayload = {
  __typename?: 'FillMatrixValuePATPayload';
  errors?: Maybe<Array<FillMatrixValuePatError>>;
  matrixValue?: Maybe<MatrixValue>;
};

export type FillMatrixValuePayload = {
  __typename?: 'FillMatrixValuePayload';
  errors?: Maybe<Array<FillMatrixValueError>>;
  matrixValue?: Maybe<MatrixValue>;
};

export type Folder = {
  __typename?: 'Folder';
  entries: Array<FolderEntry>;
  folderType: FolderType;
  id: Scalars['Int']['output'];
  modifiedBy?: Maybe<ApplicationUser>;
  modifiedById?: Maybe<Scalars['String']['output']>;
  modifiedOn?: Maybe<Scalars['DateTime']['output']>;
  name: Scalars['String']['output'];
  parentFolder?: Maybe<Folder>;
  parentFolderId?: Maybe<Scalars['Int']['output']>;
  path: Scalars['String']['output'];
  project?: Maybe<Project>;
  projectId: Scalars['Int']['output'];
  rootOfProjectId?: Maybe<Scalars['Int']['output']>;
};

export type FolderEntry = {
  __typename?: 'FolderEntry';
  document?: Maybe<Document>;
  documentId?: Maybe<Scalars['Int']['output']>;
  externalLink?: Maybe<ExternalLink>;
  externalLinkId?: Maybe<Scalars['Int']['output']>;
  folder?: Maybe<Folder>;
  folderId?: Maybe<Scalars['Int']['output']>;
  form?: Maybe<ProjectForm>;
  formId?: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
  project?: Maybe<Project>;
  projectId: Scalars['Int']['output'];
};

export type FolderEntryFilterInput = {
  and?: InputMaybe<Array<FolderEntryFilterInput>>;
  document?: InputMaybe<DocumentFilterInput>;
  documentId?: InputMaybe<IntOperationFilterInput>;
  externalLink?: InputMaybe<ExternalLinkFilterInput>;
  externalLinkId?: InputMaybe<IntOperationFilterInput>;
  folder?: InputMaybe<FolderFilterInput>;
  folderId?: InputMaybe<IntOperationFilterInput>;
  form?: InputMaybe<ProjectFormFilterInput>;
  formId?: InputMaybe<IntOperationFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<FolderEntryFilterInput>>;
  project?: InputMaybe<ProjectFilterInput>;
  projectId?: InputMaybe<IntOperationFilterInput>;
};

export type FolderFilterInput = {
  and?: InputMaybe<Array<FolderFilterInput>>;
  entries?: InputMaybe<ListFilterInputTypeOfFolderEntryFilterInput>;
  folderType?: InputMaybe<FolderTypeOperationFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<FolderFilterInput>>;
  parentFolder?: InputMaybe<FolderFilterInput>;
  parentFolderId?: InputMaybe<IntOperationFilterInput>;
  project?: InputMaybe<ProjectFilterInput>;
  projectId?: InputMaybe<IntOperationFilterInput>;
};

export type FolderSortInput = {
  folderType?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  modifiedBy?: InputMaybe<ApplicationUserSortInput>;
  modifiedById?: InputMaybe<SortEnumType>;
  modifiedOn?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  parentFolder?: InputMaybe<FolderSortInput>;
  parentFolderId?: InputMaybe<SortEnumType>;
  path?: InputMaybe<SortEnumType>;
  project?: InputMaybe<ProjectSortInput>;
  projectId?: InputMaybe<SortEnumType>;
  rootOfProject?: InputMaybe<ProjectSortInput>;
  rootOfProjectId?: InputMaybe<SortEnumType>;
};

export enum FolderType {
  ConfidentialDocument = 'ConfidentialDocument',
  Custom = 'Custom',
  Root = 'Root',
  SharedWithAll = 'SharedWithAll'
}

export type FolderTypeOperationFilterInput = {
  eq?: InputMaybe<FolderType>;
  in?: InputMaybe<Array<FolderType>>;
  neq?: InputMaybe<FolderType>;
  nin?: InputMaybe<Array<FolderType>>;
};

export type FormCondition = {
  __typename?: 'FormCondition';
  effect: ConditionEffect;
  form?: Maybe<FormDefinition>;
  formId: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  trigger: ConditionTrigger;
};

export type FormConditionFilterInput = {
  and?: InputMaybe<Array<FormConditionFilterInput>>;
  effect?: InputMaybe<ConditionEffectFilterInput>;
  form?: InputMaybe<FormDefinitionFilterInput>;
  formId?: InputMaybe<IntOperationFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<FormConditionFilterInput>>;
  trigger?: InputMaybe<ConditionTriggerFilterInput>;
};

export type FormDefinition = {
  __typename?: 'FormDefinition';
  conditions: Array<FormCondition>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  sections: Array<FormSection>;
};


export type FormDefinitionSectionsArgs = {
  order?: InputMaybe<Array<FormSectionSortInput>>;
};

export type FormDefinitionFilterInput = {
  and?: InputMaybe<Array<FormDefinitionFilterInput>>;
  conditions?: InputMaybe<ListFilterInputTypeOfFormConditionFilterInput>;
  description?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<FormDefinitionFilterInput>>;
  sections?: InputMaybe<ListFilterInputTypeOfFormSectionFilterInput>;
};

export type FormDefinitionInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
};

export type FormDefinitionSortInput = {
  description?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
};

export type FormField = {
  __typename?: 'FormField';
  affectedBy: Array<ConditionEffect>;
  fieldType: FieldType;
  id: Scalars['Int']['output'];
  isMultiline: Scalars['Boolean']['output'];
  isMultiselect: Scalars['Boolean']['output'];
  isRequired: Scalars['Boolean']['output'];
  matrix?: Maybe<Matrix>;
  matrixId?: Maybe<Scalars['Int']['output']>;
  name: Scalars['String']['output'];
  options: Array<SelectionFieldOption>;
  order: Scalars['Int']['output'];
  section?: Maybe<FormSection>;
  sectionId: Scalars['Int']['output'];
  triggers: Array<ConditionTrigger>;
};

export type FormFieldFilterInput = {
  affectedBy?: InputMaybe<ListFilterInputTypeOfConditionEffectFilterInput>;
  and?: InputMaybe<Array<FormFieldFilterInput>>;
  fieldType?: InputMaybe<FieldTypeOperationFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  isMultiline?: InputMaybe<BooleanOperationFilterInput>;
  isMultiselect?: InputMaybe<BooleanOperationFilterInput>;
  isRequired?: InputMaybe<BooleanOperationFilterInput>;
  matrix?: InputMaybe<MatrixFilterInput>;
  matrixId?: InputMaybe<IntOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  options?: InputMaybe<ListFilterInputTypeOfSelectionFieldOptionFilterInput>;
  or?: InputMaybe<Array<FormFieldFilterInput>>;
  order?: InputMaybe<IntOperationFilterInput>;
  section?: InputMaybe<FormSectionFilterInput>;
  sectionId?: InputMaybe<IntOperationFilterInput>;
  triggers?: InputMaybe<ListFilterInputTypeOfConditionTriggerFilterInput>;
};

export type FormFieldSortInput = {
  fieldType?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  isMultiline?: InputMaybe<SortEnumType>;
  isMultiselect?: InputMaybe<SortEnumType>;
  isRequired?: InputMaybe<SortEnumType>;
  matrix?: InputMaybe<MatrixSortInput>;
  matrixId?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  order?: InputMaybe<SortEnumType>;
  section?: InputMaybe<FormSectionSortInput>;
  sectionId?: InputMaybe<SortEnumType>;
};

export type FormFileAttachment = {
  __typename?: 'FormFileAttachment';
  fileName: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  thumbnailStatus?: Maybe<DocumentThumbnailStatus>;
};

export type FormFileAttachmentFilterInput = {
  and?: InputMaybe<Array<FormFileAttachmentFilterInput>>;
  contentType?: InputMaybe<StringOperationFilterInput>;
  fileName?: InputMaybe<StringOperationFilterInput>;
  formValue?: InputMaybe<FormValueFilterInput>;
  formValueId?: InputMaybe<IntOperationFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  imageThumbnailUrl?: InputMaybe<StringOperationFilterInput>;
  isImageContentType?: InputMaybe<BooleanOperationFilterInput>;
  or?: InputMaybe<Array<FormFileAttachmentFilterInput>>;
  storageUrl?: InputMaybe<StringOperationFilterInput>;
  thumbnailStatus?: InputMaybe<NullableOfDocumentThumbnailStatusOperationFilterInput>;
};

export type FormSection = {
  __typename?: 'FormSection';
  affectedBy: Array<ConditionEffect>;
  fields: Array<FormField>;
  form?: Maybe<FormDefinition>;
  formId: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  order: Scalars['Int']['output'];
};


export type FormSectionFieldsArgs = {
  order?: InputMaybe<Array<FormFieldSortInput>>;
};

export type FormSectionFilterInput = {
  affectedBy?: InputMaybe<ListFilterInputTypeOfConditionEffectFilterInput>;
  and?: InputMaybe<Array<FormSectionFilterInput>>;
  fields?: InputMaybe<ListFilterInputTypeOfFormFieldFilterInput>;
  form?: InputMaybe<FormDefinitionFilterInput>;
  formId?: InputMaybe<IntOperationFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<FormSectionFilterInput>>;
  order?: InputMaybe<IntOperationFilterInput>;
};

export type FormSectionSortInput = {
  form?: InputMaybe<FormDefinitionSortInput>;
  formId?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  order?: InputMaybe<SortEnumType>;
};

export type FormSettings = {
  __typename?: 'FormSettings';
  allowedRespondents: AllowedRespondents;
  allowedSecurityGroupsRespondents: Array<SecurityGroup>;
  allowedUsersRespondents: Array<ApplicationUser>;
  formType: FormType;
  id: Scalars['Int']['output'];
};

export type FormSettingsFilterInput = {
  allowedRespondents?: InputMaybe<AllowedRespondentsOperationFilterInput>;
  allowedSecurityGroupsRespondents?: InputMaybe<ListFilterInputTypeOfSecurityGroupFilterInput>;
  allowedUsersRespondents?: InputMaybe<ListFilterInputTypeOfApplicationUserFilterInput>;
  and?: InputMaybe<Array<FormSettingsFilterInput>>;
  formType?: InputMaybe<FormTypeOperationFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<FormSettingsFilterInput>>;
  projectForm?: InputMaybe<ProjectFormFilterInput>;
  projectFormId?: InputMaybe<IntOperationFilterInput>;
};

export enum FormStatus {
  Draft = 'Draft',
  Published = 'Published',
  /** @deprecated Form submissions now have a status indicating if it is responded. */
  Responded = 'Responded',
  Sent = 'Sent'
}

export type FormStatusOperationFilterInput = {
  eq?: InputMaybe<FormStatus>;
  in?: InputMaybe<Array<FormStatus>>;
  neq?: InputMaybe<FormStatus>;
  nin?: InputMaybe<Array<FormStatus>>;
};

export type FormSubmissionAnonymousLink = {
  __typename?: 'FormSubmissionAnonymousLink';
  belongsToUserId?: Maybe<Scalars['String']['output']>;
  formDefinition: FormDefinition;
  id: Scalars['Int']['output'];
  isUserAuthorizedToFill: Scalars['Boolean']['output'];
  status: SubmissionStatus;
  submittedOn?: Maybe<Scalars['DateTime']['output']>;
};

export type FormTemplate = {
  __typename?: 'FormTemplate';
  createdBy?: Maybe<ApplicationUser>;
  createdById: Scalars['String']['output'];
  createdOn: Scalars['DateTime']['output'];
  formDefinition?: Maybe<FormDefinition>;
  formDefinitionId: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  roleAssignments: Array<RoleAssignment>;
};

export type FormTemplateFilterInput = {
  and?: InputMaybe<Array<FormTemplateFilterInput>>;
  createdBy?: InputMaybe<ApplicationUserFilterInput>;
  createdById?: InputMaybe<StringOperationFilterInput>;
  createdOn?: InputMaybe<DateTimeOperationFilterInput>;
  formDefinition?: InputMaybe<FormDefinitionFilterInput>;
  formDefinitionId?: InputMaybe<IntOperationFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<FormTemplateFilterInput>>;
  roleAssignments?: InputMaybe<ListFilterInputTypeOfRoleAssignmentFilterInput>;
};

export enum FormType {
  CollectResponse = 'CollectResponse',
  SelfServe = 'SelfServe'
}

export type FormTypeOperationFilterInput = {
  eq?: InputMaybe<FormType>;
  in?: InputMaybe<Array<FormType>>;
  neq?: InputMaybe<FormType>;
  nin?: InputMaybe<Array<FormType>>;
};

export type FormValue = {
  __typename?: 'FormValue';
  boolean?: Maybe<Scalars['Boolean']['output']>;
  dateValue?: Maybe<Scalars['DateTime']['output']>;
  fieldId: Scalars['Int']['output'];
  files: Array<FormFileAttachment>;
  id: Scalars['Int']['output'];
  matrixRows: Array<MatrixRow>;
  number?: Maybe<Scalars['Decimal']['output']>;
  selectedOptions: Array<SelectionFieldOption>;
  text?: Maybe<Scalars['String']['output']>;
};

export type FormValueEditedEvent = {
  __typename?: 'FormValueEditedEvent';
  formValue: FormValue;
  initiatorUniqueClientId: Scalars['String']['output'];
};

export type FormValueFilterInput = {
  and?: InputMaybe<Array<FormValueFilterInput>>;
  boolean?: InputMaybe<BooleanOperationFilterInput>;
  dateValue?: InputMaybe<DateTimeOperationFilterInput>;
  field?: InputMaybe<FormFieldFilterInput>;
  fieldId?: InputMaybe<IntOperationFilterInput>;
  files?: InputMaybe<ListFilterInputTypeOfFormFileAttachmentFilterInput>;
  form?: InputMaybe<ProjectFormFilterInput>;
  formId?: InputMaybe<IntOperationFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  matrixRows?: InputMaybe<ListFilterInputTypeOfMatrixRowFilterInput>;
  number?: InputMaybe<DecimalOperationFilterInput>;
  or?: InputMaybe<Array<FormValueFilterInput>>;
  selectedOptions?: InputMaybe<ListFilterInputTypeOfSelectionFieldOptionFilterInput>;
  submission?: InputMaybe<ProjectFormSubmissionFilterInput>;
  submissionId?: InputMaybe<IntOperationFilterInput>;
  text?: InputMaybe<StringOperationFilterInput>;
};

export type FormValueInput = {
  boolean?: InputMaybe<Scalars['Boolean']['input']>;
  dateValue?: InputMaybe<Scalars['DateTime']['input']>;
  fieldId: Scalars['Int']['input'];
  number?: InputMaybe<Scalars['Decimal']['input']>;
  selectedOptionIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  submissionId: Scalars['Int']['input'];
  text?: InputMaybe<Scalars['String']['input']>;
};

export enum FormsPermission {
  LimitedAccess = 'LimitedAccess',
  Manage = 'Manage'
}

export type GetCalendarAvailabilitiesForMeetingTypeInput = {
  endTime: Scalars['DateTime']['input'];
  meetingTypeId: Scalars['Int']['input'];
  projectId: Scalars['Int']['input'];
  startTime: Scalars['DateTime']['input'];
  timeIntervalInMinutes: Scalars['Int']['input'];
  timeZone: Scalars['String']['input'];
};

export type GetCalendarAvailabilitiesInput = {
  endTime: Scalars['DateTime']['input'];
  memberId: Scalars['String']['input'];
  projectId: Scalars['Int']['input'];
  startTime: Scalars['DateTime']['input'];
  timeIntervalInMinutes: Scalars['Int']['input'];
  timeZone: Scalars['String']['input'];
};

export type HierarchyIdFilterInput = {
  and?: InputMaybe<Array<HierarchyIdFilterInput>>;
  or?: InputMaybe<Array<HierarchyIdFilterInput>>;
};

export type ImportFoldersError = NotFoundError | ValidationResultError;

export type ImportFoldersInput = {
  currentFolderId: Scalars['Int']['input'];
  folderIds: Array<Scalars['Int']['input']>;
  projectId: Scalars['Int']['input'];
  projectToImportFromId: Scalars['Int']['input'];
};

export type ImportFoldersPayload = {
  __typename?: 'ImportFoldersPayload';
  errors?: Maybe<Array<ImportFoldersError>>;
  project?: Maybe<Project>;
};

export type ImportTasksError = NotFoundError | ValidationResultError;

export type ImportTasksInput = {
  newStartDate?: InputMaybe<Scalars['DateTime']['input']>;
  projectId: Scalars['Int']['input'];
  projectToImportFromId: Scalars['Int']['input'];
  taskIds: Array<Scalars['Int']['input']>;
  userMapping: Array<KeyValuePairOfStringAndStringInput>;
};

export type ImportTasksPayload = {
  __typename?: 'ImportTasksPayload';
  errors?: Maybe<Array<ImportTasksError>>;
  project?: Maybe<Project>;
};

export type IntOperationFilterInput = {
  eq?: InputMaybe<Scalars['Int']['input']>;
  gt?: InputMaybe<Scalars['Int']['input']>;
  gte?: InputMaybe<Scalars['Int']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  lt?: InputMaybe<Scalars['Int']['input']>;
  lte?: InputMaybe<Scalars['Int']['input']>;
  neq?: InputMaybe<Scalars['Int']['input']>;
  ngt?: InputMaybe<Scalars['Int']['input']>;
  ngte?: InputMaybe<Scalars['Int']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  nlt?: InputMaybe<Scalars['Int']['input']>;
  nlte?: InputMaybe<Scalars['Int']['input']>;
};

export type InviteUserError = NotFoundError | ValidationResultError;

export type InviteUserInput = {
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  phoneNumberExtension?: InputMaybe<Scalars['Int']['input']>;
  role: BuiltInRoles;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type InviteUserPayload = {
  __typename?: 'InviteUserPayload';
  applicationUser?: Maybe<ApplicationUser>;
  errors?: Maybe<Array<InviteUserError>>;
};

export type KeyValuePairOfStringAndStringInput = {
  key: Scalars['String']['input'];
  value: Scalars['String']['input'];
};

export type ListBuiltInRolesOperationFilterInput = {
  all?: InputMaybe<BuiltInRolesOperationFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<BuiltInRolesOperationFilterInput>;
  some?: InputMaybe<BuiltInRolesOperationFilterInput>;
};

export type ListFilterInputTypeOfApplicationRoleFilterInput = {
  all?: InputMaybe<ApplicationRoleFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<ApplicationRoleFilterInput>;
  some?: InputMaybe<ApplicationRoleFilterInput>;
};

export type ListFilterInputTypeOfApplicationUserFilterInput = {
  all?: InputMaybe<ApplicationUserFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<ApplicationUserFilterInput>;
  some?: InputMaybe<ApplicationUserFilterInput>;
};

export type ListFilterInputTypeOfBudgetItemFilterInput = {
  all?: InputMaybe<BudgetItemFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<BudgetItemFilterInput>;
  some?: InputMaybe<BudgetItemFilterInput>;
};

export type ListFilterInputTypeOfConditionEffectFilterInput = {
  all?: InputMaybe<ConditionEffectFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<ConditionEffectFilterInput>;
  some?: InputMaybe<ConditionEffectFilterInput>;
};

export type ListFilterInputTypeOfConditionTriggerFilterInput = {
  all?: InputMaybe<ConditionTriggerFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<ConditionTriggerFilterInput>;
  some?: InputMaybe<ConditionTriggerFilterInput>;
};

export type ListFilterInputTypeOfDocumentFilterInput = {
  all?: InputMaybe<DocumentFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<DocumentFilterInput>;
  some?: InputMaybe<DocumentFilterInput>;
};

export type ListFilterInputTypeOfExpenseFilterInput = {
  all?: InputMaybe<ExpenseFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<ExpenseFilterInput>;
  some?: InputMaybe<ExpenseFilterInput>;
};

export type ListFilterInputTypeOfFolderEntryFilterInput = {
  all?: InputMaybe<FolderEntryFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<FolderEntryFilterInput>;
  some?: InputMaybe<FolderEntryFilterInput>;
};

export type ListFilterInputTypeOfFolderFilterInput = {
  all?: InputMaybe<FolderFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<FolderFilterInput>;
  some?: InputMaybe<FolderFilterInput>;
};

export type ListFilterInputTypeOfFormConditionFilterInput = {
  all?: InputMaybe<FormConditionFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<FormConditionFilterInput>;
  some?: InputMaybe<FormConditionFilterInput>;
};

export type ListFilterInputTypeOfFormFieldFilterInput = {
  all?: InputMaybe<FormFieldFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<FormFieldFilterInput>;
  some?: InputMaybe<FormFieldFilterInput>;
};

export type ListFilterInputTypeOfFormFileAttachmentFilterInput = {
  all?: InputMaybe<FormFileAttachmentFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<FormFileAttachmentFilterInput>;
  some?: InputMaybe<FormFileAttachmentFilterInput>;
};

export type ListFilterInputTypeOfFormSectionFilterInput = {
  all?: InputMaybe<FormSectionFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<FormSectionFilterInput>;
  some?: InputMaybe<FormSectionFilterInput>;
};

export type ListFilterInputTypeOfFormValueFilterInput = {
  all?: InputMaybe<FormValueFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<FormValueFilterInput>;
  some?: InputMaybe<FormValueFilterInput>;
};

export type ListFilterInputTypeOfMatrixColumnFilterInput = {
  all?: InputMaybe<MatrixColumnFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<MatrixColumnFilterInput>;
  some?: InputMaybe<MatrixColumnFilterInput>;
};

export type ListFilterInputTypeOfMatrixRowFilterInput = {
  all?: InputMaybe<MatrixRowFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<MatrixRowFilterInput>;
  some?: InputMaybe<MatrixRowFilterInput>;
};

export type ListFilterInputTypeOfMatrixValueFilterInput = {
  all?: InputMaybe<MatrixValueFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<MatrixValueFilterInput>;
  some?: InputMaybe<MatrixValueFilterInput>;
};

export type ListFilterInputTypeOfMeetingFilterInput = {
  all?: InputMaybe<MeetingFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<MeetingFilterInput>;
  some?: InputMaybe<MeetingFilterInput>;
};

export type ListFilterInputTypeOfMeetingProposalFilterInput = {
  all?: InputMaybe<MeetingProposalFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<MeetingProposalFilterInput>;
  some?: InputMaybe<MeetingProposalFilterInput>;
};

export type ListFilterInputTypeOfMeetingTypeFilterInput = {
  all?: InputMaybe<MeetingTypeFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<MeetingTypeFilterInput>;
  some?: InputMaybe<MeetingTypeFilterInput>;
};

export type ListFilterInputTypeOfProjectAccessTokenFilterInput = {
  all?: InputMaybe<ProjectAccessTokenFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<ProjectAccessTokenFilterInput>;
  some?: InputMaybe<ProjectAccessTokenFilterInput>;
};

export type ListFilterInputTypeOfProjectEventFilterInput = {
  all?: InputMaybe<ProjectEventFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<ProjectEventFilterInput>;
  some?: InputMaybe<ProjectEventFilterInput>;
};

export type ListFilterInputTypeOfProjectFormFilterInput = {
  all?: InputMaybe<ProjectFormFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<ProjectFormFilterInput>;
  some?: InputMaybe<ProjectFormFilterInput>;
};

export type ListFilterInputTypeOfProjectTaskFilterInput = {
  all?: InputMaybe<ProjectTaskFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<ProjectTaskFilterInput>;
  some?: InputMaybe<ProjectTaskFilterInput>;
};

export type ListFilterInputTypeOfRoleAssignmentFilterInput = {
  all?: InputMaybe<RoleAssignmentFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<RoleAssignmentFilterInput>;
  some?: InputMaybe<RoleAssignmentFilterInput>;
};

export type ListFilterInputTypeOfSecurityGroupFilterInput = {
  all?: InputMaybe<SecurityGroupFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<SecurityGroupFilterInput>;
  some?: InputMaybe<SecurityGroupFilterInput>;
};

export type ListFilterInputTypeOfSelectionFieldOptionFilterInput = {
  all?: InputMaybe<SelectionFieldOptionFilterInput>;
  any?: InputMaybe<Scalars['Boolean']['input']>;
  none?: InputMaybe<SelectionFieldOptionFilterInput>;
  some?: InputMaybe<SelectionFieldOptionFilterInput>;
};

export type LoginInfo = {
  __typename?: 'LoginInfo';
  isMicrosoftAccountLogin: Scalars['Boolean']['output'];
};

export type Matrix = {
  __typename?: 'Matrix';
  columns: Array<MatrixColumn>;
  defaultRows: Array<MatrixRow>;
  id: Scalars['Int']['output'];
};


export type MatrixColumnsArgs = {
  order?: InputMaybe<Array<MatrixColumnSortInput>>;
};

export type MatrixColumn = {
  __typename?: 'MatrixColumn';
  description?: Maybe<Scalars['String']['output']>;
  fieldType: MatrixColumnType;
  id: Scalars['Int']['output'];
  isRequired: Scalars['Boolean']['output'];
  matrix?: Maybe<Matrix>;
  matrixId: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  order: Scalars['Int']['output'];
  values: Array<MatrixValue>;
};

export type MatrixColumnFilterInput = {
  and?: InputMaybe<Array<MatrixColumnFilterInput>>;
  description?: InputMaybe<StringOperationFilterInput>;
  fieldType?: InputMaybe<MatrixColumnTypeOperationFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  isRequired?: InputMaybe<BooleanOperationFilterInput>;
  matrix?: InputMaybe<MatrixFilterInput>;
  matrixId?: InputMaybe<IntOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<MatrixColumnFilterInput>>;
  order?: InputMaybe<IntOperationFilterInput>;
  values?: InputMaybe<ListFilterInputTypeOfMatrixValueFilterInput>;
};

export type MatrixColumnInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  isRequired: Scalars['Boolean']['input'];
  name: Scalars['String']['input'];
  order: Scalars['Int']['input'];
  type: MatrixColumnType;
};

export type MatrixColumnSortInput = {
  description?: InputMaybe<SortEnumType>;
  fieldType?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  isRequired?: InputMaybe<SortEnumType>;
  matrix?: InputMaybe<MatrixSortInput>;
  matrixId?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  order?: InputMaybe<SortEnumType>;
};

export enum MatrixColumnType {
  Boolean = 'Boolean',
  Numerical = 'Numerical',
  Text = 'Text'
}

export type MatrixColumnTypeOperationFilterInput = {
  eq?: InputMaybe<MatrixColumnType>;
  in?: InputMaybe<Array<MatrixColumnType>>;
  neq?: InputMaybe<MatrixColumnType>;
  nin?: InputMaybe<Array<MatrixColumnType>>;
};

export type MatrixFilterInput = {
  and?: InputMaybe<Array<MatrixFilterInput>>;
  columns?: InputMaybe<ListFilterInputTypeOfMatrixColumnFilterInput>;
  defaultRows?: InputMaybe<ListFilterInputTypeOfMatrixRowFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<MatrixFilterInput>>;
};

export type MatrixInput = {
  columns: Array<MatrixColumnInput>;
  defaultRows: Array<MatrixRowInput>;
};

export type MatrixRow = {
  __typename?: 'MatrixRow';
  formValue?: Maybe<FormValue>;
  formValueId?: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
  isDefaultRow: Scalars['Boolean']['output'];
  matrix?: Maybe<Matrix>;
  matrixId?: Maybe<Scalars['Int']['output']>;
  values: Array<MatrixValue>;
};

export type MatrixRowEvent = {
  __typename?: 'MatrixRowEvent';
  eventType: MatrixRowEventType;
  id: Scalars['Int']['output'];
  initiatorUniqueClientId: Scalars['String']['output'];
};

export enum MatrixRowEventType {
  Added = 'Added',
  Removed = 'Removed'
}

export type MatrixRowFilterInput = {
  and?: InputMaybe<Array<MatrixRowFilterInput>>;
  formValue?: InputMaybe<FormValueFilterInput>;
  formValueId?: InputMaybe<IntOperationFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  isDefaultRow?: InputMaybe<BooleanOperationFilterInput>;
  matrix?: InputMaybe<MatrixFilterInput>;
  matrixId?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<MatrixRowFilterInput>>;
  values?: InputMaybe<ListFilterInputTypeOfMatrixValueFilterInput>;
};

export type MatrixRowInput = {
  values: Array<MatrixValueInput>;
};

export type MatrixSortInput = {
  id?: InputMaybe<SortEnumType>;
};

export type MatrixValue = {
  __typename?: 'MatrixValue';
  boolean?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['Int']['output'];
  matrixColumn?: Maybe<MatrixColumn>;
  matrixColumnId: Scalars['Int']['output'];
  matrixRow?: Maybe<MatrixRow>;
  matrixRowId: Scalars['Int']['output'];
  number?: Maybe<Scalars['Decimal']['output']>;
  text?: Maybe<Scalars['String']['output']>;
};

export type MatrixValueEditedEvent = {
  __typename?: 'MatrixValueEditedEvent';
  initiatorUniqueClientId: Scalars['String']['output'];
  matrixValue: MatrixValue;
};

export type MatrixValueFilterInput = {
  and?: InputMaybe<Array<MatrixValueFilterInput>>;
  boolean?: InputMaybe<BooleanOperationFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  matrixColumn?: InputMaybe<MatrixColumnFilterInput>;
  matrixColumnId?: InputMaybe<IntOperationFilterInput>;
  matrixRow?: InputMaybe<MatrixRowFilterInput>;
  matrixRowId?: InputMaybe<IntOperationFilterInput>;
  number?: InputMaybe<DecimalOperationFilterInput>;
  or?: InputMaybe<Array<MatrixValueFilterInput>>;
  text?: InputMaybe<StringOperationFilterInput>;
};

export type MatrixValueInput = {
  boolean?: InputMaybe<Scalars['Boolean']['input']>;
  number?: InputMaybe<Scalars['Decimal']['input']>;
  text?: InputMaybe<Scalars['String']['input']>;
};

export type Meeting = {
  __typename?: 'Meeting';
  cancellationReason?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<ApplicationUser>;
  createdById: Scalars['String']['output'];
  description?: Maybe<Scalars['String']['output']>;
  endTime?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['Int']['output'];
  meetingStatus: MeetingStatus;
  meetingType?: Maybe<MeetingType>;
  meetingTypeId: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  participants: Array<ApplicationUser>;
  proposals: Array<MeetingProposal>;
  startTime?: Maybe<Scalars['DateTime']['output']>;
};


export type MeetingProposalsArgs = {
  order?: InputMaybe<Array<MeetingProposalSortInput>>;
};

export type MeetingFilterInput = {
  and?: InputMaybe<Array<MeetingFilterInput>>;
  cancellationReason?: InputMaybe<StringOperationFilterInput>;
  createdBy?: InputMaybe<ApplicationUserFilterInput>;
  createdById?: InputMaybe<StringOperationFilterInput>;
  description?: InputMaybe<StringOperationFilterInput>;
  endTime?: InputMaybe<DateTimeOperationFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  meetingStatus?: InputMaybe<MeetingStatusOperationFilterInput>;
  meetingType?: InputMaybe<MeetingTypeFilterInput>;
  meetingTypeId?: InputMaybe<IntOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<MeetingFilterInput>>;
  participants?: InputMaybe<ListFilterInputTypeOfApplicationUserFilterInput>;
  project?: InputMaybe<ProjectFilterInput>;
  projectId?: InputMaybe<IntOperationFilterInput>;
  proposals?: InputMaybe<ListFilterInputTypeOfMeetingProposalFilterInput>;
  startTime?: InputMaybe<DateTimeOperationFilterInput>;
};

export type MeetingProposal = {
  __typename?: 'MeetingProposal';
  acceptedUsers: Array<ApplicationUser>;
  createdBy?: Maybe<ApplicationUser>;
  createdById: Scalars['String']['output'];
  createdOn: Scalars['DateTime']['output'];
  declinedUsers: Array<ApplicationUser>;
  id: Scalars['Int']['output'];
  meeting?: Maybe<Meeting>;
  meetingId: Scalars['Int']['output'];
  startTime: Scalars['DateTime']['output'];
};

export type MeetingProposalFilterInput = {
  acceptedUsers?: InputMaybe<ListFilterInputTypeOfApplicationUserFilterInput>;
  and?: InputMaybe<Array<MeetingProposalFilterInput>>;
  createdBy?: InputMaybe<ApplicationUserFilterInput>;
  createdById?: InputMaybe<StringOperationFilterInput>;
  createdOn?: InputMaybe<DateTimeOperationFilterInput>;
  declinedUsers?: InputMaybe<ListFilterInputTypeOfApplicationUserFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  meeting?: InputMaybe<MeetingFilterInput>;
  meetingId?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<MeetingProposalFilterInput>>;
  startTime?: InputMaybe<DateTimeOperationFilterInput>;
};

export type MeetingProposalSortInput = {
  createdBy?: InputMaybe<ApplicationUserSortInput>;
  createdById?: InputMaybe<SortEnumType>;
  createdOn?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  meeting?: InputMaybe<MeetingSortInput>;
  meetingId?: InputMaybe<SortEnumType>;
  startTime?: InputMaybe<SortEnumType>;
};

export type MeetingSortInput = {
  cancellationReason?: InputMaybe<SortEnumType>;
  createdBy?: InputMaybe<ApplicationUserSortInput>;
  createdById?: InputMaybe<SortEnumType>;
  description?: InputMaybe<SortEnumType>;
  endTime?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  meetingStatus?: InputMaybe<SortEnumType>;
  meetingType?: InputMaybe<MeetingTypeSortInput>;
  meetingTypeId?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  project?: InputMaybe<ProjectSortInput>;
  projectId?: InputMaybe<SortEnumType>;
  startTime?: InputMaybe<SortEnumType>;
};

export enum MeetingStatus {
  Cancelled = 'Cancelled',
  Planned = 'Planned',
  Requested = 'Requested'
}

export type MeetingStatusOperationFilterInput = {
  eq?: InputMaybe<MeetingStatus>;
  in?: InputMaybe<Array<MeetingStatus>>;
  neq?: InputMaybe<MeetingStatus>;
  nin?: InputMaybe<Array<MeetingStatus>>;
};

export type MeetingType = {
  __typename?: 'MeetingType';
  createdBy?: Maybe<ApplicationUser>;
  createdById?: Maybe<Scalars['String']['output']>;
  createdOn: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  duration: Scalars['TimeSpan']['output'];
  id: Scalars['Int']['output'];
  isDeleted: Scalars['Boolean']['output'];
  meetingRequestsOwners: Array<ApplicationUser>;
  meetings: Array<Meeting>;
  modifiedBy?: Maybe<ApplicationUser>;
  modifiedById?: Maybe<Scalars['String']['output']>;
  modifiedOn?: Maybe<Scalars['DateTime']['output']>;
  name: Scalars['String']['output'];
  project?: Maybe<Project>;
  projectId: Scalars['Int']['output'];
};

export type MeetingTypeFilterInput = {
  and?: InputMaybe<Array<MeetingTypeFilterInput>>;
  createdBy?: InputMaybe<ApplicationUserFilterInput>;
  createdById?: InputMaybe<StringOperationFilterInput>;
  createdOn?: InputMaybe<DateTimeOperationFilterInput>;
  description?: InputMaybe<StringOperationFilterInput>;
  duration?: InputMaybe<TimeSpanOperationFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  meetingRequestsOwners?: InputMaybe<ListFilterInputTypeOfApplicationUserFilterInput>;
  meetings?: InputMaybe<ListFilterInputTypeOfMeetingFilterInput>;
  modifiedBy?: InputMaybe<ApplicationUserFilterInput>;
  modifiedById?: InputMaybe<StringOperationFilterInput>;
  modifiedOn?: InputMaybe<DateTimeOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<MeetingTypeFilterInput>>;
  project?: InputMaybe<ProjectFilterInput>;
  projectId?: InputMaybe<IntOperationFilterInput>;
};

export type MeetingTypeSortInput = {
  createdBy?: InputMaybe<ApplicationUserSortInput>;
  createdById?: InputMaybe<SortEnumType>;
  createdOn?: InputMaybe<SortEnumType>;
  description?: InputMaybe<SortEnumType>;
  duration?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  isDeleted?: InputMaybe<SortEnumType>;
  modifiedBy?: InputMaybe<ApplicationUserSortInput>;
  modifiedById?: InputMaybe<SortEnumType>;
  modifiedOn?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  project?: InputMaybe<ProjectSortInput>;
  projectId?: InputMaybe<SortEnumType>;
};

export type MissingConfigurationError = Error & {
  __typename?: 'MissingConfigurationError';
  message: Scalars['String']['output'];
};

export type MoveChildActionError = NotFoundError | ValidationResultError;

export type MoveChildActionInput = {
  movedActionConfigurationId: Scalars['Int']['input'];
  orderIndex: Scalars['Int']['input'];
  parentActionConfigurationId: Scalars['Int']['input'];
  workflowDefinitionId: Scalars['Int']['input'];
};

export type MoveChildActionPayload = {
  __typename?: 'MoveChildActionPayload';
  errors?: Maybe<Array<MoveChildActionError>>;
  workflowActionConfiguration?: Maybe<WorkflowActionConfiguration>;
};

export type MoveFolderEntryError = ValidationResultError;

export type MoveFolderEntryInput = {
  destinationFolderId: Scalars['Int']['input'];
  entryId: Scalars['Int']['input'];
  projectId: Scalars['Int']['input'];
};

export type MoveFolderEntryPayload = {
  __typename?: 'MoveFolderEntryPayload';
  errors?: Maybe<Array<MoveFolderEntryError>>;
  folderEntry?: Maybe<FolderEntry>;
};

export type MoveFolderError = ValidationResultError;

export type MoveFolderInput = {
  destinationFolderId: Scalars['Int']['input'];
  folderId: Scalars['Int']['input'];
  projectId: Scalars['Int']['input'];
};

export type MoveFolderPayload = {
  __typename?: 'MoveFolderPayload';
  errors?: Maybe<Array<MoveFolderError>>;
  folder?: Maybe<Folder>;
};

export type MoveFormFieldError = NotFoundError | ValidationResultError;

export type MoveFormFieldInput = {
  destinationSectionId?: InputMaybe<Scalars['Int']['input']>;
  fieldId: Scalars['Int']['input'];
  formDefinitionId: Scalars['Int']['input'];
  newIndex: Scalars['Int']['input'];
};

export type MoveFormFieldPayload = {
  __typename?: 'MoveFormFieldPayload';
  errors?: Maybe<Array<MoveFormFieldError>>;
  formField?: Maybe<FormField>;
};

export type Mutation = {
  __typename?: 'Mutation';
  acceptMeetingRequest: AcceptMeetingRequestPayload;
  addBudgetItem: AddBudgetItemPayload;
  addChildAction: AddChildActionPayload;
  addCondition: AddConditionPayload;
  addExpense: AddExpensePayload;
  addExternalLink: AddExternalLinkPayload;
  addExternalUser: AddExternalUserPayload;
  addFolder: AddFolderPayload;
  addMatrixRow: AddMatrixRowPayload;
  addMatrixRowPAT: AddMatrixRowPatPayload;
  addMeeting: AddMeetingPayload;
  addMeetingType: AddMeetingTypePayload;
  addProject: AddProjectPayload;
  addProjectForm: AddProjectFormPayload;
  addProjectFormFromTemplate: AddProjectFormFromTemplatePayload;
  addProjectMember: AddProjectMemberPayload;
  addSecurityGroup: AddSecurityGroupPayload;
  addTask: AddTaskPayload;
  addTaskPredecessor: AddTaskPredecessorPayload;
  addTenant: AddTenantPayload;
  addWorkflowDefinition: AddWorkflowDefinitionPayload;
  addWorkflowVariable: AddWorkflowVariablePayload;
  askSignature: AskSignaturePayload;
  collectFormResponses: CollectFormResponsesPayload;
  createCommercialProjectCalculator: CreateCommercialProjectCalculatorPayload;
  createFormField: CreateFormFieldPayload;
  createFormSection: CreateFormSectionPayload;
  createFormTemplate: CreateFormTemplatePayload;
  createProjectAccessToken: CreateProjectAccessTokenPayload;
  createTemplateFromProjectForm: CreateTemplateFromProjectFormPayload;
  declineMeetingRequest: DeclineMeetingRequestPayload;
  deleteBudgetItem: DeleteBudgetItemPayload;
  deleteCondition: DeleteConditionPayload;
  deleteExpense: DeleteExpensePayload;
  deleteExpenseAttachment: DeleteExpenseAttachmentPayload;
  deleteExternalLink: DeleteExternalLinkPayload;
  deleteFolder: DeleteFolderPayload;
  deleteFormAttachment: DeleteFormAttachmentPayload;
  deleteFormAttachmentPAT: DeleteFormAttachmentPatPayload;
  deleteFormField: DeleteFormFieldPayload;
  deleteFormSection: DeleteFormSectionPayload;
  deleteFormSubmission: DeleteFormSubmissionPayload;
  deleteFormTemplate: DeleteFormTemplatePayload;
  deleteMeeting: DeleteMeetingPayload;
  deleteMeetingType: DeleteMeetingTypePayload;
  deleteProjectForm: DeleteProjectFormPayload;
  deleteRequest: DeleteRequestPayload;
  deleteSecurityGroup: DeleteSecurityGroupPayload;
  deleteTask: DeleteTaskPayload;
  deleteWorkflowActionConfiguration: DeleteWorkflowActionConfigurationPayload;
  deleteWorkflowDefinition: DeleteWorkflowDefinitionPayload;
  deleteWorkflowInstance: DeleteWorkflowInstancePayload;
  deleteWorkflowVariable: DeleteWorkflowVariablePayload;
  duplicateCommercialProjectCalculator: DuplicateCommercialProjectCalculatorPayload;
  duplicateFormTemplate: DuplicateFormTemplatePayload;
  editBudgetItem: EditBudgetItemPayload;
  editCondition: EditConditionPayload;
  editExpense: EditExpensePayload;
  editExternalUser: EditExternalUserPayload;
  editFormDefinition: EditFormDefinitionPayload;
  editFormField: EditFormFieldPayload;
  editFormSection: EditFormSectionPayload;
  editMeetingType: EditMeetingTypePayload;
  editMemberSecurityGroup: EditMemberSecurityGroupPayload;
  editProject: EditProjectPayload;
  editProjectProgress: EditProjectProgressPayload;
  editUser: EditUserPayload;
  editUserRole: EditUserRolePayload;
  editWorkflowDefinition: EditWorkflowDefinitionPayload;
  editWorkflowVariable: EditWorkflowVariablePayload;
  fillForm: FillFormPayload;
  fillFormAnonymously: FillFormAnonymouslyPayload;
  fillFormValue: FillFormValuePayload;
  fillFormValuePAT: FillFormValuePatPayload;
  fillMatrixValue: FillMatrixValuePayload;
  fillMatrixValuePAT: FillMatrixValuePatPayload;
  importFolders: ImportFoldersPayload;
  importTasks: ImportTasksPayload;
  inviteUser: InviteUserPayload;
  moveChildAction: MoveChildActionPayload;
  moveFolder: MoveFolderPayload;
  moveFolderEntry: MoveFolderEntryPayload;
  moveFormField: MoveFormFieldPayload;
  publishDocument: PublishDocumentPayload;
  publishForm: PublishFormPayload;
  reSendMyWeeklyEmail: ReSendMyWeeklyEmailPayload;
  removeCommercialProjectCalculator: RemoveCommercialProjectCalculatorPayload;
  removeDocument: RemoveDocumentPayload;
  removeFormTemplateRoleAssignment: RemoveFormTemplateRoleAssignmentPayload;
  removeMatrixRow: RemoveMatrixRowPayload;
  removeMatrixRowPAT: RemoveMatrixRowPatPayload;
  /** @deprecated No longer supported. */
  removePresence: RemovePresencePayload;
  removePresencePAT: RemovePresencePatPayload;
  removeProjectMember: RemoveProjectMemberPayload;
  removeSecurityGroupMember: RemoveSecurityGroupMemberPayload;
  removeSignature: RemoveSignaturePayload;
  removeUser: RemoveUserPayload;
  renameDocument: RenameDocumentPayload;
  renameFolder: RenameFolderPayload;
  renewAccessTokenFromExpiredToken: RenewAccessTokenFromExpiredTokenPayload;
  reorderFormSections: ReorderFormSectionsPayload;
  reparentTask: ReparentTaskPayload;
  requestFormSubmission: RequestFormSubmissionPayload;
  requestFormSubmissionAnonymous: RequestFormSubmissionAnonymousPayload;
  rescheduleTask: RescheduleTaskPayload;
  resendFormInvitation: ResendFormInvitationPayload;
  reshareOrganizationResource: ReshareOrganizationResourcePayload;
  reshareProjectResource: ReshareProjectResourcePayload;
  sendSharingTemplateNotification: SendSharingTemplateNotificationPayload;
  shareOrganizationResource: ShareOrganizationResourcePayload;
  shareProjectResource: ShareProjectResourcePayload;
  signDocument: SignDocumentPayload;
  signDocumentAnonymously: SignDocumentAnonymouslyPayload;
  startTrial: StartTrialPayload;
  startWorkflowInstance: StartWorkflowInstancePayload;
  submitWorkflowAction: SubmitWorkflowActionPayload;
  unpublishDocument: UnpublishDocumentPayload;
  unpublishForm: UnpublishFormPayload;
  unshareOrganizationResource: UnshareOrganizationResourcePayload;
  unshareProjectResource: UnshareProjectResourcePayload;
  unsubscribeFromEmails: UnsubscribeFromEmailsPayload;
  updateBrandingConfiguration: UpdateBrandingConfigurationPayload;
  updateCommercialProjectCalculator: UpdateCommercialProjectCalculatorPayload;
  updateFormSettings: UpdateFormSettingsPayload;
  updateMyPreferences: UpdateMyPreferencesPayload;
  /** @deprecated No longer supported. */
  updatePresence: UpdatePresencePayload;
  updatePresencePAT: UpdatePresencePatPayload;
  updateSecurityGroup: UpdateSecurityGroupPayload;
  updateSecurityGroupRoles: UpdateSecurityGroupRolesPayload;
  updateTask: UpdateTaskPayload;
  updateTaskStatus: UpdateTaskStatusPayload;
  updateWorkflowActionConfiguration: UpdateWorkflowActionConfigurationPayload;
};


export type MutationAcceptMeetingRequestArgs = {
  input: AcceptMeetingRequestInput;
};


export type MutationAddBudgetItemArgs = {
  input: AddBudgetItemInput;
};


export type MutationAddChildActionArgs = {
  input: AddChildActionInput;
};


export type MutationAddConditionArgs = {
  input: AddConditionInput;
};


export type MutationAddExpenseArgs = {
  input: AddExpenseInput;
};


export type MutationAddExternalLinkArgs = {
  input: AddExternalLinkInput;
};


export type MutationAddExternalUserArgs = {
  input: AddExternalUserInput;
};


export type MutationAddFolderArgs = {
  input: AddFolderInput;
};


export type MutationAddMatrixRowArgs = {
  input: AddMatrixRowInput;
};


export type MutationAddMatrixRowPatArgs = {
  input: AddMatrixRowPatInput;
};


export type MutationAddMeetingArgs = {
  input: AddMeetingInput;
};


export type MutationAddMeetingTypeArgs = {
  input: AddMeetingTypeInput;
};


export type MutationAddProjectArgs = {
  input: AddProjectInput;
};


export type MutationAddProjectFormArgs = {
  input: AddProjectFormInput;
};


export type MutationAddProjectFormFromTemplateArgs = {
  input: AddProjectFormFromTemplateInput;
};


export type MutationAddProjectMemberArgs = {
  input: AddProjectMemberInput;
};


export type MutationAddSecurityGroupArgs = {
  input: AddSecurityGroupInput;
};


export type MutationAddTaskArgs = {
  input: AddTaskInput;
};


export type MutationAddTaskPredecessorArgs = {
  input: AddTaskPredecessorInput;
};


export type MutationAddTenantArgs = {
  input: AddTenantInput;
};


export type MutationAddWorkflowDefinitionArgs = {
  input: AddWorkflowDefinitionInput;
};


export type MutationAddWorkflowVariableArgs = {
  input: AddWorkflowVariableInput;
};


export type MutationAskSignatureArgs = {
  input: AskSignatureInput;
};


export type MutationCollectFormResponsesArgs = {
  input: CollectFormResponsesInput;
};


export type MutationCreateCommercialProjectCalculatorArgs = {
  input: CreateCommercialProjectCalculatorInput;
};


export type MutationCreateFormFieldArgs = {
  input: CreateFormFieldInput;
};


export type MutationCreateFormSectionArgs = {
  input: CreateFormSectionInput;
};


export type MutationCreateFormTemplateArgs = {
  input: CreateFormTemplateInput;
};


export type MutationCreateProjectAccessTokenArgs = {
  input: CreateProjectAccessTokenInput;
};


export type MutationCreateTemplateFromProjectFormArgs = {
  input: CreateTemplateFromProjectFormInput;
};


export type MutationDeclineMeetingRequestArgs = {
  input: DeclineMeetingRequestInput;
};


export type MutationDeleteBudgetItemArgs = {
  input: DeleteBudgetItemInput;
};


export type MutationDeleteConditionArgs = {
  input: DeleteConditionInput;
};


export type MutationDeleteExpenseArgs = {
  input: DeleteExpenseInput;
};


export type MutationDeleteExpenseAttachmentArgs = {
  input: DeleteExpenseAttachmentInput;
};


export type MutationDeleteExternalLinkArgs = {
  input: DeleteExternalLinkInput;
};


export type MutationDeleteFolderArgs = {
  input: DeleteFolderInput;
};


export type MutationDeleteFormAttachmentArgs = {
  input: DeleteFormAttachmentInput;
};


export type MutationDeleteFormAttachmentPatArgs = {
  input: DeleteFormAttachmentPatInput;
};


export type MutationDeleteFormFieldArgs = {
  input: DeleteFormFieldInput;
};


export type MutationDeleteFormSectionArgs = {
  input: DeleteFormSectionInput;
};


export type MutationDeleteFormSubmissionArgs = {
  input: DeleteFormSubmissionInput;
};


export type MutationDeleteFormTemplateArgs = {
  input: DeleteFormTemplateInput;
};


export type MutationDeleteMeetingArgs = {
  input: DeleteMeetingInput;
};


export type MutationDeleteMeetingTypeArgs = {
  input: DeleteMeetingTypeInput;
};


export type MutationDeleteProjectFormArgs = {
  input: DeleteProjectFormInput;
};


export type MutationDeleteRequestArgs = {
  input: DeleteRequestInput;
};


export type MutationDeleteSecurityGroupArgs = {
  input: DeleteSecurityGroupInput;
};


export type MutationDeleteTaskArgs = {
  input: DeleteTaskInput;
};


export type MutationDeleteWorkflowActionConfigurationArgs = {
  input: DeleteWorkflowActionConfigurationInput;
};


export type MutationDeleteWorkflowDefinitionArgs = {
  input: DeleteWorkflowDefinitionInput;
};


export type MutationDeleteWorkflowInstanceArgs = {
  input: DeleteWorkflowInstanceInput;
};


export type MutationDeleteWorkflowVariableArgs = {
  input: DeleteWorkflowVariableInput;
};


export type MutationDuplicateCommercialProjectCalculatorArgs = {
  input: DuplicateCommercialProjectCalculatorInput;
};


export type MutationDuplicateFormTemplateArgs = {
  input: DuplicateFormTemplateInput;
};


export type MutationEditBudgetItemArgs = {
  input: EditBudgetItemInput;
};


export type MutationEditConditionArgs = {
  input: EditConditionInput;
};


export type MutationEditExpenseArgs = {
  input: EditExpenseInput;
};


export type MutationEditExternalUserArgs = {
  input: EditExternalUserInput;
};


export type MutationEditFormDefinitionArgs = {
  input: EditFormDefinitionInput;
};


export type MutationEditFormFieldArgs = {
  input: EditFormFieldInput;
};


export type MutationEditFormSectionArgs = {
  input: EditFormSectionInput;
};


export type MutationEditMeetingTypeArgs = {
  input: EditMeetingTypeInput;
};


export type MutationEditMemberSecurityGroupArgs = {
  input: EditMemberSecurityGroupInput;
};


export type MutationEditProjectArgs = {
  input: EditProjectInput;
};


export type MutationEditProjectProgressArgs = {
  input: EditProjectProgressInput;
};


export type MutationEditUserArgs = {
  input: EditUserInput;
};


export type MutationEditUserRoleArgs = {
  input: EditUserRoleInput;
};


export type MutationEditWorkflowDefinitionArgs = {
  input: EditWorkflowDefinitionInput;
};


export type MutationEditWorkflowVariableArgs = {
  input: EditWorkflowVariableInput;
};


export type MutationFillFormArgs = {
  input: FillFormInput;
};


export type MutationFillFormAnonymouslyArgs = {
  input: FillFormAnonymouslyInput;
};


export type MutationFillFormValueArgs = {
  input: FillFormValueInput;
};


export type MutationFillFormValuePatArgs = {
  input: FillFormValuePatInput;
};


export type MutationFillMatrixValueArgs = {
  input: FillMatrixValueInput;
};


export type MutationFillMatrixValuePatArgs = {
  input: FillMatrixValuePatInput;
};


export type MutationImportFoldersArgs = {
  input: ImportFoldersInput;
};


export type MutationImportTasksArgs = {
  input: ImportTasksInput;
};


export type MutationInviteUserArgs = {
  input: InviteUserInput;
};


export type MutationMoveChildActionArgs = {
  input: MoveChildActionInput;
};


export type MutationMoveFolderArgs = {
  input: MoveFolderInput;
};


export type MutationMoveFolderEntryArgs = {
  input: MoveFolderEntryInput;
};


export type MutationMoveFormFieldArgs = {
  input: MoveFormFieldInput;
};


export type MutationPublishDocumentArgs = {
  input: PublishDocumentInput;
};


export type MutationPublishFormArgs = {
  input: PublishFormInput;
};


export type MutationRemoveCommercialProjectCalculatorArgs = {
  input: RemoveCommercialProjectCalculatorInput;
};


export type MutationRemoveDocumentArgs = {
  input: RemoveDocumentInput;
};


export type MutationRemoveFormTemplateRoleAssignmentArgs = {
  input: RemoveFormTemplateRoleAssignmentInput;
};


export type MutationRemoveMatrixRowArgs = {
  input: RemoveMatrixRowInput;
};


export type MutationRemoveMatrixRowPatArgs = {
  input: RemoveMatrixRowPatInput;
};


export type MutationRemovePresenceArgs = {
  input: RemovePresenceInput;
};


export type MutationRemovePresencePatArgs = {
  input: RemovePresencePatInput;
};


export type MutationRemoveProjectMemberArgs = {
  input: RemoveProjectMemberInput;
};


export type MutationRemoveSecurityGroupMemberArgs = {
  input: RemoveSecurityGroupMemberInput;
};


export type MutationRemoveSignatureArgs = {
  input: RemoveSignatureInput;
};


export type MutationRemoveUserArgs = {
  input: RemoveUserInput;
};


export type MutationRenameDocumentArgs = {
  input: RenameDocumentInput;
};


export type MutationRenameFolderArgs = {
  input: RenameFolderInput;
};


export type MutationRenewAccessTokenFromExpiredTokenArgs = {
  input: RenewAccessTokenFromExpiredTokenInput;
};


export type MutationReorderFormSectionsArgs = {
  input: ReorderFormSectionsInput;
};


export type MutationReparentTaskArgs = {
  input: ReparentTaskInput;
};


export type MutationRequestFormSubmissionArgs = {
  input: RequestFormSubmissionInput;
};


export type MutationRequestFormSubmissionAnonymousArgs = {
  input: RequestFormSubmissionAnonymousInput;
};


export type MutationRescheduleTaskArgs = {
  input: RescheduleTaskInput;
};


export type MutationResendFormInvitationArgs = {
  input: ResendFormInvitationInput;
};


export type MutationReshareOrganizationResourceArgs = {
  input: ReshareOrganizationResourceInput;
};


export type MutationReshareProjectResourceArgs = {
  input: ReshareProjectResourceInput;
};


export type MutationSendSharingTemplateNotificationArgs = {
  input: SendSharingTemplateNotificationInput;
};


export type MutationShareOrganizationResourceArgs = {
  input: ShareOrganizationResourceInput;
};


export type MutationShareProjectResourceArgs = {
  input: ShareProjectResourceInput;
};


export type MutationSignDocumentArgs = {
  input: SignDocumentInput;
};


export type MutationSignDocumentAnonymouslyArgs = {
  input: SignDocumentAnonymouslyInput;
};


export type MutationStartTrialArgs = {
  input: StartTrialInput;
};


export type MutationStartWorkflowInstanceArgs = {
  input: StartWorkflowInstanceInput;
};


export type MutationSubmitWorkflowActionArgs = {
  input: SubmitWorkflowActionInput;
};


export type MutationUnpublishDocumentArgs = {
  input: UnpublishDocumentInput;
};


export type MutationUnpublishFormArgs = {
  input: UnpublishFormInput;
};


export type MutationUnshareOrganizationResourceArgs = {
  input: UnshareOrganizationResourceInput;
};


export type MutationUnshareProjectResourceArgs = {
  input: UnshareProjectResourceInput;
};


export type MutationUnsubscribeFromEmailsArgs = {
  input: UnsubscribeFromEmailsInput;
};


export type MutationUpdateBrandingConfigurationArgs = {
  input: UpdateBrandingConfigurationInput;
};


export type MutationUpdateCommercialProjectCalculatorArgs = {
  input: UpdateCommercialProjectCalculatorInput;
};


export type MutationUpdateFormSettingsArgs = {
  input: UpdateFormSettingsInput;
};


export type MutationUpdateMyPreferencesArgs = {
  input: UpdateMyPreferencesInput;
};


export type MutationUpdatePresenceArgs = {
  input: UpdatePresenceInput;
};


export type MutationUpdatePresencePatArgs = {
  input: UpdatePresencePatInput;
};


export type MutationUpdateSecurityGroupArgs = {
  input: UpdateSecurityGroupInput;
};


export type MutationUpdateSecurityGroupRolesArgs = {
  input: UpdateSecurityGroupRolesInput;
};


export type MutationUpdateTaskArgs = {
  input: UpdateTaskInput;
};


export type MutationUpdateTaskStatusArgs = {
  input: UpdateTaskStatusInput;
};


export type MutationUpdateWorkflowActionConfigurationArgs = {
  input: UpdateWorkflowActionConfigurationInput;
};

export type MyRoles = {
  __typename?: 'MyRoles';
  roles: Array<BuiltInRoles>;
};

export type NotFoundError = Error & {
  __typename?: 'NotFoundError';
  message: Scalars['String']['output'];
};

export type NullableOfDocumentThumbnailStatusOperationFilterInput = {
  eq?: InputMaybe<DocumentThumbnailStatus>;
  in?: InputMaybe<Array<InputMaybe<DocumentThumbnailStatus>>>;
  neq?: InputMaybe<DocumentThumbnailStatus>;
  nin?: InputMaybe<Array<InputMaybe<DocumentThumbnailStatus>>>;
};

export type PermissionsGrouping = {
  __typename?: 'PermissionsGrouping';
  budgetPermission: BudgetAndExpensesPermission;
  documentsPermission: DocumentsPermission;
  formsPermission: FormsPermission;
  tasksPermission: TasksPermission;
  workflowsPermission: WorkflowsPermission;
};

export enum PrincipalType {
  SecurityGroup = 'SecurityGroup',
  SharedWithAllProjectMembers = 'SharedWithAllProjectMembers',
  SharedWithAnyone = 'SharedWithAnyone',
  User = 'User'
}

export type PrincipalTypeOperationFilterInput = {
  eq?: InputMaybe<PrincipalType>;
  in?: InputMaybe<Array<PrincipalType>>;
  neq?: InputMaybe<PrincipalType>;
  nin?: InputMaybe<Array<PrincipalType>>;
};

export type Project = {
  __typename?: 'Project';
  budgetItems: Array<BudgetItem>;
  createdBy?: Maybe<ApplicationUser>;
  createdById: Scalars['String']['output'];
  createdOn: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  documents: Array<Document>;
  entries: Array<FolderEntry>;
  expenses: Array<Expense>;
  folders: Array<Folder>;
  forms: Array<ProjectForm>;
  hideBudgetFeature: Scalars['Boolean']['output'];
  id: Scalars['Int']['output'];
  isArchived: Scalars['Boolean']['output'];
  meetingTypes: Array<MeetingType>;
  meetings: Array<Meeting>;
  myPermissions: PermissionsGrouping;
  myRoles: MyRoles;
  name: Scalars['String']['output'];
  projectEvents: Array<ProjectEvent>;
  projectProgress: Scalars['Int']['output'];
  rootFolder?: Maybe<Folder>;
  securityGroups: Array<SecurityGroup>;
  securityRoleAssignments: Array<RoleAssignment>;
  tasks: Array<ProjectTask>;
};


export type ProjectSecurityGroupsArgs = {
  where?: InputMaybe<SecurityGroupFilterInput>;
};

export type ProjectAccessToken = {
  __typename?: 'ProjectAccessToken';
  createdOn: Scalars['DateTime']['output'];
  customMessage?: Maybe<Scalars['String']['output']>;
  document?: Maybe<Document>;
  documentId?: Maybe<Scalars['Int']['output']>;
  forUser?: Maybe<ApplicationUser>;
  forUserId: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  isZohoSignEnabled?: Maybe<Scalars['Boolean']['output']>;
  lastRenewed?: Maybe<Scalars['DateTime']['output']>;
  project?: Maybe<Project>;
  projectForm?: Maybe<ProjectForm>;
  projectFormId?: Maybe<Scalars['Int']['output']>;
  projectFormSubmission?: Maybe<ProjectFormSubmission>;
  projectFormSubmissionId?: Maybe<Scalars['Int']['output']>;
  projectId: Scalars['Int']['output'];
  token: Scalars['String']['output'];
};

export type ProjectAccessTokenContent = {
  __typename?: 'ProjectAccessTokenContent';
  document?: Maybe<DocumentFromAnonymousLink>;
  forUser?: Maybe<ApplicationUserFromAnonymousLink>;
  forUserId: Scalars['String']['output'];
  formSubmission?: Maybe<FormSubmissionAnonymousLink>;
  isTokenRenewable: Scalars['Boolean']['output'];
  isZohoSignEnabled?: Maybe<Scalars['Boolean']['output']>;
  project?: Maybe<ProjectFromAnonymousLink>;
  projectForm?: Maybe<ProjectFormFromAnonymousLink>;
  tenant?: Maybe<TenantFromAnonymousLink>;
  tokenValidationError?: Maybe<Scalars['String']['output']>;
};

export type ProjectAccessTokenFilterInput = {
  and?: InputMaybe<Array<ProjectAccessTokenFilterInput>>;
  documentId?: InputMaybe<IntOperationFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<ProjectAccessTokenFilterInput>>;
  projectFormId?: InputMaybe<IntOperationFilterInput>;
  projectId?: InputMaybe<IntOperationFilterInput>;
};

export type ProjectBudget = {
  __typename?: 'ProjectBudget';
  budgetOverrun: Scalars['Decimal']['output'];
  totalBudgetedAmount: Scalars['Decimal']['output'];
  totalExpendedAmount: Scalars['Decimal']['output'];
};

export type ProjectEvent = {
  __typename?: 'ProjectEvent';
  document?: Maybe<Document>;
  documentId?: Maybe<Scalars['Int']['output']>;
  eventDate: Scalars['DateTime']['output'];
  eventTriggeredBy?: Maybe<ApplicationUser>;
  eventTriggeredById: Scalars['String']['output'];
  eventType: DomainEventType;
  form?: Maybe<ProjectForm>;
  formId?: Maybe<Scalars['Int']['output']>;
  formSubmission?: Maybe<ProjectFormSubmission>;
  formSubmissionId?: Maybe<Scalars['Int']['output']>;
  id: Scalars['Int']['output'];
  meeting?: Maybe<Meeting>;
  meetingId?: Maybe<Scalars['Int']['output']>;
  project?: Maybe<Project>;
  projectId: Scalars['Int']['output'];
  workflowAction?: Maybe<WorkflowActionConfiguration>;
  workflowActionId?: Maybe<Scalars['Int']['output']>;
  workflowInstance?: Maybe<WorkflowInstance>;
  workflowInstanceId?: Maybe<Scalars['Int']['output']>;
};

export type ProjectEventFilterInput = {
  and?: InputMaybe<Array<ProjectEventFilterInput>>;
  eventDate?: InputMaybe<DateTimeOperationFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<ProjectEventFilterInput>>;
  projectId?: InputMaybe<IntOperationFilterInput>;
};

export type ProjectFilterInput = {
  and?: InputMaybe<Array<ProjectFilterInput>>;
  budgetItems?: InputMaybe<ListFilterInputTypeOfBudgetItemFilterInput>;
  createdBy?: InputMaybe<ApplicationUserFilterInput>;
  createdById?: InputMaybe<StringOperationFilterInput>;
  createdOn?: InputMaybe<DateTimeOperationFilterInput>;
  description?: InputMaybe<StringOperationFilterInput>;
  documents?: InputMaybe<ListFilterInputTypeOfDocumentFilterInput>;
  entries?: InputMaybe<ListFilterInputTypeOfFolderEntryFilterInput>;
  expenses?: InputMaybe<ListFilterInputTypeOfExpenseFilterInput>;
  folders?: InputMaybe<ListFilterInputTypeOfFolderFilterInput>;
  forms?: InputMaybe<ListFilterInputTypeOfProjectFormFilterInput>;
  hideBudgetFeature?: InputMaybe<BooleanOperationFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  isArchived?: InputMaybe<BooleanOperationFilterInput>;
  meetingTypes?: InputMaybe<ListFilterInputTypeOfMeetingTypeFilterInput>;
  meetings?: InputMaybe<ListFilterInputTypeOfMeetingFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<ProjectFilterInput>>;
  projectEvents?: InputMaybe<ListFilterInputTypeOfProjectEventFilterInput>;
  projectProgress?: InputMaybe<IntOperationFilterInput>;
  rootFolder?: InputMaybe<FolderFilterInput>;
  securityGroups?: InputMaybe<ListFilterInputTypeOfSecurityGroupFilterInput>;
  securityRoleAssignments?: InputMaybe<ListFilterInputTypeOfRoleAssignmentFilterInput>;
  tasks?: InputMaybe<ListFilterInputTypeOfProjectTaskFilterInput>;
};

export type ProjectForm = {
  __typename?: 'ProjectForm';
  allowedUsers: Array<ApplicationUser>;
  createdBy?: Maybe<ApplicationUser>;
  createdById: Scalars['String']['output'];
  createdOn: Scalars['DateTime']['output'];
  formDefinition?: Maybe<FormDefinition>;
  formDefinitionId: Scalars['Int']['output'];
  formSettings: FormSettings;
  id: Scalars['Int']['output'];
  project?: Maybe<Project>;
  projectId: Scalars['Int']['output'];
  publishedBy?: Maybe<ApplicationUser>;
  publishedById?: Maybe<Scalars['String']['output']>;
  publishedOn?: Maybe<Scalars['DateTime']['output']>;
  status: FormStatus;
  submissions: Array<ProjectFormSubmission>;
};

export type ProjectFormFilterInput = {
  and?: InputMaybe<Array<ProjectFormFilterInput>>;
  createdBy?: InputMaybe<ApplicationUserFilterInput>;
  createdById?: InputMaybe<StringOperationFilterInput>;
  createdOn?: InputMaybe<DateTimeOperationFilterInput>;
  formDefinition?: InputMaybe<FormDefinitionFilterInput>;
  formDefinitionId?: InputMaybe<IntOperationFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<ProjectFormFilterInput>>;
  projectId?: InputMaybe<IntOperationFilterInput>;
  publishedBy?: InputMaybe<ApplicationUserFilterInput>;
  publishedById?: InputMaybe<StringOperationFilterInput>;
  publishedOn?: InputMaybe<DateTimeOperationFilterInput>;
  status?: InputMaybe<FormStatusOperationFilterInput>;
};

export type ProjectFormFromAnonymousLink = {
  __typename?: 'ProjectFormFromAnonymousLink';
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  isUserAuthorizedToFill: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  publishedBy: ApplicationUserFromAnonymousLink;
  publishedOn?: Maybe<Scalars['DateTime']['output']>;
  status: FormStatus;
};

export type ProjectFormOverview = {
  __typename?: 'ProjectFormOverview';
  createdBy: Scalars['String']['output'];
  createdOn?: Maybe<Scalars['DateTime']['output']>;
  formName: Scalars['String']['output'];
  formSettings: FormSettings;
  id: Scalars['Int']['output'];
  publishedOn?: Maybe<Scalars['DateTime']['output']>;
  status: FormStatus;
  submissionsCount: Scalars['Int']['output'];
};

export type ProjectFormPresence = {
  __typename?: 'ProjectFormPresence';
  displayName: Scalars['String']['output'];
  fieldId?: Maybe<Scalars['Int']['output']>;
  formSubmissionId: Scalars['Int']['output'];
  matrixColumnId?: Maybe<Scalars['Int']['output']>;
  matrixRowId?: Maybe<Scalars['Int']['output']>;
  uniqueClientId: Scalars['String']['output'];
  userId: Scalars['String']['output'];
};

export type ProjectFormSubmission = {
  __typename?: 'ProjectFormSubmission';
  attachments: Array<FormFileAttachment>;
  belongsToUser?: Maybe<ApplicationUser>;
  belongsToUserId?: Maybe<Scalars['String']['output']>;
  createdOn: Scalars['DateTime']['output'];
  events: Array<ProjectEvent>;
  formDefinition?: Maybe<FormDefinition>;
  formDefinitionId: Scalars['Int']['output'];
  formValues: Array<FormValue>;
  id: Scalars['Int']['output'];
  projectForm?: Maybe<ProjectForm>;
  projectFormId?: Maybe<Scalars['Int']['output']>;
  status: SubmissionStatus;
  submittedOn?: Maybe<Scalars['DateTime']['output']>;
  workflowAction?: Maybe<WorkflowActionConfiguration>;
  workflowActionId?: Maybe<Scalars['Int']['output']>;
  workflowInstance?: Maybe<WorkflowInstance>;
  workflowInstanceId?: Maybe<Scalars['Int']['output']>;
};

export type ProjectFormSubmissionFilterInput = {
  and?: InputMaybe<Array<ProjectFormSubmissionFilterInput>>;
  belongsToUser?: InputMaybe<ApplicationUserFilterInput>;
  belongsToUserId?: InputMaybe<StringOperationFilterInput>;
  createdOn?: InputMaybe<DateTimeOperationFilterInput>;
  events?: InputMaybe<ListFilterInputTypeOfProjectEventFilterInput>;
  formDefinition?: InputMaybe<FormDefinitionFilterInput>;
  formDefinitionId?: InputMaybe<IntOperationFilterInput>;
  formValues?: InputMaybe<ListFilterInputTypeOfFormValueFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  or?: InputMaybe<Array<ProjectFormSubmissionFilterInput>>;
  projectForm?: InputMaybe<ProjectFormFilterInput>;
  projectFormId?: InputMaybe<IntOperationFilterInput>;
  status?: InputMaybe<SubmissionStatusOperationFilterInput>;
  submittedOn?: InputMaybe<DateTimeOperationFilterInput>;
  workflowAction?: InputMaybe<WorkflowActionConfigurationFilterInput>;
  workflowActionId?: InputMaybe<IntOperationFilterInput>;
  workflowInstance?: InputMaybe<WorkflowInstanceFilterInput>;
  workflowInstanceId?: InputMaybe<IntOperationFilterInput>;
};

export type ProjectFromAnonymousLink = {
  __typename?: 'ProjectFromAnonymousLink';
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
};

export type ProjectSortInput = {
  createdBy?: InputMaybe<ApplicationUserSortInput>;
  createdById?: InputMaybe<SortEnumType>;
  createdOn?: InputMaybe<SortEnumType>;
  description?: InputMaybe<SortEnumType>;
  hideBudgetFeature?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  isArchived?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  projectProgress?: InputMaybe<SortEnumType>;
  rootFolder?: InputMaybe<FolderSortInput>;
};

export type ProjectTask = {
  __typename?: 'ProjectTask';
  assignees: Array<ApplicationUser>;
  completedOn?: Maybe<Scalars['DateTime']['output']>;
  createdBy?: Maybe<ApplicationUser>;
  createdById?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  document?: Maybe<Document>;
  documentId?: Maybe<Scalars['Int']['output']>;
  dueDate?: Maybe<Scalars['DateTime']['output']>;
  hierarchyPath?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  name?: Maybe<Scalars['String']['output']>;
  predecessors: Array<ProjectTask>;
  project?: Maybe<Project>;
  projectForm?: Maybe<ProjectForm>;
  projectFormId?: Maybe<Scalars['Int']['output']>;
  projectId: Scalars['Int']['output'];
  roleAssignments: Array<RoleAssignment>;
  startDate?: Maybe<Scalars['DateTime']['output']>;
  status: ProjectTaskStatus;
  successors: Array<ProjectTask>;
  type: ProjectTaskType;
};

export type ProjectTaskFilterInput = {
  and?: InputMaybe<Array<ProjectTaskFilterInput>>;
  assignees?: InputMaybe<ListFilterInputTypeOfApplicationUserFilterInput>;
  completedOn?: InputMaybe<DateTimeOperationFilterInput>;
  concurrencyTimestamp?: InputMaybe<DateTimeOperationFilterInput>;
  createdBy?: InputMaybe<ApplicationUserFilterInput>;
  createdById?: InputMaybe<StringOperationFilterInput>;
  description?: InputMaybe<StringOperationFilterInput>;
  document?: InputMaybe<DocumentFilterInput>;
  documentId?: InputMaybe<IntOperationFilterInput>;
  dueDate?: InputMaybe<DateTimeOperationFilterInput>;
  hierarchyPath?: InputMaybe<HierarchyIdFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<ProjectTaskFilterInput>>;
  predecessors?: InputMaybe<ListFilterInputTypeOfProjectTaskFilterInput>;
  project?: InputMaybe<ProjectFilterInput>;
  projectForm?: InputMaybe<ProjectFormFilterInput>;
  projectFormId?: InputMaybe<IntOperationFilterInput>;
  projectId?: InputMaybe<IntOperationFilterInput>;
  roleAssignments?: InputMaybe<ListFilterInputTypeOfRoleAssignmentFilterInput>;
  startDate?: InputMaybe<DateTimeOperationFilterInput>;
  status?: InputMaybe<ProjectTaskStatusOperationFilterInput>;
  successors?: InputMaybe<ListFilterInputTypeOfProjectTaskFilterInput>;
  type?: InputMaybe<ProjectTaskTypeOperationFilterInput>;
};

export enum ProjectTaskStatus {
  Done = 'Done',
  Draft = 'Draft',
  InProgress = 'InProgress',
  Ready = 'Ready'
}

export type ProjectTaskStatusOperationFilterInput = {
  eq?: InputMaybe<ProjectTaskStatus>;
  in?: InputMaybe<Array<ProjectTaskStatus>>;
  neq?: InputMaybe<ProjectTaskStatus>;
  nin?: InputMaybe<Array<ProjectTaskStatus>>;
};

export enum ProjectTaskType {
  Custom = 'Custom',
  FillForm = 'FillForm',
  SignDocument = 'SignDocument'
}

export type ProjectTaskTypeOperationFilterInput = {
  eq?: InputMaybe<ProjectTaskType>;
  in?: InputMaybe<Array<ProjectTaskType>>;
  neq?: InputMaybe<ProjectTaskType>;
  nin?: InputMaybe<Array<ProjectTaskType>>;
};

export enum ProvisioningStatus {
  NotStarted = 'NotStarted',
  Provisioning = 'Provisioning',
  Ready = 'Ready'
}

export type PublishDocumentError = NotFoundError;

export type PublishDocumentInput = {
  documentId: Scalars['Int']['input'];
  projectId: Scalars['Int']['input'];
};

export type PublishDocumentPayload = {
  __typename?: 'PublishDocumentPayload';
  document?: Maybe<Document>;
  errors?: Maybe<Array<PublishDocumentError>>;
};

export type PublishFormError = ValidationResultError;

export type PublishFormInput = {
  projectFormId: Scalars['Int']['input'];
  projectId: Scalars['Int']['input'];
};

export type PublishFormPayload = {
  __typename?: 'PublishFormPayload';
  errors?: Maybe<Array<PublishFormError>>;
  projectForm?: Maybe<ProjectForm>;
};

export type Query = {
  __typename?: 'Query';
  budgetItems: Array<BudgetItem>;
  calendarAvailabilities: Availabilities;
  calendarAvailabilitiesForMeetingType: Availabilities;
  childFolders: Array<Folder>;
  commercialProjectCalculator?: Maybe<CommercialProjectCalculator>;
  commercialProjectCalculators: Array<CommercialProjectCalculator>;
  document?: Maybe<Document>;
  documentPermissions: Array<RoleAssignment>;
  expenses: Array<Expense>;
  externalLinkPermissions: Array<RoleAssignment>;
  featureFlags: FeatureFlags;
  folder?: Maybe<Folder>;
  folderEntries: Array<FolderEntry>;
  folderEntry?: Maybe<FolderEntry>;
  folderPermissions: Array<RoleAssignment>;
  formPresences: Array<ProjectFormPresence>;
  formPresencesPAT: Array<ProjectFormPresence>;
  formTemplate?: Maybe<FormTemplate>;
  formTemplatePermissions: Array<RoleAssignment>;
  formTemplates: Array<FormTemplate>;
  loginInfo: LoginInfo;
  me?: Maybe<ApplicationUser>;
  meeting?: Maybe<Meeting>;
  meetingTypes: Array<MeetingType>;
  meetings: Array<Meeting>;
  myPermissions: PermissionsGrouping;
  myProjectRoles: MyRoles;
  myTasks: Array<ProjectTask>;
  organizationUsers: Array<ApplicationUser>;
  parentTask?: Maybe<ProjectTask>;
  project?: Maybe<Project>;
  projectAccessTokenContent?: Maybe<ProjectAccessTokenContent>;
  projectAccessTokenFormValues: Array<FormValue>;
  projectAccessTokens: Array<ProjectAccessToken>;
  projectBudget: ProjectBudget;
  projectBudgetProgress: ProjectBudget;
  projectDocuments: Array<Document>;
  projectEvents: Array<ProjectEvent>;
  projectFolders: Array<Folder>;
  projectForm?: Maybe<ProjectForm>;
  projectFormOverviews: Array<ProjectFormOverview>;
  projectFormPermissions: Array<RoleAssignment>;
  projectFormSettings?: Maybe<FormSettings>;
  projectFormSubmission?: Maybe<ProjectFormSubmission>;
  projectFormSubmissions: Array<ProjectFormSubmission>;
  projectFormValues: Array<FormValue>;
  projectForms: Array<ProjectForm>;
  projectMember?: Maybe<ApplicationUser>;
  projectMembers: Array<ApplicationUser>;
  projectTask?: Maybe<ProjectTask>;
  projectTaskSubtasks: Array<ProjectTask>;
  projectTasks: Array<ProjectTask>;
  projectWorkflow?: Maybe<WorkflowInstance>;
  projectWorkflows: Array<WorkflowInstance>;
  projects: Array<Project>;
  securityGroup?: Maybe<SecurityGroup>;
  securityGroupForUserInProject: Array<SecurityGroup>;
  securityGroupsForProject: Array<SecurityGroup>;
  taskPermissions: Array<RoleAssignment>;
  tenantSettings: TenantSettings;
  tenantSubscriptionDetails: AppSubscriptionModel;
  tenantTheme: TenantTheme;
  tenants: Array<Tenant>;
  user?: Maybe<ApplicationUser>;
  userTenants: Array<Tenant>;
  users: Array<ApplicationUser>;
  validPredecessorTasks: Array<ProjectTask>;
  validateUnsubscribeToken: ValidateUnsubscribeTokenResult;
  workflowActionConfiguration?: Maybe<WorkflowActionConfiguration>;
  workflowActionConfigurationsOfWorkflow: Array<WorkflowActionConfiguration>;
  workflowActionDefinitions: Array<WorkflowActionDefinition>;
  workflowTemplate?: Maybe<WorkflowDefinition>;
  workflowTemplatePermissions: Array<RoleAssignment>;
  workflowTemplates: Array<WorkflowDefinition>;
  zohoEmbedUrl: Scalars['String']['output'];
};


export type QueryBudgetItemsArgs = {
  projectId: Scalars['Int']['input'];
};


export type QueryCalendarAvailabilitiesArgs = {
  input: GetCalendarAvailabilitiesInput;
};


export type QueryCalendarAvailabilitiesForMeetingTypeArgs = {
  input: GetCalendarAvailabilitiesForMeetingTypeInput;
};


export type QueryChildFoldersArgs = {
  parentFolderId: Scalars['Int']['input'];
  where?: InputMaybe<FolderFilterInput>;
};


export type QueryCommercialProjectCalculatorArgs = {
  calculatorId: Scalars['Int']['input'];
  projectId: Scalars['Int']['input'];
  where?: InputMaybe<CommercialProjectCalculatorFilterInput>;
};


export type QueryCommercialProjectCalculatorsArgs = {
  projectId: Scalars['Int']['input'];
  where?: InputMaybe<CommercialProjectCalculatorFilterInput>;
};


export type QueryDocumentArgs = {
  documentId: Scalars['Int']['input'];
  where?: InputMaybe<DocumentFilterInput>;
};


export type QueryDocumentPermissionsArgs = {
  documentId: Scalars['Int']['input'];
  projectId: Scalars['Int']['input'];
};


export type QueryExpensesArgs = {
  projectId: Scalars['Int']['input'];
  where?: InputMaybe<ExpenseFilterInput>;
};


export type QueryExternalLinkPermissionsArgs = {
  linkId: Scalars['Int']['input'];
  projectId: Scalars['Int']['input'];
};


export type QueryFolderArgs = {
  folderId: Scalars['Int']['input'];
  projectId: Scalars['Int']['input'];
};


export type QueryFolderEntriesArgs = {
  folderId: Scalars['Int']['input'];
  projectId: Scalars['Int']['input'];
  where?: InputMaybe<FolderEntryFilterInput>;
};


export type QueryFolderEntryArgs = {
  entryId: Scalars['Int']['input'];
  where?: InputMaybe<FolderEntryFilterInput>;
};


export type QueryFolderPermissionsArgs = {
  folderId: Scalars['Int']['input'];
  projectId: Scalars['Int']['input'];
};


export type QueryFormPresencesArgs = {
  formSubmissionId: Scalars['Int']['input'];
};


export type QueryFormPresencesPatArgs = {
  accessToken: Scalars['String']['input'];
};


export type QueryFormTemplateArgs = {
  templateId: Scalars['Int']['input'];
  where?: InputMaybe<FormTemplateFilterInput>;
};


export type QueryFormTemplatePermissionsArgs = {
  templateId: Scalars['Int']['input'];
};


export type QueryFormTemplatesArgs = {
  where?: InputMaybe<FormTemplateFilterInput>;
};


export type QueryMeetingArgs = {
  meetingId: Scalars['Int']['input'];
  projectId: Scalars['Int']['input'];
};


export type QueryMeetingTypesArgs = {
  projectId: Scalars['Int']['input'];
};


export type QueryMeetingsArgs = {
  projectId: Scalars['Int']['input'];
  where?: InputMaybe<MeetingFilterInput>;
};


export type QueryMyTasksArgs = {
  projectId: Scalars['Int']['input'];
  where?: InputMaybe<ProjectTaskFilterInput>;
};


export type QueryOrganizationUsersArgs = {
  where?: InputMaybe<ApplicationUserFilterInput>;
};


export type QueryParentTaskArgs = {
  projectId: Scalars['Int']['input'];
  taskId: Scalars['Int']['input'];
  where?: InputMaybe<ProjectTaskFilterInput>;
};


export type QueryProjectArgs = {
  id: Scalars['Int']['input'];
};


export type QueryProjectAccessTokenContentArgs = {
  token: Scalars['String']['input'];
};


export type QueryProjectAccessTokenFormValuesArgs = {
  token: Scalars['String']['input'];
};


export type QueryProjectAccessTokensArgs = {
  projectId: Scalars['Int']['input'];
  where?: InputMaybe<ProjectAccessTokenFilterInput>;
};


export type QueryProjectBudgetArgs = {
  id: Scalars['Int']['input'];
};


export type QueryProjectBudgetProgressArgs = {
  id: Scalars['Int']['input'];
};


export type QueryProjectDocumentsArgs = {
  projectId: Scalars['Int']['input'];
  where?: InputMaybe<DocumentFilterInput>;
};


export type QueryProjectEventsArgs = {
  projectId: Scalars['Int']['input'];
  where?: InputMaybe<ProjectEventFilterInput>;
};


export type QueryProjectFoldersArgs = {
  projectId: Scalars['Int']['input'];
  where?: InputMaybe<FolderFilterInput>;
};


export type QueryProjectFormArgs = {
  formId: Scalars['Int']['input'];
  where?: InputMaybe<ProjectFormFilterInput>;
};


export type QueryProjectFormOverviewsArgs = {
  projectId: Scalars['Int']['input'];
  where?: InputMaybe<ProjectFormFilterInput>;
};


export type QueryProjectFormPermissionsArgs = {
  formId: Scalars['Int']['input'];
  projectId: Scalars['Int']['input'];
};


export type QueryProjectFormSettingsArgs = {
  formId: Scalars['Int']['input'];
  where?: InputMaybe<FormSettingsFilterInput>;
};


export type QueryProjectFormSubmissionArgs = {
  submissionId: Scalars['Int']['input'];
};


export type QueryProjectFormSubmissionsArgs = {
  formId?: InputMaybe<Scalars['Int']['input']>;
  projectId: Scalars['Int']['input'];
  where?: InputMaybe<ProjectFormSubmissionFilterInput>;
};


export type QueryProjectFormValuesArgs = {
  formSubmissionId: Scalars['Int']['input'];
  where?: InputMaybe<FormValueFilterInput>;
};


export type QueryProjectFormsArgs = {
  projectId: Scalars['Int']['input'];
  where?: InputMaybe<ProjectFormFilterInput>;
};


export type QueryProjectMemberArgs = {
  projectId: Scalars['Int']['input'];
  userId: Scalars['String']['input'];
  where?: InputMaybe<ApplicationUserFilterInput>;
};


export type QueryProjectMembersArgs = {
  projectId: Scalars['Int']['input'];
  where?: InputMaybe<ApplicationUserFilterInput>;
};


export type QueryProjectTaskArgs = {
  projectId: Scalars['Int']['input'];
  taskId: Scalars['Int']['input'];
  where?: InputMaybe<ProjectTaskFilterInput>;
};


export type QueryProjectTaskSubtasksArgs = {
  taskId: Scalars['Int']['input'];
  where?: InputMaybe<ProjectTaskFilterInput>;
};


export type QueryProjectTasksArgs = {
  projectId: Scalars['Int']['input'];
  where?: InputMaybe<ProjectTaskFilterInput>;
};


export type QueryProjectWorkflowArgs = {
  projectId: Scalars['Int']['input'];
  where?: InputMaybe<WorkflowInstanceFilterInput>;
  workflowId: Scalars['Int']['input'];
};


export type QueryProjectWorkflowsArgs = {
  projectId: Scalars['Int']['input'];
  where?: InputMaybe<WorkflowInstanceFilterInput>;
};


export type QueryProjectsArgs = {
  where?: InputMaybe<ProjectFilterInput>;
};


export type QuerySecurityGroupArgs = {
  projectId: Scalars['Int']['input'];
  securityGroupId: Scalars['String']['input'];
  where?: InputMaybe<SecurityGroupFilterInput>;
};


export type QuerySecurityGroupForUserInProjectArgs = {
  projectId: Scalars['Int']['input'];
  userId: Scalars['String']['input'];
  where?: InputMaybe<SecurityGroupFilterInput>;
};


export type QuerySecurityGroupsForProjectArgs = {
  projectId: Scalars['Int']['input'];
  where?: InputMaybe<SecurityGroupFilterInput>;
};


export type QueryTaskPermissionsArgs = {
  projectId: Scalars['Int']['input'];
  taskId: Scalars['Int']['input'];
};


export type QueryUserArgs = {
  id: Scalars['String']['input'];
  where?: InputMaybe<ApplicationUserFilterInput>;
};


export type QueryUsersArgs = {
  where?: InputMaybe<ApplicationUserFilterInput>;
};


export type QueryValidPredecessorTasksArgs = {
  taskId: Scalars['Int']['input'];
  where?: InputMaybe<ProjectTaskFilterInput>;
};


export type QueryValidateUnsubscribeTokenArgs = {
  input: ValidateUnsubscribeTokenInput;
};


export type QueryWorkflowActionConfigurationArgs = {
  workflowActionId: Scalars['Int']['input'];
};


export type QueryWorkflowActionConfigurationsOfWorkflowArgs = {
  order?: InputMaybe<Array<WorkflowActionConfigurationSortInput>>;
  workflowId: Scalars['Int']['input'];
};


export type QueryWorkflowActionDefinitionsArgs = {
  order?: InputMaybe<Array<WorkflowActionDefinitionSortInput>>;
};


export type QueryWorkflowTemplateArgs = {
  templateId: Scalars['Int']['input'];
};


export type QueryWorkflowTemplatePermissionsArgs = {
  templateId: Scalars['Int']['input'];
};


export type QueryWorkflowTemplatesArgs = {
  where?: InputMaybe<WorkflowDefinitionFilterInput>;
};


export type QueryZohoEmbedUrlArgs = {
  actionId: Scalars['String']['input'];
  requestId: Scalars['String']['input'];
};

export type ReSendMyWeeklyEmailPayload = {
  __typename?: 'ReSendMyWeeklyEmailPayload';
  reSendMyWeeklyEmailResponse?: Maybe<ReSendMyWeeklyEmailResponse>;
};

export type ReSendMyWeeklyEmailResponse = {
  __typename?: 'ReSendMyWeeklyEmailResponse';
  wasEmailSent: Scalars['Boolean']['output'];
};

export type RemoveCommercialProjectCalculatorError = NotFoundError | ValidationResultError;

export type RemoveCommercialProjectCalculatorInput = {
  id: Scalars['Int']['input'];
  projectId: Scalars['Int']['input'];
};

export type RemoveCommercialProjectCalculatorPayload = {
  __typename?: 'RemoveCommercialProjectCalculatorPayload';
  commercialProjectCalculator?: Maybe<CommercialProjectCalculator>;
  errors?: Maybe<Array<RemoveCommercialProjectCalculatorError>>;
};

export type RemoveDocumentError = NotFoundError;

export type RemoveDocumentInput = {
  documentId: Scalars['Int']['input'];
  projectId: Scalars['Int']['input'];
};

export type RemoveDocumentPayload = {
  __typename?: 'RemoveDocumentPayload';
  document?: Maybe<Document>;
  errors?: Maybe<Array<RemoveDocumentError>>;
};

export type RemoveFormTemplateRoleAssignmentError = NotFoundError | ValidationResultError;

export type RemoveFormTemplateRoleAssignmentInput = {
  formTemplateId: Scalars['Int']['input'];
  roleAssignmentId: Scalars['String']['input'];
};

export type RemoveFormTemplateRoleAssignmentPayload = {
  __typename?: 'RemoveFormTemplateRoleAssignmentPayload';
  errors?: Maybe<Array<RemoveFormTemplateRoleAssignmentError>>;
  roleAssignment?: Maybe<RoleAssignment>;
};

export type RemoveMatrixRowError = NotFoundError | ValidationResultError;

export type RemoveMatrixRowInput = {
  matrixFieldId: Scalars['Int']['input'];
  rowId: Scalars['Int']['input'];
  submissionId: Scalars['Int']['input'];
};

export type RemoveMatrixRowPatError = NotFoundError | ValidationResultError;

export type RemoveMatrixRowPatInput = {
  accessToken: Scalars['String']['input'];
  matrixFieldId: Scalars['Int']['input'];
  rowId: Scalars['Int']['input'];
  submissionId: Scalars['Int']['input'];
};

export type RemoveMatrixRowPatPayload = {
  __typename?: 'RemoveMatrixRowPATPayload';
  errors?: Maybe<Array<RemoveMatrixRowPatError>>;
  matrixRow?: Maybe<MatrixRow>;
};

export type RemoveMatrixRowPayload = {
  __typename?: 'RemoveMatrixRowPayload';
  errors?: Maybe<Array<RemoveMatrixRowError>>;
  matrixRow?: Maybe<MatrixRow>;
};

export type RemovePresenceError = NotFoundError | ValidationResultError;

export type RemovePresenceInput = {
  submissionId: Scalars['Int']['input'];
  uniqueClientId: Scalars['String']['input'];
};

export type RemovePresencePatError = NotFoundError | ValidationResultError;

export type RemovePresencePatInput = {
  accessToken: Scalars['String']['input'];
  submissionId: Scalars['Int']['input'];
  uniqueClientId: Scalars['String']['input'];
};

export type RemovePresencePatPayload = {
  __typename?: 'RemovePresencePATPayload';
  errors?: Maybe<Array<RemovePresencePatError>>;
  projectFormPresence?: Maybe<ProjectFormPresence>;
};

export type RemovePresencePayload = {
  __typename?: 'RemovePresencePayload';
  errors?: Maybe<Array<RemovePresenceError>>;
  projectFormPresence?: Maybe<ProjectFormPresence>;
};

export type RemoveProjectMemberError = NotFoundError;

export type RemoveProjectMemberInput = {
  projectId: Scalars['Int']['input'];
  userId: Scalars['String']['input'];
};

export type RemoveProjectMemberPayload = {
  __typename?: 'RemoveProjectMemberPayload';
  errors?: Maybe<Array<RemoveProjectMemberError>>;
  project?: Maybe<Project>;
};

export type RemoveSecurityGroupMemberError = NotFoundError | ValidationResultError;

export type RemoveSecurityGroupMemberInput = {
  projectId: Scalars['Int']['input'];
  securityGroupId: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};

export type RemoveSecurityGroupMemberPayload = {
  __typename?: 'RemoveSecurityGroupMemberPayload';
  errors?: Maybe<Array<RemoveSecurityGroupMemberError>>;
  project?: Maybe<Project>;
};

export type RemoveSignatureError = NotFoundError;

export type RemoveSignatureInput = {
  documentId: Scalars['Int']['input'];
  projectId: Scalars['Int']['input'];
};

export type RemoveSignaturePayload = {
  __typename?: 'RemoveSignaturePayload';
  document?: Maybe<Document>;
  errors?: Maybe<Array<RemoveSignatureError>>;
};

export type RemoveUserError = NotFoundError | ValidationResultError;

export type RemoveUserInput = {
  id: Scalars['String']['input'];
};

export type RemoveUserPayload = {
  __typename?: 'RemoveUserPayload';
  applicationUser?: Maybe<ApplicationUser>;
  errors?: Maybe<Array<RemoveUserError>>;
};

export type RenameDocumentError = NotFoundError;

export type RenameDocumentInput = {
  documentId: Scalars['Int']['input'];
  fileName: Scalars['String']['input'];
  projectId: Scalars['Int']['input'];
};

export type RenameDocumentPayload = {
  __typename?: 'RenameDocumentPayload';
  document?: Maybe<Document>;
  errors?: Maybe<Array<RenameDocumentError>>;
};

export type RenameFolderError = ValidationResultError;

export type RenameFolderInput = {
  folderId: Scalars['Int']['input'];
  newName: Scalars['String']['input'];
  projectId: Scalars['Int']['input'];
};

export type RenameFolderPayload = {
  __typename?: 'RenameFolderPayload';
  errors?: Maybe<Array<RenameFolderError>>;
  folder?: Maybe<Folder>;
};

export type RenewAccessTokenFromExpiredTokenError = NotFoundError | ValidationResultError;

export type RenewAccessTokenFromExpiredTokenInput = {
  token: Scalars['String']['input'];
};

export type RenewAccessTokenFromExpiredTokenPayload = {
  __typename?: 'RenewAccessTokenFromExpiredTokenPayload';
  errors?: Maybe<Array<RenewAccessTokenFromExpiredTokenError>>;
  renewAccessTokenFromExpiredTokenResult?: Maybe<RenewAccessTokenFromExpiredTokenResult>;
};

export type RenewAccessTokenFromExpiredTokenResult = {
  __typename?: 'RenewAccessTokenFromExpiredTokenResult';
  sentToEmail: Scalars['String']['output'];
};

export type ReorderFormSectionsError = NotFoundError | ValidationResultError;

export type ReorderFormSectionsInput = {
  formDefinitionId: Scalars['Int']['input'];
  sectionIdsInOrder: Array<Scalars['Int']['input']>;
};

export type ReorderFormSectionsPayload = {
  __typename?: 'ReorderFormSectionsPayload';
  errors?: Maybe<Array<ReorderFormSectionsError>>;
  formDefinition?: Maybe<FormDefinition>;
};

export type ReparentTaskError = NotFoundError;

export type ReparentTaskInput = {
  parentTaskId?: InputMaybe<Scalars['Int']['input']>;
  projectId: Scalars['Int']['input'];
  taskId: Scalars['Int']['input'];
};

export type ReparentTaskPayload = {
  __typename?: 'ReparentTaskPayload';
  errors?: Maybe<Array<ReparentTaskError>>;
  projectTask?: Maybe<ProjectTask>;
};

export type RequestFormSubmissionAnonymousError = AccessTokenExpiredError | AccessTokenInvalidError | NotFoundError | ValidationResultError;

export type RequestFormSubmissionAnonymousInput = {
  token: Scalars['String']['input'];
};

export type RequestFormSubmissionAnonymousPayload = {
  __typename?: 'RequestFormSubmissionAnonymousPayload';
  errors?: Maybe<Array<RequestFormSubmissionAnonymousError>>;
  projectFormSubmission?: Maybe<ProjectFormSubmission>;
};

export type RequestFormSubmissionError = NotFoundError | ValidationResultError;

export type RequestFormSubmissionInput = {
  formId: Scalars['Int']['input'];
};

export type RequestFormSubmissionPayload = {
  __typename?: 'RequestFormSubmissionPayload';
  errors?: Maybe<Array<RequestFormSubmissionError>>;
  projectFormSubmission?: Maybe<ProjectFormSubmission>;
};

export type RescheduleTaskError = NotFoundError | ValidationResultError;

export type RescheduleTaskInput = {
  dueDate: Scalars['DateTime']['input'];
  projectId: Scalars['Int']['input'];
  startDate: Scalars['DateTime']['input'];
  taskId: Scalars['Int']['input'];
};

export type RescheduleTaskPayload = {
  __typename?: 'RescheduleTaskPayload';
  errors?: Maybe<Array<RescheduleTaskError>>;
  projectTask?: Maybe<ProjectTask>;
};

export type ResendFormInvitationError = NotFoundError | ValidationResultError;

export type ResendFormInvitationInput = {
  formId: Scalars['Int']['input'];
  projectId: Scalars['Int']['input'];
  recipientIds: Array<Scalars['String']['input']>;
};

export type ResendFormInvitationPayload = {
  __typename?: 'ResendFormInvitationPayload';
  errors?: Maybe<Array<ResendFormInvitationError>>;
  projectForm?: Maybe<ProjectForm>;
};

export type ReshareOrganizationResourceError = NotFoundError | ValidationResultError;

export type ReshareOrganizationResourceInput = {
  message?: InputMaybe<Scalars['String']['input']>;
  principalId: Scalars['String']['input'];
  principalType: PrincipalType;
  resourceId: Scalars['Int']['input'];
  resourceType: ShareableResourceType;
};

export type ReshareOrganizationResourcePayload = {
  __typename?: 'ReshareOrganizationResourcePayload';
  errors?: Maybe<Array<ReshareOrganizationResourceError>>;
  sendSharingNotificationResponse?: Maybe<SendSharingNotificationResponse>;
};

export type ReshareProjectResourceError = NotFoundError | ValidationResultError;

export type ReshareProjectResourceInput = {
  message?: InputMaybe<Scalars['String']['input']>;
  principalId: Scalars['String']['input'];
  principalType: PrincipalType;
  projectId: Scalars['Int']['input'];
  resourceId: Scalars['Int']['input'];
  resourceType: ShareableResourceType;
};

export type ReshareProjectResourcePayload = {
  __typename?: 'ReshareProjectResourcePayload';
  errors?: Maybe<Array<ReshareProjectResourceError>>;
  sendSharingNotificationResponse?: Maybe<SendSharingNotificationResponse>;
};

export type RoleAssignment = {
  __typename?: 'RoleAssignment';
  applicationRole?: Maybe<ApplicationRole>;
  applicationRoleId: Scalars['String']['output'];
  id: Scalars['String']['output'];
  inheritedFromFolder?: Maybe<Folder>;
  inheritedFromFolderId?: Maybe<Scalars['Int']['output']>;
  isFromFillFormTask: Scalars['Boolean']['output'];
  isFromSignDocumentTask: Scalars['Boolean']['output'];
  isInherited: Scalars['Boolean']['output'];
  isSharedWithPublicLink: Scalars['Boolean']['output'];
  principalType: PrincipalType;
  securityGroup?: Maybe<SecurityGroup>;
  securityGroupId?: Maybe<Scalars['String']['output']>;
  sharedWithAllProjectMembers: Scalars['Boolean']['output'];
  user?: Maybe<ApplicationUser>;
  userId?: Maybe<Scalars['String']['output']>;
};

export type RoleAssignmentFilterInput = {
  and?: InputMaybe<Array<RoleAssignmentFilterInput>>;
  applicationRole?: InputMaybe<ApplicationRoleFilterInput>;
  applicationRoleId?: InputMaybe<StringOperationFilterInput>;
  appliesToDocument?: InputMaybe<DocumentFilterInput>;
  appliesToDocumentId?: InputMaybe<IntOperationFilterInput>;
  appliesToFolder?: InputMaybe<FolderFilterInput>;
  appliesToFolderId?: InputMaybe<IntOperationFilterInput>;
  appliesToTask?: InputMaybe<ProjectTaskFilterInput>;
  appliesToTaskId?: InputMaybe<IntOperationFilterInput>;
  externalLink?: InputMaybe<ExternalLinkFilterInput>;
  externalLinkId?: InputMaybe<IntOperationFilterInput>;
  formTemplate?: InputMaybe<FormTemplateFilterInput>;
  formTemplateId?: InputMaybe<IntOperationFilterInput>;
  id?: InputMaybe<StringOperationFilterInput>;
  inheritedFromFolder?: InputMaybe<FolderFilterInput>;
  inheritedFromFolderId?: InputMaybe<IntOperationFilterInput>;
  isFromFillFormTask?: InputMaybe<BooleanOperationFilterInput>;
  isFromSignDocumentTask?: InputMaybe<BooleanOperationFilterInput>;
  isInherited?: InputMaybe<BooleanOperationFilterInput>;
  isSharedWithPublicLink?: InputMaybe<BooleanOperationFilterInput>;
  or?: InputMaybe<Array<RoleAssignmentFilterInput>>;
  principalType?: InputMaybe<PrincipalTypeOperationFilterInput>;
  securityGroup?: InputMaybe<SecurityGroupFilterInput>;
  securityGroupId?: InputMaybe<StringOperationFilterInput>;
  sharedWithAllProjectMembers?: InputMaybe<BooleanOperationFilterInput>;
  user?: InputMaybe<ApplicationUserFilterInput>;
  userId?: InputMaybe<StringOperationFilterInput>;
  workflowDefinition?: InputMaybe<WorkflowDefinitionFilterInput>;
  workflowDefinitionId?: InputMaybe<IntOperationFilterInput>;
};

export type SecurityGroup = {
  __typename?: 'SecurityGroup';
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  isDefaultProjectAdminGroup: Scalars['Boolean']['output'];
  members: Array<ApplicationUser>;
  name: Scalars['String']['output'];
  /** Depends on Roles being loaded */
  permissions: PermissionsGrouping;
  project?: Maybe<Project>;
  projectId?: Maybe<Scalars['Int']['output']>;
  roles: Array<ApplicationRole>;
};


export type SecurityGroupMembersArgs = {
  where?: InputMaybe<ApplicationUserFilterInput>;
};


export type SecurityGroupRolesArgs = {
  where?: InputMaybe<ApplicationRoleFilterInput>;
};

export type SecurityGroupFilterInput = {
  and?: InputMaybe<Array<SecurityGroupFilterInput>>;
  description?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<StringOperationFilterInput>;
  isDefaultProjectAdminGroup?: InputMaybe<BooleanOperationFilterInput>;
  members?: InputMaybe<ListFilterInputTypeOfApplicationUserFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<SecurityGroupFilterInput>>;
  project?: InputMaybe<ProjectFilterInput>;
  projectId?: InputMaybe<IntOperationFilterInput>;
  roles?: InputMaybe<ListFilterInputTypeOfApplicationRoleFilterInput>;
};

export type SelectionFieldOption = {
  __typename?: 'SelectionFieldOption';
  formField?: Maybe<FormField>;
  formFieldId: Scalars['Int']['output'];
  formValues: Array<FormValue>;
  id: Scalars['Int']['output'];
  label: Scalars['String']['output'];
};

export type SelectionFieldOptionFilterInput = {
  and?: InputMaybe<Array<SelectionFieldOptionFilterInput>>;
  formField?: InputMaybe<FormFieldFilterInput>;
  formFieldId?: InputMaybe<IntOperationFilterInput>;
  formValues?: InputMaybe<ListFilterInputTypeOfFormValueFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  label?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<SelectionFieldOptionFilterInput>>;
};

export type SelectionFieldOptionInput = {
  label: Scalars['String']['input'];
};

export type SendSharingNotificationResponse = {
  __typename?: 'SendSharingNotificationResponse';
  wasNotificationSent: Scalars['Boolean']['output'];
};

export type SendSharingTemplateNotificationError = NotFoundError | ValidationResultError;

export type SendSharingTemplateNotificationInput = {
  message?: InputMaybe<Scalars['String']['input']>;
  principalId: Scalars['String']['input'];
  principalType: PrincipalType;
  resourceId: Scalars['Int']['input'];
  resourceType: ShareableResourceType;
};

export type SendSharingTemplateNotificationPayload = {
  __typename?: 'SendSharingTemplateNotificationPayload';
  errors?: Maybe<Array<SendSharingTemplateNotificationError>>;
  sendSharingNotificationResponse?: Maybe<SendSharingNotificationResponse>;
};

export type ShareOrganizationResourceError = NotFoundError | ValidationResultError;

export type ShareOrganizationResourceInput = {
  notificationMessage?: InputMaybe<Scalars['String']['input']>;
  principalId: Scalars['String']['input'];
  resourceId: Scalars['Int']['input'];
  resourceType: ShareableResourceType;
  sendNotification: Scalars['Boolean']['input'];
};

export type ShareOrganizationResourcePayload = {
  __typename?: 'ShareOrganizationResourcePayload';
  errors?: Maybe<Array<ShareOrganizationResourceError>>;
  roleAssignment?: Maybe<RoleAssignment>;
};

export type ShareProjectResourceError = NotFoundError | ValidationResultError;

export type ShareProjectResourceInput = {
  notificationMessage?: InputMaybe<Scalars['String']['input']>;
  principalId: Scalars['String']['input'];
  principalType: PrincipalType;
  projectId: Scalars['Int']['input'];
  resourceId: Scalars['Int']['input'];
  resourceType: ShareableResourceType;
  sendNotification: Scalars['Boolean']['input'];
};

export type ShareProjectResourcePayload = {
  __typename?: 'ShareProjectResourcePayload';
  errors?: Maybe<Array<ShareProjectResourceError>>;
  roleAssignment?: Maybe<RoleAssignment>;
};

export enum ShareableResourceType {
  Document = 'Document',
  ExternalLink = 'ExternalLink',
  Folder = 'Folder',
  Form = 'Form',
  FormTemplate = 'FormTemplate',
  Task = 'Task',
  WorkflowDefinition = 'WorkflowDefinition'
}

export type SignDocumentAnonymouslyError = AccessTokenExpiredError | AccessTokenInvalidError | NotFoundError | ValidationResultError;

export type SignDocumentAnonymouslyInput = {
  signatureBase64String?: InputMaybe<Scalars['String']['input']>;
  textSignature?: InputMaybe<Scalars['String']['input']>;
  token: Scalars['String']['input'];
};

export type SignDocumentAnonymouslyPayload = {
  __typename?: 'SignDocumentAnonymouslyPayload';
  document?: Maybe<Document>;
  errors?: Maybe<Array<SignDocumentAnonymouslyError>>;
};

export type SignDocumentError = NotFoundError | ValidationResultError;

export type SignDocumentInput = {
  documentId: Scalars['Int']['input'];
  projectId: Scalars['Int']['input'];
  signatureBase64String?: InputMaybe<Scalars['String']['input']>;
  textSignature?: InputMaybe<Scalars['String']['input']>;
};

export type SignDocumentPayload = {
  __typename?: 'SignDocumentPayload';
  document?: Maybe<Document>;
  errors?: Maybe<Array<SignDocumentError>>;
};

export enum SortEnumType {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type StartTrialError = DuplicateNameError | MissingConfigurationError | ValidationResultError;

export type StartTrialInput = {
  adminInput: TenantAdminInput;
  referralId?: InputMaybe<Scalars['String']['input']>;
  tenantName: Scalars['String']['input'];
};

export type StartTrialPayload = {
  __typename?: 'StartTrialPayload';
  errors?: Maybe<Array<StartTrialError>>;
  tenant?: Maybe<Tenant>;
};

export type StartWorkflowInstanceError = NotFoundError | ValidationResultError;

export type StartWorkflowInstanceInput = {
  projectId: Scalars['Int']['input'];
  workflowDefinitionId: Scalars['Int']['input'];
  workflowVariables: Array<WorkflowVariableInput>;
};

export type StartWorkflowInstancePayload = {
  __typename?: 'StartWorkflowInstancePayload';
  errors?: Maybe<Array<StartWorkflowInstanceError>>;
  workflowInstance?: Maybe<WorkflowInstance>;
};

export type StringOperationFilterInput = {
  and?: InputMaybe<Array<StringOperationFilterInput>>;
  contains?: InputMaybe<Scalars['String']['input']>;
  endsWith?: InputMaybe<Scalars['String']['input']>;
  eq?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  ncontains?: InputMaybe<Scalars['String']['input']>;
  nendsWith?: InputMaybe<Scalars['String']['input']>;
  neq?: InputMaybe<Scalars['String']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  nstartsWith?: InputMaybe<Scalars['String']['input']>;
  or?: InputMaybe<Array<StringOperationFilterInput>>;
  startsWith?: InputMaybe<Scalars['String']['input']>;
};

export enum SubmissionStatus {
  /** @deprecated No longer supported. */
  Closed = 'Closed',
  IsResponding = 'IsResponding',
  Submitted = 'Submitted'
}

export type SubmissionStatusOperationFilterInput = {
  eq?: InputMaybe<SubmissionStatus>;
  in?: InputMaybe<Array<SubmissionStatus>>;
  neq?: InputMaybe<SubmissionStatus>;
  nin?: InputMaybe<Array<SubmissionStatus>>;
};

export type SubmitWorkflowActionError = NotFoundError | ValidationResultError | WorkflowExecutionError;

export type SubmitWorkflowActionInput = {
  jsonPayload?: InputMaybe<Scalars['String']['input']>;
  workflowActionId: Scalars['Int']['input'];
  workflowInstanceId: Scalars['Int']['input'];
};

export type SubmitWorkflowActionPayload = {
  __typename?: 'SubmitWorkflowActionPayload';
  errors?: Maybe<Array<SubmitWorkflowActionError>>;
  workflowActionConfiguration?: Maybe<WorkflowActionConfiguration>;
};

export type Subscription = {
  __typename?: 'Subscription';
  formPresenceUpdated: Array<ProjectFormPresence>;
  formValueEdited: FormValueEditedEvent;
  matrixRowEvent: MatrixRowEvent;
  matrixValueEdited: MatrixValueEditedEvent;
  provisioningStatusChanged: ProvisioningStatus;
};


export type SubscriptionFormPresenceUpdatedArgs = {
  accessToken?: InputMaybe<Scalars['String']['input']>;
  submissionId: Scalars['Int']['input'];
};


export type SubscriptionFormValueEditedArgs = {
  accessToken?: InputMaybe<Scalars['String']['input']>;
  submissionId: Scalars['Int']['input'];
};


export type SubscriptionMatrixRowEventArgs = {
  accessToken?: InputMaybe<Scalars['String']['input']>;
  submissionId: Scalars['Int']['input'];
};


export type SubscriptionMatrixValueEditedArgs = {
  accessToken?: InputMaybe<Scalars['String']['input']>;
  submissionId: Scalars['Int']['input'];
};


export type SubscriptionProvisioningStatusChangedArgs = {
  tenantId: Scalars['String']['input'];
};

export enum SubscriptionStatus {
  Active = 'Active',
  Canceled = 'Canceled',
  InTrial = 'InTrial',
  Incomplete = 'Incomplete',
  Paused = 'Paused',
  Unpaid = 'Unpaid'
}

export enum TasksPermission {
  LimitedAccess = 'LimitedAccess',
  Manage = 'Manage',
  ViewAll = 'ViewAll'
}

export type Tenant = {
  __typename?: 'Tenant';
  appSubscription?: Maybe<AppSubscription>;
  connectionString?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  identifier?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  provisioningStatus: ProvisioningStatus;
  version: Array<Scalars['Byte']['output']>;
};

export type TenantAdminInput = {
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  language: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
};

export type TenantFromAnonymousLink = {
  __typename?: 'TenantFromAnonymousLink';
  name: Scalars['String']['output'];
};

export type TenantInput = {
  identifier: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type TenantSettings = {
  __typename?: 'TenantSettings';
  id: Scalars['Int']['output'];
  isConstructionAddonEnabled: Scalars['Boolean']['output'];
  isSignDocumentByZohoEnabled: Scalars['Boolean']['output'];
  isWhiteLabelEnabled: Scalars['Boolean']['output'];
  isWorkflowsAddonEnabled: Scalars['Boolean']['output'];
  isWorkflowsEnabled: Scalars['Boolean']['output'];
  primaryColor?: Maybe<Scalars['String']['output']>;
  remainingZohoSignatureRequests: Scalars['Int']['output'];
  secondaryColor?: Maybe<Scalars['String']['output']>;
};

export type TenantTheme = {
  __typename?: 'TenantTheme';
  faviconUrl?: Maybe<Scalars['String']['output']>;
  isBrandingEnabled: Scalars['Boolean']['output'];
  loginBackgroundUrl?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  primaryColor?: Maybe<Scalars['String']['output']>;
  secondaryColor?: Maybe<Scalars['String']['output']>;
  squareLogoDarkUrl?: Maybe<Scalars['String']['output']>;
  squareLogoLightUrl?: Maybe<Scalars['String']['output']>;
  topBarLogoDarkUrl?: Maybe<Scalars['String']['output']>;
  topBarLogoLightUrl?: Maybe<Scalars['String']['output']>;
};

export type TimeSpanOperationFilterInput = {
  eq?: InputMaybe<Scalars['TimeSpan']['input']>;
  gt?: InputMaybe<Scalars['TimeSpan']['input']>;
  gte?: InputMaybe<Scalars['TimeSpan']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['TimeSpan']['input']>>>;
  lt?: InputMaybe<Scalars['TimeSpan']['input']>;
  lte?: InputMaybe<Scalars['TimeSpan']['input']>;
  neq?: InputMaybe<Scalars['TimeSpan']['input']>;
  ngt?: InputMaybe<Scalars['TimeSpan']['input']>;
  ngte?: InputMaybe<Scalars['TimeSpan']['input']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['TimeSpan']['input']>>>;
  nlt?: InputMaybe<Scalars['TimeSpan']['input']>;
  nlte?: InputMaybe<Scalars['TimeSpan']['input']>;
};

export type UnpublishDocumentError = NotFoundError;

export type UnpublishDocumentInput = {
  documentId: Scalars['Int']['input'];
  projectId: Scalars['Int']['input'];
};

export type UnpublishDocumentPayload = {
  __typename?: 'UnpublishDocumentPayload';
  document?: Maybe<Document>;
  errors?: Maybe<Array<UnpublishDocumentError>>;
};

export type UnpublishFormError = ValidationResultError;

export type UnpublishFormInput = {
  projectFormId: Scalars['Int']['input'];
  projectId: Scalars['Int']['input'];
};

export type UnpublishFormPayload = {
  __typename?: 'UnpublishFormPayload';
  errors?: Maybe<Array<UnpublishFormError>>;
  projectForm?: Maybe<ProjectForm>;
};

export type UnshareOrganizationResourceError = NotFoundError | ValidationResultError;

export type UnshareOrganizationResourceInput = {
  roleAssignmentId: Scalars['String']['input'];
};

export type UnshareOrganizationResourcePayload = {
  __typename?: 'UnshareOrganizationResourcePayload';
  errors?: Maybe<Array<UnshareOrganizationResourceError>>;
  roleAssignment?: Maybe<RoleAssignment>;
};

export type UnshareProjectResourceError = NotFoundError | ValidationResultError;

export type UnshareProjectResourceInput = {
  projectId: Scalars['Int']['input'];
  roleAssignmentId: Scalars['String']['input'];
};

export type UnshareProjectResourcePayload = {
  __typename?: 'UnshareProjectResourcePayload';
  errors?: Maybe<Array<UnshareProjectResourceError>>;
  roleAssignment?: Maybe<RoleAssignment>;
};

export type UnsubscribeFromEmailsError = AccessTokenInvalidError;

export type UnsubscribeFromEmailsInput = {
  token: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};

export type UnsubscribeFromEmailsPayload = {
  __typename?: 'UnsubscribeFromEmailsPayload';
  errors?: Maybe<Array<UnsubscribeFromEmailsError>>;
  unsubscribeFromEmailsResponse?: Maybe<UnsubscribeFromEmailsResponse>;
};

export type UnsubscribeFromEmailsResponse = {
  __typename?: 'UnsubscribeFromEmailsResponse';
  wasUnsubscribed: Scalars['Boolean']['output'];
};

export type UpdateBrandingConfigurationError = NotFoundError | ValidationResultError;

export type UpdateBrandingConfigurationInput = {
  isWhiteLabelEnabled: Scalars['Boolean']['input'];
  primaryColor: Scalars['String']['input'];
  secondaryColor: Scalars['String']['input'];
};

export type UpdateBrandingConfigurationPayload = {
  __typename?: 'UpdateBrandingConfigurationPayload';
  errors?: Maybe<Array<UpdateBrandingConfigurationError>>;
  tenantSettings?: Maybe<TenantSettings>;
};

export type UpdateCommercialProjectCalculatorError = NotFoundError | ValidationResultError;

export type UpdateCommercialProjectCalculatorInput = {
  id: Scalars['Int']['input'];
  jsonData: Scalars['String']['input'];
  name: Scalars['String']['input'];
  projectId: Scalars['Int']['input'];
};

export type UpdateCommercialProjectCalculatorPayload = {
  __typename?: 'UpdateCommercialProjectCalculatorPayload';
  commercialProjectCalculator?: Maybe<CommercialProjectCalculator>;
  errors?: Maybe<Array<UpdateCommercialProjectCalculatorError>>;
};

export type UpdateFormSettingsError = NotFoundError | ValidationResultError;

export type UpdateFormSettingsInput = {
  allowedRespondents: AllowedRespondents;
  allowedSecurityGroupsRespondents: Array<Scalars['String']['input']>;
  allowedUsersRespondents: Array<Scalars['String']['input']>;
  projectFormId: Scalars['Int']['input'];
  projectId: Scalars['Int']['input'];
};

export type UpdateFormSettingsPayload = {
  __typename?: 'UpdateFormSettingsPayload';
  errors?: Maybe<Array<UpdateFormSettingsError>>;
  formSettings?: Maybe<FormSettings>;
};

export type UpdateMyPreferencesError = NotFoundError;

export type UpdateMyPreferencesInput = {
  optOutOfWeeklyEmails: Scalars['Boolean']['input'];
  preferredLanguage: Scalars['String']['input'];
};

export type UpdateMyPreferencesPayload = {
  __typename?: 'UpdateMyPreferencesPayload';
  applicationUser?: Maybe<ApplicationUser>;
  errors?: Maybe<Array<UpdateMyPreferencesError>>;
};

export type UpdatePresenceError = NotFoundError | ValidationResultError;

export type UpdatePresenceInput = {
  fieldId?: InputMaybe<Scalars['Int']['input']>;
  matrixColumnId?: InputMaybe<Scalars['Int']['input']>;
  matrixRowId?: InputMaybe<Scalars['Int']['input']>;
  submissionId: Scalars['Int']['input'];
  uniqueClientId: Scalars['String']['input'];
};

export type UpdatePresencePatError = AccessTokenExpiredError | AccessTokenInvalidError | NotFoundError | ValidationResultError;

export type UpdatePresencePatInput = {
  accessToken: Scalars['String']['input'];
  fieldId?: InputMaybe<Scalars['Int']['input']>;
  matrixColumnId?: InputMaybe<Scalars['Int']['input']>;
  matrixRowId?: InputMaybe<Scalars['Int']['input']>;
  submissionId: Scalars['Int']['input'];
  uniqueClientId: Scalars['String']['input'];
};

export type UpdatePresencePatPayload = {
  __typename?: 'UpdatePresencePATPayload';
  errors?: Maybe<Array<UpdatePresencePatError>>;
  projectFormPresence?: Maybe<ProjectFormPresence>;
};

export type UpdatePresencePayload = {
  __typename?: 'UpdatePresencePayload';
  errors?: Maybe<Array<UpdatePresenceError>>;
  projectFormPresence?: Maybe<ProjectFormPresence>;
};

export type UpdateSecurityGroupError = NotFoundError | ValidationResultError;

export type UpdateSecurityGroupInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  groupId: Scalars['String']['input'];
  name: Scalars['String']['input'];
  projectId: Scalars['Int']['input'];
};

export type UpdateSecurityGroupPayload = {
  __typename?: 'UpdateSecurityGroupPayload';
  errors?: Maybe<Array<UpdateSecurityGroupError>>;
  securityGroup?: Maybe<SecurityGroup>;
};

export type UpdateSecurityGroupRolesError = NotFoundError | ValidationResultError;

export type UpdateSecurityGroupRolesInput = {
  budgetPermission: BudgetAndExpensesPermission;
  documentsPermission: DocumentsPermission;
  formsPermission: FormsPermission;
  projectId: Scalars['Int']['input'];
  securityGroupId: Scalars['String']['input'];
  tasksPermission: TasksPermission;
  workflowsPermission: WorkflowsPermission;
};

export type UpdateSecurityGroupRolesPayload = {
  __typename?: 'UpdateSecurityGroupRolesPayload';
  errors?: Maybe<Array<UpdateSecurityGroupRolesError>>;
  securityGroup?: Maybe<SecurityGroup>;
};

export type UpdateTaskError = NotFoundError | ValidationResultError;

export type UpdateTaskInput = {
  assigneeIds: Array<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  dueDate?: InputMaybe<Scalars['DateTime']['input']>;
  name: Scalars['String']['input'];
  parentTaskId?: InputMaybe<Scalars['Int']['input']>;
  predecessorIds: Array<Scalars['Int']['input']>;
  projectId: Scalars['Int']['input'];
  startDate?: InputMaybe<Scalars['DateTime']['input']>;
  status: ProjectTaskStatus;
  taskId: Scalars['Int']['input'];
};

export type UpdateTaskPayload = {
  __typename?: 'UpdateTaskPayload';
  errors?: Maybe<Array<UpdateTaskError>>;
  projectTask?: Maybe<ProjectTask>;
};

export type UpdateTaskStatusError = NotFoundError | ValidationResultError;

export type UpdateTaskStatusInput = {
  projectId: Scalars['Int']['input'];
  status: ProjectTaskStatus;
  taskId: Scalars['Int']['input'];
};

export type UpdateTaskStatusPayload = {
  __typename?: 'UpdateTaskStatusPayload';
  errors?: Maybe<Array<UpdateTaskStatusError>>;
  projectTask?: Maybe<ProjectTask>;
};

export type UpdateWorkflowActionConfigurationError = NotFoundError | ValidationResultError;

export type UpdateWorkflowActionConfigurationInput = {
  actionName: Scalars['String']['input'];
  fieldConfigurations: Array<ActionFieldValueInput>;
  workflowActionId: Scalars['Int']['input'];
  workflowDefinitionId: Scalars['Int']['input'];
};

export type UpdateWorkflowActionConfigurationPayload = {
  __typename?: 'UpdateWorkflowActionConfigurationPayload';
  errors?: Maybe<Array<UpdateWorkflowActionConfigurationError>>;
  workflowActionConfiguration?: Maybe<WorkflowActionConfiguration>;
};

export type ValidateUnsubscribeTokenInput = {
  token: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};

export type ValidateUnsubscribeTokenResult = {
  __typename?: 'ValidateUnsubscribeTokenResult';
  isValid: Scalars['Boolean']['output'];
};

export type ValidationResultError = Error & {
  __typename?: 'ValidationResultError';
  message: Scalars['String']['output'];
};

export enum WorkFlowStatus {
  Completed = 'Completed',
  InProgress = 'InProgress',
  NotStarted = 'NotStarted'
}

export type WorkFlowStatusOperationFilterInput = {
  eq?: InputMaybe<WorkFlowStatus>;
  in?: InputMaybe<Array<WorkFlowStatus>>;
  neq?: InputMaybe<WorkFlowStatus>;
  nin?: InputMaybe<Array<WorkFlowStatus>>;
};

export type WorkflowActionConfiguration = {
  __typename?: 'WorkflowActionConfiguration';
  childActions: Array<WorkflowActionConfiguration>;
  fieldValues: Array<WorkflowActionFieldValue>;
  hierarchyPath?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  order: Scalars['Int']['output'];
  participants: Array<ApplicationUser>;
  status: WorkflowActionStatus;
  submittedBy?: Maybe<ApplicationUser>;
  submittedById?: Maybe<Scalars['String']['output']>;
  submittedOn?: Maybe<Scalars['DateTime']['output']>;
  workflowActionDefinition?: Maybe<WorkflowActionDefinition>;
  workflowActionDefinitionId: Scalars['String']['output'];
};

export type WorkflowActionConfigurationFilterInput = {
  and?: InputMaybe<Array<WorkflowActionConfigurationFilterInput>>;
  id?: InputMaybe<IntOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<WorkflowActionConfigurationFilterInput>>;
};

export type WorkflowActionConfigurationSortInput = {
  belongsToWorkflow?: InputMaybe<WorkflowInstanceSortInput>;
  belongsToWorkflowId?: InputMaybe<SortEnumType>;
  hierarchyPath?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  order?: InputMaybe<SortEnumType>;
  parentActionConfiguration?: InputMaybe<WorkflowActionConfigurationSortInput>;
  parentActionConfigurationId?: InputMaybe<SortEnumType>;
  rootOfWorkflow?: InputMaybe<WorkflowDefinitionSortInput>;
  rootOfWorkflowInstance?: InputMaybe<WorkflowInstanceSortInput>;
  status?: InputMaybe<SortEnumType>;
  submittedBy?: InputMaybe<ApplicationUserSortInput>;
  submittedById?: InputMaybe<SortEnumType>;
  submittedOn?: InputMaybe<SortEnumType>;
  workflowActionDefinition?: InputMaybe<WorkflowActionDefinitionSortInput>;
  workflowActionDefinitionId?: InputMaybe<SortEnumType>;
};

export type WorkflowActionDefinition = {
  __typename?: 'WorkflowActionDefinition';
  actionDefinitionId: Scalars['String']['output'];
  fields: Array<WorkflowActionField>;
  name: Scalars['String']['output'];
};


export type WorkflowActionDefinitionFieldsArgs = {
  order?: InputMaybe<Array<WorkflowActionFieldSortInput>>;
};

export type WorkflowActionDefinitionSortInput = {
  actionCategory?: InputMaybe<SortEnumType>;
  actionDefinitionId?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
};

export type WorkflowActionField = {
  __typename?: 'WorkflowActionField';
  description?: Maybe<Scalars['String']['output']>;
  fieldType: WorkflowFieldType;
  id: Scalars['UUID']['output'];
  name: Scalars['String']['output'];
  order: Scalars['Int']['output'];
  required: Scalars['Boolean']['output'];
  variableType: WorkflowVariableValueTypes;
};

export type WorkflowActionFieldSortInput = {
  description?: InputMaybe<SortEnumType>;
  fieldType?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  isParticipantField?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  order?: InputMaybe<SortEnumType>;
  required?: InputMaybe<SortEnumType>;
  variableType?: InputMaybe<SortEnumType>;
};

export type WorkflowActionFieldValue = {
  __typename?: 'WorkflowActionFieldValue';
  fieldId: Scalars['UUID']['output'];
  id: Scalars['Int']['output'];
  value?: Maybe<WorkflowVariable>;
  workflowActionId?: Maybe<Scalars['Int']['output']>;
};

export enum WorkflowActionStatus {
  Completed = 'Completed',
  InProgress = 'InProgress',
  NotStarted = 'NotStarted'
}

export type WorkflowDefinition = {
  __typename?: 'WorkflowDefinition';
  createdBy?: Maybe<ApplicationUser>;
  createdById: Scalars['String']['output'];
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  rootAction?: Maybe<WorkflowActionConfiguration>;
  rootActionId: Scalars['Int']['output'];
  variables: Array<WorkflowVariable>;
};

export type WorkflowDefinitionFilterInput = {
  and?: InputMaybe<Array<WorkflowDefinitionFilterInput>>;
  createdBy?: InputMaybe<ApplicationUserFilterInput>;
  createdById?: InputMaybe<StringOperationFilterInput>;
  description?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<IntOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<WorkflowDefinitionFilterInput>>;
};

export type WorkflowDefinitionSortInput = {
  createdBy?: InputMaybe<ApplicationUserSortInput>;
  createdById?: InputMaybe<SortEnumType>;
  description?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  rootAction?: InputMaybe<WorkflowActionConfigurationSortInput>;
  rootActionId?: InputMaybe<SortEnumType>;
};

export type WorkflowExecutionError = Error & {
  __typename?: 'WorkflowExecutionError';
  message: Scalars['String']['output'];
};

export enum WorkflowFieldType {
  Configuration = 'Configuration',
  InteractiveInput = 'InteractiveInput',
  OutputOnly = 'OutputOnly'
}

export type WorkflowInstance = {
  __typename?: 'WorkflowInstance';
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  projectId: Scalars['Int']['output'];
  rootAction?: Maybe<WorkflowActionConfiguration>;
  rootActionId: Scalars['Int']['output'];
  variables: Array<WorkflowVariable>;
  workFlowStatus: WorkFlowStatus;
  workflowDefinitionId?: Maybe<Scalars['Int']['output']>;
};

export type WorkflowInstanceFilterInput = {
  and?: InputMaybe<Array<WorkflowInstanceFilterInput>>;
  id?: InputMaybe<IntOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<WorkflowInstanceFilterInput>>;
  projectId?: InputMaybe<IntOperationFilterInput>;
  rootAction?: InputMaybe<WorkflowActionConfigurationFilterInput>;
  rootActionId?: InputMaybe<IntOperationFilterInput>;
  workFlowStatus?: InputMaybe<WorkFlowStatusOperationFilterInput>;
};

export type WorkflowInstanceSortInput = {
  createdBy?: InputMaybe<ApplicationUserSortInput>;
  createdById?: InputMaybe<SortEnumType>;
  id?: InputMaybe<SortEnumType>;
  isDeleted?: InputMaybe<SortEnumType>;
  name?: InputMaybe<SortEnumType>;
  project?: InputMaybe<ProjectSortInput>;
  projectId?: InputMaybe<SortEnumType>;
  rootAction?: InputMaybe<WorkflowActionConfigurationSortInput>;
  rootActionId?: InputMaybe<SortEnumType>;
  workFlowStatus?: InputMaybe<SortEnumType>;
  workflowDefinition?: InputMaybe<WorkflowDefinitionSortInput>;
  workflowDefinitionId?: InputMaybe<SortEnumType>;
};

export type WorkflowVariable = {
  __typename?: 'WorkflowVariable';
  id: Scalars['Int']['output'];
  name?: Maybe<Scalars['String']['output']>;
  serializedValue?: Maybe<Scalars['String']['output']>;
  variableType: WorkflowVariableType;
  variableValueType: WorkflowVariableValueTypes;
};

export type WorkflowVariableInput = {
  serializedValue?: InputMaybe<Scalars['String']['input']>;
  variableId: Scalars['Int']['input'];
};

export enum WorkflowVariableType {
  ManualInputVariable = 'ManualInputVariable',
  WorkflowVariable = 'WorkflowVariable'
}

export enum WorkflowVariableValueTypes {
  DateAndTime = 'DateAndTime',
  DateOnly = 'DateOnly',
  Decimal = 'Decimal',
  Document = 'Document',
  DocumentSignatureCoordinates = 'DocumentSignatureCoordinates',
  FormSubmission = 'FormSubmission',
  FormTemplate = 'FormTemplate',
  Integer = 'Integer',
  String = 'String',
  User = 'User'
}

export enum WorkflowsPermission {
  LimitedAccess = 'LimitedAccess',
  Manage = 'Manage'
}

export type ZohoSignatureCoordinatesInput = {
  page: Scalars['Int']['input'];
  x: Scalars['Int']['input'];
  y: Scalars['Int']['input'];
};

export type FormPresenceUpdatedSubscriptionVariables = Exact<{
  submissionId: Scalars['Int']['input'];
  accessToken?: InputMaybe<Scalars['String']['input']>;
}>;


export type FormPresenceUpdatedSubscription = { __typename?: 'Subscription', formPresenceUpdated: Array<{ __typename?: 'ProjectFormPresence', uniqueClientId: string, userId: string, displayName: string, fieldId?: number | null, matrixColumnId?: number | null, matrixRowId?: number | null }> };

export type FormValueEditedSubscriptionVariables = Exact<{
  submissionId: Scalars['Int']['input'];
  accessToken?: InputMaybe<Scalars['String']['input']>;
}>;


export type FormValueEditedSubscription = { __typename?: 'Subscription', formValueEdited: { __typename?: 'FormValueEditedEvent', initiatorUniqueClientId: string, formValue: { __typename?: 'FormValue', id: number, fieldId: number, text?: string | null, number?: any | null, boolean?: boolean | null, dateValue?: any | null, selectedOptions: Array<{ __typename?: 'SelectionFieldOption', id: number }>, matrixRows: Array<{ __typename?: 'MatrixRow', id: number, isDefaultRow: boolean, values: Array<{ __typename?: 'MatrixValue', id: number, matrixColumnId: number, text?: string | null, number?: any | null, boolean?: boolean | null }> }>, files: Array<{ __typename?: 'FormFileAttachment', id: number, fileName: string }> } } };

export type MatrixRowEventSubscriptionVariables = Exact<{
  accessToken?: InputMaybe<Scalars['String']['input']>;
  submissionId: Scalars['Int']['input'];
}>;


export type MatrixRowEventSubscription = { __typename?: 'Subscription', matrixRowEvent: { __typename?: 'MatrixRowEvent', id: number, initiatorUniqueClientId: string, eventType: MatrixRowEventType } };

export type MatrixValueEditedSubscriptionVariables = Exact<{
  accessToken?: InputMaybe<Scalars['String']['input']>;
  submissionId: Scalars['Int']['input'];
}>;


export type MatrixValueEditedSubscription = { __typename?: 'Subscription', matrixValueEdited: { __typename?: 'MatrixValueEditedEvent', initiatorUniqueClientId: string, matrixValue: { __typename?: 'MatrixValue', id: number, matrixRowId: number, matrixColumnId: number, text?: string | null, number?: any | null, boolean?: boolean | null } } };

export type ProvisioningStatusChangedSubscriptionVariables = Exact<{
  tenantId: Scalars['String']['input'];
}>;


export type ProvisioningStatusChangedSubscription = { __typename?: 'Subscription', provisioningStatusChanged: ProvisioningStatus };


export const FormPresenceUpdatedDocument = gql`
    subscription formPresenceUpdated($submissionId: Int!, $accessToken: String) {
  formPresenceUpdated(submissionId: $submissionId, accessToken: $accessToken) {
    uniqueClientId
    userId
    displayName
    fieldId
    matrixColumnId
    matrixRowId
  }
}
    `;

/**
 * __useFormPresenceUpdatedSubscription__
 *
 * To run a query within a React component, call `useFormPresenceUpdatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useFormPresenceUpdatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFormPresenceUpdatedSubscription({
 *   variables: {
 *      submissionId: // value for 'submissionId'
 *      accessToken: // value for 'accessToken'
 *   },
 * });
 */
export function useFormPresenceUpdatedSubscription(baseOptions: Apollo.SubscriptionHookOptions<FormPresenceUpdatedSubscription, FormPresenceUpdatedSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<FormPresenceUpdatedSubscription, FormPresenceUpdatedSubscriptionVariables>(FormPresenceUpdatedDocument, options);
      }
export type FormPresenceUpdatedSubscriptionHookResult = ReturnType<typeof useFormPresenceUpdatedSubscription>;
export type FormPresenceUpdatedSubscriptionResult = Apollo.SubscriptionResult<FormPresenceUpdatedSubscription>;
export const FormValueEditedDocument = gql`
    subscription formValueEdited($submissionId: Int!, $accessToken: String) {
  formValueEdited(submissionId: $submissionId, accessToken: $accessToken) {
    initiatorUniqueClientId
    formValue {
      id
      fieldId
      text
      number
      boolean
      dateValue
      selectedOptions {
        id
      }
      matrixRows {
        id
        isDefaultRow
        values {
          id
          matrixColumnId
          text
          number
          boolean
        }
      }
      files {
        id
        fileName
      }
    }
  }
}
    `;

/**
 * __useFormValueEditedSubscription__
 *
 * To run a query within a React component, call `useFormValueEditedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useFormValueEditedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFormValueEditedSubscription({
 *   variables: {
 *      submissionId: // value for 'submissionId'
 *      accessToken: // value for 'accessToken'
 *   },
 * });
 */
export function useFormValueEditedSubscription(baseOptions: Apollo.SubscriptionHookOptions<FormValueEditedSubscription, FormValueEditedSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<FormValueEditedSubscription, FormValueEditedSubscriptionVariables>(FormValueEditedDocument, options);
      }
export type FormValueEditedSubscriptionHookResult = ReturnType<typeof useFormValueEditedSubscription>;
export type FormValueEditedSubscriptionResult = Apollo.SubscriptionResult<FormValueEditedSubscription>;
export const MatrixRowEventDocument = gql`
    subscription matrixRowEvent($accessToken: String, $submissionId: Int!) {
  matrixRowEvent(accessToken: $accessToken, submissionId: $submissionId) {
    id
    initiatorUniqueClientId
    eventType
  }
}
    `;

/**
 * __useMatrixRowEventSubscription__
 *
 * To run a query within a React component, call `useMatrixRowEventSubscription` and pass it any options that fit your needs.
 * When your component renders, `useMatrixRowEventSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMatrixRowEventSubscription({
 *   variables: {
 *      accessToken: // value for 'accessToken'
 *      submissionId: // value for 'submissionId'
 *   },
 * });
 */
export function useMatrixRowEventSubscription(baseOptions: Apollo.SubscriptionHookOptions<MatrixRowEventSubscription, MatrixRowEventSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<MatrixRowEventSubscription, MatrixRowEventSubscriptionVariables>(MatrixRowEventDocument, options);
      }
export type MatrixRowEventSubscriptionHookResult = ReturnType<typeof useMatrixRowEventSubscription>;
export type MatrixRowEventSubscriptionResult = Apollo.SubscriptionResult<MatrixRowEventSubscription>;
export const MatrixValueEditedDocument = gql`
    subscription matrixValueEdited($accessToken: String, $submissionId: Int!) {
  matrixValueEdited(accessToken: $accessToken, submissionId: $submissionId) {
    initiatorUniqueClientId
    matrixValue {
      id
      matrixRowId
      matrixColumnId
      text
      number
      boolean
    }
  }
}
    `;

/**
 * __useMatrixValueEditedSubscription__
 *
 * To run a query within a React component, call `useMatrixValueEditedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useMatrixValueEditedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMatrixValueEditedSubscription({
 *   variables: {
 *      accessToken: // value for 'accessToken'
 *      submissionId: // value for 'submissionId'
 *   },
 * });
 */
export function useMatrixValueEditedSubscription(baseOptions: Apollo.SubscriptionHookOptions<MatrixValueEditedSubscription, MatrixValueEditedSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<MatrixValueEditedSubscription, MatrixValueEditedSubscriptionVariables>(MatrixValueEditedDocument, options);
      }
export type MatrixValueEditedSubscriptionHookResult = ReturnType<typeof useMatrixValueEditedSubscription>;
export type MatrixValueEditedSubscriptionResult = Apollo.SubscriptionResult<MatrixValueEditedSubscription>;
export const ProvisioningStatusChangedDocument = gql`
    subscription provisioningStatusChanged($tenantId: String!) {
  provisioningStatusChanged(tenantId: $tenantId)
}
    `;

/**
 * __useProvisioningStatusChangedSubscription__
 *
 * To run a query within a React component, call `useProvisioningStatusChangedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useProvisioningStatusChangedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProvisioningStatusChangedSubscription({
 *   variables: {
 *      tenantId: // value for 'tenantId'
 *   },
 * });
 */
export function useProvisioningStatusChangedSubscription(baseOptions: Apollo.SubscriptionHookOptions<ProvisioningStatusChangedSubscription, ProvisioningStatusChangedSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<ProvisioningStatusChangedSubscription, ProvisioningStatusChangedSubscriptionVariables>(ProvisioningStatusChangedDocument, options);
      }
export type ProvisioningStatusChangedSubscriptionHookResult = ReturnType<typeof useProvisioningStatusChangedSubscription>;
export type ProvisioningStatusChangedSubscriptionResult = Apollo.SubscriptionResult<ProvisioningStatusChangedSubscription>;
import { EditorSavingStatus, SavingStatusContext } from '@components/Editor/TopBar/SavingStatusContext';
import { useEditorHelpers } from '@components/Editor/TopBar/useEditorHelpers';
import { Droppable } from '@hello-pangea/dnd';
import { DroppableEmptyState } from '@modules/forms/components/FormEditor/DroppableEmptyState';
import { Stack, alpha } from '@mui/material';
import { WorkflowActionStatus, useAddChildActionMutation, useMoveChildActionMutation } from 'gql/index';
import { useContext, useEffect } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { getBorderColor } from '../../../../../components/Utils/DroppableComponentUtils';
import { ActionNameByType } from '../../WorflowInstance/Actions/Types';
import { AllActionIds } from '../Actions/types';
import { WorkflowEditorContext } from '../WorkflowEditorContext';
import { useChildActionAddedListener, useChildActionMovedListener } from '../events';
import { WorkflowEditorActionValue, WorkflowEditorDroppableIds, WorkflowEditorDroppableTypes, WorkflowEditorValues } from '../types';
import { mapActionFragmentToForm } from '../utils';
import { ActionTile } from './ActionTile';


export const DroppableWorkflowAction: React.FC = () => {
  const { formatMessage } = useIntl();

  const { control } = useFormContext<WorkflowEditorValues>();
  const { actionDefinitions, setSelectedActionIndex, workflowDefinition: workflowTemplate } = useContext(WorkflowEditorContext);
  const { setEditorSavingStatus, editorSavingStatus } = useContext(SavingStatusContext);
  const { onEditionError, onEditionSuccess } = useEditorHelpers();

  const { fields: actions, insert, move, update, remove } = useFieldArray({ control, name: 'actions', keyName: 'uniqueId' });

  const { mutate: addChildAction, isLoading: isAddingChildAction } = useAddChildActionMutation({ onError: onEditionError, onSuccess: onEditionSuccess });
  const { mutate: moveChildAction, isLoading: isMovingChildAction } = useMoveChildActionMutation({ onError: onEditionError, onSuccess: onEditionSuccess });

  useEffect(() => {
    if ((isAddingChildAction || isMovingChildAction) && editorSavingStatus !== EditorSavingStatus.Saving) {
      setEditorSavingStatus(EditorSavingStatus.Saving);
    }
  }, [editorSavingStatus, isAddingChildAction, isMovingChildAction, setEditorSavingStatus]);

  useChildActionAddedListener(({ createdAtIndex, actionDefinitionId }) => {
    const actionDefinition = actionDefinitions.find(p => p.actionDefinitionId === actionDefinitionId);

    const placeholderAction: WorkflowEditorActionValue = {
      id: -1,
      workflowActionDefinitionId: actionDefinitionId,
      name: actionDefinition?.name ?? '',
      fieldValues: [],
      order: createdAtIndex,
      status: WorkflowActionStatus.NotStarted
    };

    insert(createdAtIndex, placeholderAction);

    // until we allow more complex flow actions, all child actions go under the root Sequence Action
    const rootSequenceActionId = workflowTemplate.rootAction?.id ?? -1;

    addChildAction({
      input: {
        actionConfigurationId: rootSequenceActionId,
        actionDefinitionId: actionDefinitionId,
        orderIndex: createdAtIndex,
        workflowDefinitionId: workflowTemplate.id,
        defaultName: formatMessage(ActionNameByType[actionDefinitionId as AllActionIds])
      }
    }, {
      onSuccess: (response) => {
        const createdAction = response.addChildAction.workflowActionConfiguration;
        const actionDefinition = actionDefinitions.find(p => p.actionDefinitionId == createdAction?.workflowActionDefinitionId);
        createdAction && actionDefinition &&
          update(createdAtIndex, mapActionFragmentToForm(createdAction, actionDefinition));
      },
      onError: () => remove(createdAtIndex)
    });
  });

  useChildActionMovedListener(({ sourceIndex, destinationIndex }) => {
    if (workflowTemplate.rootAction == null) return;

    moveChildAction({
      input: {
        parentActionConfigurationId: workflowTemplate.rootAction.id,
        movedActionConfigurationId: actions[sourceIndex].id,
        orderIndex: destinationIndex,
        workflowDefinitionId: workflowTemplate.id
      }
    });

    setSelectedActionIndex(null);
    move(sourceIndex, destinationIndex);
  });

  return (
    <Stack pt={2} gap={2}>
      <Droppable droppableId={WorkflowEditorDroppableIds.steps} type={WorkflowEditorDroppableTypes.step}>
        {(provided, snapshot) => (
          // extra bottom padding ensures it is easier to drag an extra workflow action
          <Stack gap={2} pb={10} {...provided.droppableProps} ref={provided.innerRef} sx={{
            bgcolor: t => snapshot.isDraggingOver ? alpha(t.palette.primary.main, t.palette.action.hoverOpacity) : t.palette.background.default,
            boxShadow: 'none',
            border: getBorderColor(snapshot.isDraggingOver, actions.length)
          }}>
            {actions.map((action, index) => (
              <ActionTile
                actionIndex={index}
                key={action.uniqueId}
              />
            ))}

            {provided.placeholder}

            {(actions.length === 0 || snapshot.isDraggingOver) && (
              <DroppableEmptyState placeholderText={formatMessage({ id: 'Steps can be dropped here' })} />
            )}
          </Stack>
        )}
      </Droppable>

    </Stack>
  );
};

import { CancelButton } from '@components/Buttons/CancelButton';
import { useProjectDocumentsUploadManager } from '@components/FileUpload/useProjectDocumentsUploadManager';
import { UploadList } from '@modules/documents/components/UploadList';
import { useCurrentProject } from '@modules/projects/utils/useCurrentProject';
import { ArrowDownward } from '@mui/icons-material';
import { Alert, Button, Stack } from '@mui/material';
import { useResponsive } from '@utils/useResponsive';
import { Folder, useChildFoldersQuery, useFolderEntriesQuery, useProjectFoldersQuery } from 'gql/index';
import { useContext, useEffect, useRef } from 'react';
import { useDropzone } from 'react-dropzone';
import { useIntl } from 'react-intl';
import { isAncestorOf } from '../utils/folderUtils';
import { getFolderEntryName } from '../utils/getFolderEntryName';
import { useProjectDocumentsMoveMutations } from '../utils/useProjectDocumentsMoveMutations';
import { FolderTable } from './FolderTable/FolderTable';
import { FolderTableBreadcrumbs } from './FolderTableBreadcrumbs';
import { ProjectDocumentsContext } from './ProjectDocumentsContextProvider';
import { AddButtonMenu } from './Toolbar/AddButtonMenu';

export const ExplorerMainView = () => {
  const { formatMessage } = useIntl();
  const { isMobile } = useResponsive();


  const { setBreadcrumbPath, currentFolderId, movingEntry, movingFolder, setMovingEntry, setMovingFolder } = useContext(ProjectDocumentsContext);

  const { canManageDocuments, projectId, isArchived } = useCurrentProject();

  const { data: projectFolders, isFetching: isProjectFoldersFetching } = useProjectFoldersQuery({ projectId }, { select: d => d.projectFolders });

  const { data: childFolders } = useChildFoldersQuery({ parentFolderId: currentFolderId }, { select: d => d.childFolders });

  const { data: folderEntries, isFetching: isLoadingEntries } = useFolderEntriesQuery({ projectId, folderId: currentFolderId }, { select: d => d.folderEntries });



  useEffect(() => {
    if (currentFolderId && projectFolders && !projectFolders.find(f => f.id === currentFolderId)) {
      setBreadcrumbPath([]);
    }
  }, [projectFolders, currentFolderId, setBreadcrumbPath]);

  const { queueFiles } = useProjectDocumentsUploadManager(`project-${projectId}`);

  const { getInputProps, getRootProps, isDragActive } = useDropzone({
    noClick: true,
    noKeyboard: true,
    disabled: !canManageDocuments,
    onDropAccepted: files => queueFiles(files, currentFolderId),
  });

  const { moveEntry, moveFolder } = useProjectDocumentsMoveMutations();

  const onMoveConfirm = () => {
    if (movingFolder) {
      moveFolder({
        input: {
          projectId,
          folderId: movingFolder.id,
          destinationFolderId: currentFolderId
        }
      });
    }
    if (movingEntry) {
      moveEntry({
        input: {
          projectId,
          entryId: movingEntry.id,
          destinationFolderId: currentFolderId
        }
      });
    }
  };

  const isMovingFolderAncestorOfSelectedFolder = movingFolder && currentFolderId &&
    isAncestorOf(movingFolder, projectFolders?.find(f => f.id === currentFolderId) as Folder);

  const isTableLoading = isProjectFoldersFetching || isLoadingEntries;

  const folderUploadRef = useRef<HTMLInputElement>(null);

  const onMoveCancelled = () => {
    setMovingFolder?.(undefined);
    setMovingEntry?.(undefined);
  };

  return <>
    <Stack gap={1} pt={1} pb={2} px={isMobile ? 0.5 : 0} bgcolor='background.paper' flex={1} overflow={'auto'}
      {...getRootProps()}
      sx={{
        position: 'relative',
        borderStyle: 'dashed',
        borderColor: isDragActive ? 'primary.main' : 'transparent',
      }}
    >
      <Stack justifyContent={'space-between'} alignItems={'center'} direction='row' flexWrap={'wrap'} gap={2} px={1}>
        <FolderTableBreadcrumbs />
        <AddButtonMenu folderUploadRef={folderUploadRef} />
      </Stack>

      <UploadList uploadsBatchId={`project-${projectId}`} />

      <Stack flex={1} position={'relative'} overflow={isMobile ? 'auto' : 'hidden'} height='100%'>

        {(movingFolder || movingEntry) && (
          <Alert color='info' action={(
            <CancelButton size='small' onClick={onMoveCancelled} />
          )}>
            {formatMessage({ id: 'Select a folder to move {folderName} into' }, { folderName: movingFolder?.name ?? getFolderEntryName(movingEntry) })}
          </Alert>
        )}


        <FolderTable
          loading={isTableLoading}
          folders={childFolders ?? []}
          entries={folderEntries ?? []}
        />

        {!isArchived && <>
          <input {...getInputProps({ directory: '', webkitdirectory: '' })} />

          <input
            ref={folderUploadRef}
            multiple
            type='file'
            webkitdirectory=''
            directory=''
            style={{ display: 'none' }}
            onChange={e => {
              const files = e.target.files;
              if (!files) return;
              queueFiles(Array.from(files), currentFolderId);
            }}
          />
        </>}




        {((movingFolder && !isMovingFolderAncestorOfSelectedFolder && currentFolderId !== movingFolder.id) || movingEntry) && (
          <Button variant='contained' startIcon={<ArrowDownward />} sx={{ position: 'absolute', bottom: 20, right: 20 }} onClick={onMoveConfirm}>
            {formatMessage({ id: 'Move here' })}
          </Button>
        )}
      </Stack>
    </Stack>

  </>;
};
import { EmptyState } from '@components/EmptyState';
import { MatrixColumnHeader } from '@modules/forms/components/MatrixColumnHeader';
import { Add, Delete } from '@mui/icons-material';
import { Button, ClickAwayListener, FormControlLabel, IconButton, Radio, RadioGroup, Stack } from '@mui/material';
import { GridColDef, GridEventListener } from '@mui/x-data-grid';
import { DataGridPro, GridEditInputCell, GridRenderEditCellParams, GridRowId, GridTreeNodeWithRender, GridValidRowModel, useGridApiRef } from '@mui/x-data-grid-pro';
import { Chance } from 'chance';
import { MatrixColumnType } from 'gql/index';
import { merge } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { FormEditorMatrixValues, MatrixEditorDataGridRow, MatrixEditorValue } from '../../../types';
import { MatrixColumnConfigurationDrawer, MatrixColumnFormData } from './MatrixColumnConfigurationDrawer';
import { MatrixEditorMenu } from './MatrixEditorMenu';
import { RenderFn, matrixColumnTypeToGridColType, updateValueToFormValue, valueGetterByColumnType } from './MatrixUtils';

interface Props {
  disabled?: boolean;
  values?: FormEditorMatrixValues;
  onChange?: (values: FormEditorMatrixValues) => void;
}

interface EditCellParam {
  id: GridRowId,
  field: string;
}

const chance = new Chance(Math.random);

const updateCellBoolValue = <T extends GridValidRowModel>(newValue: boolean | '', params: GridRenderEditCellParams<T, unknown, unknown, GridTreeNodeWithRender>) => {
  if (params.value == newValue) {
    params.api.setEditCellValue({ id: params.id, field: params.field, value: '' });
  } else {
    params.api.setEditCellValue({ id: params.id, field: params.field, value: newValue });
  }
};

export const MatrixDataGrid: React.FC<Props> = ({ disabled, values, onChange }) => {
  const { formatMessage } = useIntl();
  const apiRef = useGridApiRef();
  const [editCellParam, setEditCellParam] = useState<EditCellParam>({ id: '', field: '' });

  const valueRendererByColumnType: Record<MatrixColumnType, RenderFn | undefined> = useMemo(() => ({
    [MatrixColumnType.Boolean]: ({ value }) => (
      value === true ? formatMessage({ id: 'Yes' })
        : value === false ? formatMessage({ id: 'No' })
          : '-'
    ),
    [MatrixColumnType.Text]: undefined,
    [MatrixColumnType.Numerical]: undefined,
  }), [formatMessage]);

  const valueEditorByColumnType: Record<MatrixColumnType, React.FC<GridRenderEditCellParams>> = useMemo(() => ({
    [MatrixColumnType.Boolean]: (params) => (
      <RadioGroup row value={params.value === '' ? null : params.value} >
        <FormControlLabel value="true" onClick={() => updateCellBoolValue(true, params)} control={<Radio />} label={formatMessage({ id: 'Yes' })} />
        <FormControlLabel value="false" onClick={() => updateCellBoolValue(false, params)} control={<Radio />} label={formatMessage({ id: 'No' })} />
      </RadioGroup>
    ),
    [MatrixColumnType.Text]: (params) => <GridEditInputCell {...params} />,
    [MatrixColumnType.Numerical]: (params) => <GridEditInputCell {...params} />,
  }), [formatMessage]);


  const matrix: FormEditorMatrixValues = {
    columns: [
      {
        columnId: chance.guid(),
        name: formatMessage({ id: 'Column 1' }),
        description: '',
        isRequired: false,
        type: MatrixColumnType.Text,
      },
      {
        columnId: chance.guid(),
        name: formatMessage({ id: 'Column 2' }),
        description: '',
        isRequired: false,
        type: MatrixColumnType.Text,
      },
      {
        columnId: chance.guid(),
        name: formatMessage({ id: 'Column 3' }),
        description: '',
        isRequired: false,
        type: MatrixColumnType.Text,
      }
    ],
    defaultRows: [],
  };

  const { control, getValues } = useForm<FormEditorMatrixValues>({
    defaultValues: merge({}, matrix, values)
  });

  const { fields: columns, ...columnsField } = useFieldArray({ name: 'columns', control, keyName: 'useFieldArrayId' });

  const { fields: rows, ...rowsField } = useFieldArray({ name: 'defaultRows', control, keyName: 'useFieldArrayId' });

  useEffect(() => {
    onChange?.(getValues());
  }, [columns, rows, getValues, onChange]);

  const onClickAway = () => {
    if (editCellParam.id === '') return;
    const lastUpdatedValue = apiRef.current.getRowWithUpdatedValues(editCellParam.id, editCellParam.field);
    processRowUpdate({ id: lastUpdatedValue.id, values: lastUpdatedValue.values, lastEditedColumnId: lastUpdatedValue.lastEditedColumnId, newValue: lastUpdatedValue.newValue });
  };

  const onRowOrderChange: GridEventListener<'rowOrderChange'> = useCallback(({ oldIndex, targetIndex }) => {
    rowsField.move(oldIndex, targetIndex);
  }, [rowsField]);

  const onColumnOrderChange: GridEventListener<'columnOrderChange'> = useCallback(({ oldIndex, targetIndex }) => {
    columnsField.move(oldIndex, targetIndex);
  }, [columnsField]);

  const processRowUpdate = useCallback((updatedRow: MatrixEditorDataGridRow) => {

    if (!updatedRow.lastEditedColumnId) {
      console.error('lastEditedColumnId is undefined');
      return updatedRow;
    }

    const newValue = updatedRow.newValue;

    if (newValue == null) {
      return updatedRow;
    }

    const rowIndex = rows.findIndex(d => d.rowId == updatedRow.id);

    const newDefaultValue = { rowId: updatedRow.id, columnId: updatedRow.lastEditedColumnId, ...updateValueToFormValue(newValue) };

    if (rowIndex == -1) {
      rowsField.append({
        rowId: updatedRow.id,
        values: [newDefaultValue]
      });
    }
    else {
      const row = rows[rowIndex];
      const oldValueIndex = row.values.findIndex(p => p.columnId == updatedRow.lastEditedColumnId);

      if (oldValueIndex >= 0) {
        const newValues = [...(row.values)];
        newValues.splice(oldValueIndex, 1, newDefaultValue);
        rowsField.update(rowIndex, {
          ...row,
          values: newValues
        });
        updatedRow.values.splice(oldValueIndex, 1, newDefaultValue);
      } else {
        rowsField.update(rowIndex, {
          ...row,
          values: [...row.values, newDefaultValue]
        });
        updatedRow.values = [...row.values, newDefaultValue];
      }
    }
    setEditCellParam({ id: '', field: '' });

    return updatedRow;
  }, [rows, rowsField]);

  const addNewColumn = useCallback(() => {
    columnsField.append({
      columnId: chance.guid(),
      type: MatrixColumnType.Text,
      isRequired: false,
      name: formatMessage({ id: 'New column' }),
      description: '',
    });
  }, [columnsField, formatMessage]);

  const addNewRow = () => {
    rowsField.append({
      rowId: chance.guid(),
      values: []
    });
  };

  const deleteRowById = useCallback((id: number | string) => () => {
    const index = rows.findIndex(r => r.rowId == id);
    if (index >= 0) rowsField.remove(index);
  }, [rows, rowsField]);

  const dataGridColumns = useMemo((): GridColDef<MatrixEditorDataGridRow>[] => {
    const dataGridCols = [];

    const currentsColumns = columns.map<GridColDef<MatrixEditorDataGridRow>>((column, index) => ({
      field: index.toString(),
      renderHeader: () => (
        <MatrixColumnHeader name={column.name} description={column.description} isRequired={column.isRequired} />
      ),
      flex: 1,
      minWidth: 128,
      editable: !disabled,
      type: matrixColumnTypeToGridColType[column.type],
      valueGetter: ({ row: { values } }) =>
        valueGetterByColumnType[column.type](values.find(d => d.columnId === column.columnId)),
      valueSetter: ({ row, value }) => ({ ...row, newValue: value, lastEditedColumnId: column.columnId }),
      renderCell: valueRendererByColumnType[column.type],
      renderEditCell: valueEditorByColumnType[column.type]
    }));
    dataGridCols.push(...currentsColumns);

    const actionsColumn: GridColDef<MatrixEditorDataGridRow> = {
      field: 'actions',
      maxWidth: 64,
      minWidth: 64,
      editable: false,
      align: 'center',
      type: 'actions',
      disableColumnMenu: true,
      renderHeader: () => (
        <IconButton onClick={addNewColumn}>
          <Add />
        </IconButton>
      ),
      renderCell: ({ row: { id } }) => (
        <IconButton onClick={deleteRowById(id)}>
          <Delete />
        </IconButton>
      )
    };
    if (!disabled) dataGridCols.push(actionsColumn);

    return dataGridCols.map(v => ({ ...v, sortable: false, headerAlign: 'left' }));
  }, [addNewColumn, columns, deleteRowById, disabled, valueEditorByColumnType, valueRendererByColumnType]);

  const dataGridRows = useMemo((): MatrixEditorDataGridRow[] => {
    return rows.map<MatrixEditorDataGridRow>((f) => ({
      id: f.rowId,
      values: columns.map<MatrixEditorValue>(c =>
        f.values.find(d => d.columnId === c.columnId && d.rowId === f.rowId) ?? {
          columnId: c.columnId,
          rowId: f.rowId
        }
      )
    }));
  }, [rows, columns]);

  const onDelete = useCallback((colDef: GridColDef) => {
    const columnIndex = Number(colDef.field);

    columnsField.remove(columnIndex);
  }, [columnsField]);

  const [selectedHeaderIndex, setSelectedHeaderIndex] = useState<number | null>(null);

  const onColumnChange = useCallback((values: MatrixColumnFormData) => {
    if (selectedHeaderIndex == null) return;

    const oldValues = columns[selectedHeaderIndex];
    if (!oldValues) return;

    columnsField.update(selectedHeaderIndex, {
      ...oldValues,
      ...values,
      type: values.type || oldValues.type,
    });

    setSelectedHeaderIndex(null);
  }, [columns, columnsField, selectedHeaderIndex]);

  return <>
    <Stack gap={1}>
      <ClickAwayListener mouseEvent='onMouseDown' onClickAway={onClickAway}>
        <div style={{ height: 400, width: '100%' }}>
          <DataGridPro
            apiRef={apiRef}
            disableRowSelectionOnClick
            disableColumnFilter
            disableColumnSelector
            disableColumnResize
            disableMultipleRowSelection
            disableChildrenFiltering
            disableChildrenSorting
            disableColumnReorder={disabled}
            disableDensitySelector
            disableMultipleColumnsFiltering
            disableMultipleColumnsSorting
            onRowOrderChange={onRowOrderChange}
            onColumnOrderChange={onColumnOrderChange}
            disableColumnMenu={disabled}
            processRowUpdate={processRowUpdate}
            onCellClick={(p) => {
              setEditCellParam({ field: p.colDef.field, id: p.row.id });
              apiRef.current.startCellEditMode({ field: p.colDef.field, id: p.row.id });
            }}
            columns={dataGridColumns}
            rows={dataGridRows}
            onColumnHeaderClick={e => !isNaN(Number(e.field)) && setSelectedHeaderIndex(Number(e.field))}
            initialState={{
              pinnedColumns: {
                right: ['actions']
              }
            }}
            hideFooter
            slots={{
              columnMenu: (props) => <MatrixEditorMenu {...props} onDelete={onDelete} />,
              noRowsOverlay: !disabled ? (() => (
                <EmptyState
                  title={formatMessage({ id: 'There are no default values.' })}
                  subtitle={formatMessage({ id: 'You can optionally add default values by clicking the button below.' })}
                  hideImage
                />
              )) : () => null
            }}
          />
        </div>
      </ClickAwayListener>

      {!disabled && (
        <Stack direction='row' justifyContent='flex-end'>
          <Button variant='text' size='small' startIcon={<Add />} onClick={addNewRow}>{formatMessage({ id: 'Add default value' })}</Button>
        </Stack>
      )}
    </Stack>

    <MatrixColumnConfigurationDrawer
      open={selectedHeaderIndex != null}
      onClose={onColumnChange}
      values={columns[selectedHeaderIndex ?? 0]}
    />
  </>;
};
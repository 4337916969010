import { PrincipalType } from 'gql/index';
import typia, { tags } from 'typia';



export const validateWorkflowPrincipal = typia.createValidate<IWorkflowPrincipal>();

export interface IWorkflowPrincipal {
    id: string & tags.Format<'uuid'>,
    principalType: PrincipalType;
}

import { useIntl } from 'react-intl';
import { WorkflowFieldInput } from '../../Fields/WorkflowFieldInput';
import { ActionConfigurationComponentProps } from '../types';

export const FillFormActionDefinition = {
  ActionDefinitionId: 'Internal.FillForm',
  FieldId_FormTemplate: 'cbc476d3-82c8-4be3-825b-0df8dea7ee6e',
  FieldId_Responder: 'ec63e001-d9a6-498f-bd65-04a21619dc28',
  FieldId_FormSubmission: '5a862af5-af30-4e24-87c5-08f1bc20060a'
} as const;

export const FillFormAction: React.FC<ActionConfigurationComponentProps> = () => {
  const { formatMessage } = useIntl();

  return (
    <>
      <WorkflowFieldInput fieldId={FillFormActionDefinition.FieldId_FormTemplate} label={formatMessage({ id: 'Form template' })} />
      <WorkflowFieldInput fieldId={FillFormActionDefinition.FieldId_Responder} label={formatMessage({ id: 'Responder' })} />
    </>
  );
};